import React, { useEffect, useState } from 'react';
import getActualUser from '../../helpers/get-actual-user';
import Dashboard from '../../models/dashboard';
import User from '../../models/user';
import { getDashboard } from '../../services/dashboard';
import { ProfileContainer, DashboardContainer, DashboardCard, IconContainer, TextContainer } from './style';
import { BiCameraMovie, BiMovie } from 'react-icons/bi';
import { FaChild } from 'react-icons/fa';
import { FiUsers, FiUserCheck, FiUserPlus } from 'react-icons/fi';

const Profile: React.FC = () => {
  const [user, setUser] = useState({} as User);
  const [dashboard, setDashboard] = useState({} as Dashboard);

  useEffect(() => {
    (async () => {
      setUser(await getActualUser());
      setDashboard(await getDashboard());
    })();
  }, []);

  return (
    <ProfileContainer>
      <h2>Bem-vindo ao painel administrativo, {user.name || 'Desconhecido'}!</h2>
      <DashboardContainer>
        <DashboardCard>
          <TextContainer>
            <h5>Conteúdos, Conteúdos Kids e Clipes Ativos</h5>
            <span>{dashboard.total_movies}</span>
          </TextContainer>
          <IconContainer>
            <BiCameraMovie />
          </IconContainer>
        </DashboardCard>

        <DashboardCard>
          <TextContainer>
            <h5>Séries, Séries Kids e Podcasts Ativos</h5>
            <span>{dashboard.total_series}</span>
          </TextContainer>
          <IconContainer>
            <BiMovie />
          </IconContainer>
        </DashboardCard>

        <DashboardCard>
          <TextContainer>
            <h5>Conteúdos e Séries Kids Ativos</h5>
            <span>{dashboard.total_kids_contents}</span>
          </TextContainer>
          <IconContainer>
            <FaChild />
          </IconContainer>
        </DashboardCard>

        <DashboardCard>
          <TextContainer>
            <h5>Total de usuários na plataforma</h5>
            <span>{dashboard.total_users}</span>
          </TextContainer>
          <IconContainer>
            <FiUsers />
          </IconContainer>
        </DashboardCard>

        <DashboardCard>
          <TextContainer>
            <h5>Novos Usuários no Último Mês</h5>
            <span>{dashboard.total_last_month_users}</span>
          </TextContainer>
          <IconContainer>
            <FiUserPlus />
          </IconContainer>
        </DashboardCard>

        <DashboardCard>
          <TextContainer>
            <h5>Usuários Premium</h5>
            <span>{dashboard.total_users_premium}</span>
          </TextContainer>
          <IconContainer>
            <FiUserCheck />
          </IconContainer>
        </DashboardCard>
      </DashboardContainer>
    </ProfileContainer>
  );
};

export default Profile;

import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import { PaymentsContainer, StyledButton } from './styles';
import { getUsers as getUsersService } from '../../services/users';
import UserFromResponse from '../../models/from-api-response/user';

import { DefaultCreationFormButtonGroup, DefaultCreationFormGroup } from '../../components/DefaultCreationForm';
import DefaultButton from '../../components/DefaultButton';
import { generateUsersReport } from '../../services/reports';

import { AiOutlineCheck, AiOutlineClose, AiOutlineCreditCard } from 'react-icons/ai';
import { FaUserAltSlash } from 'react-icons/fa';
import { RiAdminFill } from 'react-icons/ri';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';
import {
  activeOrInactiveCourtesy as activeOrInactiveCourtesyService,
  cancelPayment as cancelPaymentService,
  cancelPlan as cancelPlanService,
} from '../../services/payments';
import {
  updateUserToAdmin as updateUserToAdminService,
  activateOrInactivateUser as activateOrInactivateUserService,
} from '../../services/users';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';

import DefaultInput from '../../components/DefaultInput';

const Payments: React.FC = () => {
  const [users, setUsers] = useState([] as UserFromResponse[]);
  const [search, setSearch] = useState('');

  const cancelPayment = async (userId: string) => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja cancelar o pagamento deste usuário?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await cancelPaymentService(userId);
          await getUsers();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({ icon: 'error', text: 'Houve um erro ao cancelar o pagamento. ' + errorMessage });
        }
      }
    });
  };

  const cancelPlan = async (userId: string) => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja cancelar a assinatura deste usuário?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await cancelPlanService(userId);
          await getUsers();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({ icon: 'error', text: 'Houve um erro ao cancelar a assinatura. ' + errorMessage });
        }
      }
    });
  };

  const updateUserToAdmin = async (userId: string, isAdmin: boolean) => {
    Swal.fire({
      icon: 'question',
      text: `Tem certeza que deseja ${isAdmin ? 'remover admin deste' : 'tornar admin este'} usuário?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateUserToAdminService(userId);
          await getUsers();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: 'Houve um erro ao modificar o status do usuário. ' + errorMessage,
          });
        }
      }
    });
  };

  const activeOrInactiveCourtesy = async (userId: string, subscription: string) => {
    Swal.fire({
      icon: 'question',
      text: `${subscription === 'courtesy'
        ? 'Você tem certeza que deseja retirar a cortesia deste usuário? Após essa operação o usuário não terá acesso aos conteúdos premium'
        : 'Você tem certeza que deseja conceder uma cortesia para este usuário?'
        }`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activeOrInactiveCourtesyService(userId);
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Operação realizada com sucesso',
          });
          await getUsers();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: 'Houve um erro ao conceder/remover cortesia para esse usuário' + errorMessage,
          });
        }
      }
    });
  };

  const getUsers = useCallback(async () => {
    const allUsers = await getUsersService();
    setUsers(allUsers);
  }, []);

  const activateOrInactivateUser = useCallback(
    async (userId: string, is_active: boolean) => {
      Swal.fire({
        title: 'Confirmação',
        text: `Tem certeza que deseja ${!is_active ? 'ativar' : 'inativar'} este usuário?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateUserService(userId);
            await getUsers();
          } catch (error) {
            Swal.fire({
              title: 'Erro',
              text: `Erro ao ${is_active ? 'ativar' : 'inativar'} este usuário. ${error.response &&
                error.response.status === 400 &&
                error.response.data.message.includes('It is already enrolled in a content.')
                ? 'Esta categoria já está associado a um conteúdo!'
                : error.response
                  ? `Status ${error.response.status}`
                  : null
                }`,
            });
          }
        }
      });
    },
    [getUsers]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const usersToBeShown = useMemo(() => {
    return (users || []).map((user) => ({
      name: user.name,
      email: user.email,
      plan: user.is_admin
        ? 'Admin'
        : user.subscription_type === 'courtesy'
          ? 'Cortesia'
          : user.plan === 'free'
            ? 'Gratuito'
            : 'Pago',
      status: user.status === 'active' ? 'Ativo' : user.status === 'canceled' ? 'Inativo' : 'Em débito',
      actions: (
        <>
          <StyledButton
            onClick={() => updateUserToAdmin(user.user_id, user.is_admin)}
            className="small info"
            title={`${user.is_admin ? 'Remover' : 'Tornar'} Admin`}
          >
            {user.is_admin ? (
              <>
                Remover Admin
                <RiAdminFill />
              </>
            ) : (
              <>
                Tornar Admin <RiAdminFill />
              </>
            )}
          </StyledButton>
          <StyledButton
            onClick={() => activateOrInactivateUser(user.user_id, user.is_active)}
            className="small warning"
            title={(user.is_active ? 'Inativar' : 'Ativar') + ' usuário'}
          >
            {user.is_active ? (
              <>
                Inativar <AiOutlineClose />
              </>
            ) : (
              <>
                Ativar
                <AiOutlineCheck />
              </>
            )}
          </StyledButton>
          <StyledButton
            onClick={() => cancelPayment(user.user_id)}
            className="small warning"
            title="Cancelar Pagamento"
          >
            Cancelar pg.
            <AiOutlineCreditCard />
          </StyledButton>
          <StyledButton
            onClick={() => {
              cancelPlan(user.user_id);
            }}
            className="small danger"
            title="Cancelar Assinatura"
          >
            Cancelar ass.
            <FaUserAltSlash />
          </StyledButton>
          <StyledButton
            onClick={() => {
              activeOrInactiveCourtesy(user.user_id, user.subscription_type);
            }}
            className="small warning courtesyButton"
            title={`${user.subscription_type === 'courtesy' ? 'Remover' : 'Adicionar'} Cortesia`}
          >
            {`${user.subscription_type === 'courtesy' ? 'Remover' : 'Adicionar'} Cortesia`}
          </StyledButton>
        </>
      ),
    }));
  }, [users]);

  const getReportUsers = useCallback(
    async (format: string) => {
      try {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Download do relatório iniciado com sucesso',
        });
        await generateUsersReport(format);
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        Swal.fire({
          title: 'Erro',
          text: errorMessage ? `Erro ao fazer download do relatório` : errorMessage,
        });
      }
    },
    [search]
  );

  return (
    <PaymentsContainer>
      <BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Usuários</span>]} />

      <DefaultPageTitle>Usuários</DefaultPageTitle>
      <DefaultCreationFormButtonGroup>
        <DefaultInput
          placeholder="Pesquisar"
          value={search}
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
        />

        <DefaultButton
          type="submit"
          className="sucess"
          onClick={() => {
            getReportUsers('csv');
          }}
        >
          Gerar Relatório (CSV)
        </DefaultButton>

        <DefaultButton
          type="submit"
          className="sucess"
          onClick={() => {
            getReportUsers('pdf');
          }}
        >
          Gerar Relatório (PDF)
        </DefaultButton>
      </DefaultCreationFormButtonGroup>

      <DefaultTable
        searchText={search}
        headersConfig={[
          {
            headerLabel: <>Nome</>,
            propName: 'name',
          },
          {
            headerLabel: <>Email</>,
            propName: 'email',
          },
          {
            headerLabel: <>Plano</>,
            propName: 'plan',
          },
          {
            headerLabel: <>Status de Pagamento</>,
            propName: 'status',
          },
          {
            headerLabel: <>Ações</>,
            propName: 'actions',
            noWrap: true,
          },
        ]}
        items={usersToBeShown}
        emptyListMessage={'Não foram encontrados usuários pagantes!'}
      />
    </PaymentsContainer>
  );
};

export default Payments;

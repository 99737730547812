import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiEdit, BiTrash, BiStop, BiCopy, BiPlay } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Live from '../../models/lives';
import { showModal } from '../../helpers/modal';
import { getAllLives, getLive as getLiveService, deleteLive, changeLiveStatus } from '../../services/lives';

import { Container, StyledButton } from './style';
import { StartLiveForm } from '../../components/StartLiveForm';

const Lives: React.FC = () => {
  const history = useHistory();

  const [lives, setLives] = useState([] as Live[]);

  const getErrorMessage = (error: any) => {
    return (error && error.response && error.response.data && error.response.data.message) || error.message;
  };

  const getLives = async () => {
    const allLives = await getAllLives();
    if (allLives) {
      setLives(allLives);
    }
  };

  const handleStopLive = useCallback(async (liveId: string) => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja parar esta live?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await changeLiveStatus(liveId, 'STOPPED');

          Swal.fire({
            icon: 'success',
            text: 'Live parada com sucesso!',
          });
          
          await getLives();
        } catch(error: any) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: 'Houve um erro ao parar a live. ' + errorMessage,
          });
        }
      }
    });
  }, []);

  const copyRmtp = async (liveId: string) => {
    const actualLive = await getLiveService(liveId);
    const rtmp = actualLive.rtmp;

    const actualDocument = document.getElementById('lives-container');

    const rtmpInput = document.createElement('input');
    rtmpInput.value = rtmp || '';
    actualDocument?.appendChild(rtmpInput);
    rtmpInput.select();
    rtmpInput.setSelectionRange(0, 99999);
    document.execCommand('copy');
    rtmpInput.remove();
    Swal.fire({ text: `RTMP "${rtmp}" copiado!`, icon: 'info' });
  };

  const editLive = useCallback(
    (liveId: string) => {
      history.push(`edit-live/${liveId}`);
    },
    [history]
  );

  const removeLive = useCallback(async (liveId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta Live?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteLive(liveId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Live excluida com sucesso!',
          });

          await getLives();
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao excluir Live',
            text: 'Esta transmissão não pode ser excluída porque não foi interrompida.',
          });
        }
      }
    });
  }, []);

  const createLive = async () => {
    history.push('create-live');
  };

  function handleShowModalToStartLive(live: Live) {
    if(live.status === 'STOPPED') {
      return Swal.fire({
        icon: 'error',
        title: 'Erro ao iniciar a Live',
        text: 'Esta transmissão já foi finalizada.',
      });
    }

    if( new Date(live.stop).getTime() >= new Date().getTime()) {
      return Swal.fire({
        icon: 'error',
        title: 'Erro ao iniciar a Live',
        text: 'Esta transmissão não pode ser iniciada pois já passou do horário fim.',
      });
    }
    
    return showModal(
      'Iniciar transmissão', 
      <StartLiveForm liveId={live.id} getLives={getLives}/>
    )
  }

  const translateStatus = (liveStatus: string | undefined) => {
    switch (liveStatus) {
      case 'FINISHED':
        return <span>Finalizada</span>;

      case 'ERROR':
        return <span>Erro</span>;

      case 'STOPPED':
        return <span>Finalizada</span>;

      case 'STOPPING':
        return <span>Parando</span>;

      case 'DELETING':
        return <span>Excluindo</span>;

      case 'DELETED':
        return <span>Excluído</span>;

      case 'UNKNOWN':
        return <span>Desconhecido</span>;

      case 'SCHEDULED':
        return <span>Agendado</span>;

      case 'ACTIVE':
        return <span>Ao vivo</span>;

      case 'STARTING':
        return <span>Criando</span>;

      case 'EXTERNAL_LIVE':
        return <span>Live externa</span>;

      case 'CREATING':
        return <span>Criada</span>;

      default:
        return <span>Ao vivo</span>;
    }
  };

  const livesToBeShown = useMemo(() => {
    return lives && lives.length
      ? lives.map((live) => ({
          id: live.id,
          name: live.name,
          description: live.description,
          start: live.start,
          stop: live.stop,
          status: (
            <p
              className="tooltip"
              aria-label={
                live.status === 'ERROR'
                  ? live.error_sv === 'channel_create_limit_exceeded'
                    ? 'O limite de canais disponíveis foi atingido.'
                    : live.error_sv
                  : undefined
              }
              style={live.status === 'ERROR' ? { color: 'red' } : { color: '#000' }}
            >
              {translateStatus(live.status)}
            </p>
          ),
          actions: (
            <>
              <StyledButton
                title="Editar Live"
                className="small info"
                disabled={live.status === 'STOPPED' ? true : false}
                onClick={() => {
                  editLive(live.id);
                }}
              >
                Editar
                <BiEdit />
              </StyledButton>
              <StyledButton
                title="Começar Live"
                className="small danger"
                disabled={live.status === 'STOPPED' ? true : false}
                onClick={() => {
                  handleShowModalToStartLive(live)
                }}
              >
                Live
                <BiPlay />
              </StyledButton>
              {live.status === 'ACTIVE' && (
                <StyledButton
                  title="Parar Live"
                  className="small warning"
                  style={{ color: 'white', marginBottom: '10px' }}
                  onClick={() => handleStopLive(live.id)}
                >
                  Parar
                  <BiStop />
                </StyledButton>
              )}
              <StyledButton
                onClick={() => removeLive(live.id)}
                className="small danger"
                title="Excluir Live"
              >
                Deletar
                <BiTrash />
              </StyledButton>
              {live.status === 'ACTIVE' && (
                <StyledButton
                  onClick={() => copyRmtp(live.id)}
                  className="small danger"
                  title="Copiar RMTP"
                  style={{ backgroundColor: 'gray' }}
                >
                  Copiar
                  <BiCopy />
                </StyledButton>
              )}
            </>
          ),
        }))
      : [];
  }, [lives, editLive, removeLive, handleStopLive]);

  useEffect(() => {
    getLives();
  }, []);

  return (
    <Container id="lives-container">
      <BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Lives</span>]} />
      <DefaultPageTitle>Lives</DefaultPageTitle>
      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createLive}>Criar Live</DefaultButton>
      </CreateButtonDefaultContainer>
      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Início</span>,
            propName: 'start',
          },
          {
            headerLabel: <span>Fim</span>,
            propName: 'stop',
          },
          {
            headerLabel: <span>Status</span>,
            propName: 'status',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={livesToBeShown}
        emptyListMessage={'Não foram encontradas Lives cadastradas!'}
      />
    </Container>
  );
};

export default Lives;

import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { BiEdit } from 'react-icons/bi';

import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultTable from '../../components/DefaultTable';

import { getAllPlans } from '../../services/plans';
import { formatPrice } from '../../utils/formatPrice';
import Plan from '../../models/plan';

import { Container } from './style';

const Plans = () => {
  const [plans, setPlans] = useState([] as Plan[]);

  const history = useHistory();

  const getPlans = async (): Promise<void> => {
    const localPlans = await getAllPlans();
    setPlans(localPlans);
  };

  const updatePlan = (planId: string) => {
    history.push(`edit-plan/${planId}`);
  };

  const contentsToBeShown = useMemo(() => {
    return (plans || []).map((plan) => ({
      id: plan.plan_id,
      title: plan.description,
      description: (plan.benefits || []).map((benefit, index) => {
        return (
          <ul key={index}>
            <li>{benefit.trim()}</li>
          </ul>
        );
      }),
      price: formatPrice(plan.price),
      actions: (
        <>
          <DefaultButton onClick={() => updatePlan(plan.plan_id)} className="small info" title="Editar Plano">
            Editar
            <BiEdit />
          </DefaultButton>{' '}
        </>
      ),
    }));
  }, [plans]);

  const activePlans = plans.map((plan) => plan.status.includes('active'));

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <Container>
      <BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Planos</span>]} />

      <DefaultPageTitle>Planos</DefaultPageTitle>
      {activePlans && (
        <div>
          <DefaultTable
            headersConfig={[
              {
                headerLabel: <span>Título</span>,
                propName: 'title',
              },
              {
                headerLabel: <span>Vantagens</span>,
                propName: 'description',
              },
              {
                headerLabel: <span>Preço</span>,
                propName: 'price',
              },
              {
                headerLabel: <span>Ações</span>,
                propName: 'actions',
              },
            ]}
            items={contentsToBeShown}
            emptyListMessage={'Não foram encontrados planos cadastrados!'}
          />
        </div>
      )}
    </Container>
  );
};

export default Plans;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 10px;
    font-size: var(--font-medium);
  }
`;

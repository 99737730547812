import httpClient from '../http-client';
import UserFromResponse from '../models/from-api-response/user';

const getUsers = async (): Promise<UserFromResponse[]> => {
  const { data: users } = await httpClient.get('/users');
  return users;
};

const getActualUser = async () => {
  return (await httpClient.get('users/me')).data;
};

const getUsersForSelect = async () => {
  const users = (await httpClient.get<UserFromResponse[]>(`users/`)).data;

  if (users && users.length) {
    return users.map((user) => ({
      value: user.user_id,
      label: user.name,
    }));
  }

  return [] as { value: string; label: string }[];
};

const updateUserToAdmin = async (userId: string) => {
  await httpClient.patch(`users/${userId}/admin`);
};

const activateOrInactivateUser = async (userId: string) => {
  await httpClient.patch(`/users/${userId}/active-deactive`);
};

export { getUsersForSelect, getActualUser, getUsers, updateUserToAdmin, activateOrInactivateUser };

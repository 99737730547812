import styled from 'styled-components';
import DefaultButton from '../../components/DefaultButton';

export const Container = styled.div``;

export const StyledButton = styled(DefaultButton)`
  margin-bottom: 0.5rem;
  padding: 0.4rem !important;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

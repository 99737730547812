import styled from 'styled-components';

const DefaultButton = styled.button`
  background: var(--primary-color);
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 15px 20px;
  font-size: var(--font-small);
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);

  &.danger {
    background: var(--danger-color);
    margin-left: 5px;
  }

  &.success {
    background: var(--success-color);
    margin-left: 5px;
  }

  &.info {
    background: var(--info-color);
    margin-left: 5px;
  }

  &.white {
    background: white;
    color: #444;
    border: solid 1px var(--default-dark-gray);
    margin-left: 5px;
  }

  &.warning {
    background: #ffc107;
    color: black;
    margin-left: 5px;
  }

  &.small {
    padding: 3px 6px;
    margin-left: 5px;
  }

  &:disabled {
    cursor: not-allowed;
    filter: brightness(1.5);
    background: gray;
  }
`;

export default DefaultButton;

import { convertToLive } from '../helpers/converters/convert-live';
import httpClient from '../http-client';
import { LiveForCreate } from '../models/for-create/lives';
import { LiveForUpdate } from '../models/for-update/lives';
import LiveFromResponse from '../models/from-api-response/lives';
import Live from '../models/lives';
import { uploadFile } from './files';

const getAllLives = async () => {
  const lives = (await httpClient.get<LiveFromResponse[]>('/lives')).data;

  let convertedList = [] as Live[];
  if (lives) {
    convertedList = lives.map((res) => convertToLive(res));
  }
  return convertedList;
};

const stopLive = async (liveId: string) => {
  await httpClient.put(`/lives/${liveId}/stop`);
};

const getLive = async (liveId: string) => {
  return (await httpClient.get<LiveFromResponse>(`/lives/${liveId}`)).data;
};

const createLive = async (newLive: LiveForCreate) => {
  await httpClient.post('/lives', newLive);
};

const deleteLive = async (liveId: string) => {
  await httpClient.delete(`/lives/${liveId}`);
};

const updateLive = async (liveId: string, newLive: LiveForUpdate) => {
  await httpClient.put(`/lives/${liveId}`, newLive);
};

const updateImages = async (liveId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`/lives/${liveId}/images`, {
    images: { thumbnail_Url: uploadedFile.reference },
  });
};

const changeLiveStatus = async (liveId: string, status: 'ACTIVE' | 'STOPPED') => {
  await httpClient.patch(`/lives/${liveId}/status`, {
    status,
  });
};

const getLivesSv = async () => {
  return httpClient.get(`/lives/samba-videos`);
};

export { getAllLives, stopLive, getLive, createLive, deleteLive, updateLive, updateImages, changeLiveStatus, getLivesSv };

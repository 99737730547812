import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { hideModal } from '../../../helpers/modal';

interface SelectLanguageProps {
  availableLanguages: {
    label: string;
    value: string;
  }[];
  onSelect: (file: File, language: string) => void;
  modalId?: string;
}

const SelectLanguageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 160px;

  .languageCard {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0px 0px 7px 2px var(--default-dark-gray);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .languageCard:hover {
    background-color: #fff;
    filter: brightness(0.95);
  }
`;

const SelectLanguage: React.FC<SelectLanguageProps> = ({ availableLanguages, onSelect, modalId }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const fileInput = useRef<HTMLInputElement>(null);

  const handleSelectLanguage = (language: string) => {
    setSelectedLanguage(language);
    fileInput?.current?.click();
  };

  const handleSubtitleChange = () => {
    const file = (fileInput?.current?.files || [])[0];

    onSelect(file, selectedLanguage);
    hideModal(modalId);
  };

  return (
    <SelectLanguageContainer>
      {availableLanguages?.map((language) => (
        <div className="languageCard" key={language.value} onClick={() => handleSelectLanguage(language.value)}>
          {language.label}
        </div>
      ))}

      <input type="file" style={{ display: 'none' }} ref={fileInput} onChange={handleSubtitleChange} />
    </SelectLanguageContainer>
  );
};

export default SelectLanguage;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';

import { CreateAndEditProducerContainer } from './style';

import checkEmptyString from '../../helpers/check-empty-string';

import { createProducer, getProducer, updateProducer } from '../../services/producer';
import getErrorMessage from '../../helpers/get-error-message';

interface CreateAndEditContentProps {
  producerId: string;
}

const CreateAndEditProducer: React.FC = () => {
  const { producerId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [charactersNumber, setCharactersNumber] = useState(0);

  const createNewProducer = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o produtor.');
      }

      await createProducer({
        name,
        description: description.length ? description : undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Produtor criado com sucesso!',
        icon: 'success',
      });

      goToProducers();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: 'Erro ao criar um produtor',
        text: `Houve um erro ao criar o produtor. ${errorMessage}`,
        icon: 'error',
      });
    }
  };

  const updateExistingProducer = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o produtor.');
      }

      await updateProducer(producerId, {
        name,
        description: description || ' ',
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Produtor editado com sucesso!',
        icon: 'success',
      });

      goToProducers();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o produtor. ' + error.message,
        icon: 'error',
      });
    }
  };

  const goToProducers = () => {
    history.push('/producers');
  };

  const getContent = useCallback(async () => {
    if (producerId) {
      const producer = await getProducer(producerId);
      if (producer && Object.keys(producer).length) {
        setName(producer.name);
        setDescription(producer.description);

        !producer.description ? setCharactersNumber(0) : setCharactersNumber(producer.description.length);
      }
    }
  }, [producerId]);

  const updateDescription = (event: any) => {
    const { target } = event;
    setDescription(target.value);

    if (target.value.length <= target.maxLength) setCharactersNumber(target.value.length);
  };

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (producerId) {
      return true;
    }

    return false;
  }, [producerId]);

  return (
    <CreateAndEditProducerContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/producers">Produtores</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Produtor</span>,
        ]}
      />

      <DefaultPageTitle>{isEditting ? 'Editar' : 'Criar'} Produtor</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Nome
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="title" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea value={description || ''} onChange={(e) => updateDescription(e)} id="description" />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToProducers}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateExistingProducer(e) : createNewProducer(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditProducerContainer>
  );
};

export default CreateAndEditProducer;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Producer from '../../models/producer';
import {
  deleteProducer,
  getAllProducers,
  activateOrInactivateProducer as activateOrInactivateProducerService,
} from '../../services/producer';

import { StyledButton, ContainerButtons } from './style';
import DefaultInput from '../../components/DefaultInput';

const Producers = () => {
  const [producers, setProducers] = useState<Producer[]>();
  const [search, setSearch] = useState('');

  const history = useHistory();

  const getProducers = async () => {
    const allProducers = await getAllProducers();
    setProducers(allProducers);
  };

  const createProducer = async () => {
    history.push('create-producer');
  };

  const editProducer = useCallback(
    (producerId: string) => {
      history.push(`edit-producer/${producerId}`);
    },
    [history]
  );

  const activateOrInactivateProducer = useCallback(async (producerId: string, activate: boolean) => {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} este produtor?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateOrInactivateProducerService(producerId, activate);
          await getProducers();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao ${activate ? 'ativar' : 'inativar'} produtor, ${
              error.response &&
              error.response.status === 400 &&
              error.response.data.message.includes('It is already enrolled in a content.')
                ? 'este produtor já está associado a um conteúdo!'
                : error.response.data.message.includes('It is already enrolled in a trail.')
                ? 'este produtor já está associado a uma trilha!'
                : `pois está vinculado a algum conteúdo!`
            }`,
          });
        }
      }
    });
  }, []);

  const removeProducer = async (producerId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este produtor?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteProducer(producerId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Produtor excluído com sucesso!',
          });

          await getProducers();
        } catch (e) {
          const isEnrolledInCourse = e.response.data.message.includes('It is already enrolled in a trail.');
          Swal.fire({
            icon: 'error',
            title: 'Erro ao excluir produtor',
            text: isEnrolledInCourse
              ? e.message
              : 'Este Produtor não pode ser deletado pois está vinculado a algum conteúdo',
          });
        }
      }
    });
  };

  useEffect(() => {
    getProducers();
  }, []);

  const producersToBeShown = useMemo(() => {
    return producers && producers.length
      ? producers.map((producer) => ({
          id: producer.producer_id,
          name: producer.name,
          description: producer.description,
          isActive: producer.is_active,
          actions: (
            <ContainerButtons>
              <StyledButton
                className="small info"
                onClick={() => {
                  editProducer(producer.producer_id);
                }}
              >
                Editar <BiEdit />
              </StyledButton>
              <StyledButton
                onClick={() => activateOrInactivateProducer(producer?.producer_id, !producer.is_active)}
                className="small warning"
                title={''}
              >
                {producer.is_active ? (
                  <>
                    Inativar <AiOutlineClose />
                  </>
                ) : (
                  <>
                    Ativar
                    <AiOutlineCheck />
                  </>
                )}
              </StyledButton>
              <StyledButton
                onClick={() => removeProducer(producer.producer_id)}
                className="small danger"
                title="Excluir palestrante"
              >
                Deletar
                <BiTrash />
              </StyledButton>
            </ContainerButtons>
          ),
        }))
      : [];
  }, [producers, editProducer, removeProducer]);

  return (
    <div>
      <BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Produtores</span>]} />
      <DefaultPageTitle>Produtores</DefaultPageTitle>
      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createProducer}>Criar Produtor</DefaultButton>

        <div className="right">
          <DefaultInput
            placeholder="Pesquisar"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        searchText={search}
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={producersToBeShown}
        emptyListMessage={'Não foram encontrados produtores cadastrados!'}
      />
    </div>
  );
};

export default Producers;

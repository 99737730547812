import styled from 'styled-components';
import DefaultButton from '../../components/DefaultButton';

const PaymentsContainer = styled.div``;

const StyledButton = styled(DefaultButton)`
  padding: 0.4rem !important;
  margin: 0.2rem;
  transition: filter 0.2s;

  &.courtesyButton {
    width: 7rem;
    white-space: normal;
    margin-top: 0 !important;
    padding-bottom: 0.18rem !important;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

export { PaymentsContainer, StyledButton };

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiLinkAlt } from 'react-icons/bi';

import { CreateButtonDefaultContainer } from '../../../components/CreateButtonDefaultContainer';
import DefaultInput from '../../../components/DefaultInput';
import DefaultTable from '../../../components/DefaultTable';

import { showModal } from '../../../helpers/modal';
import LinkSeasonModal from '../LinkSeasonModal';

import Trail from '../../../models/trail';
import { getAllCourses } from '../../../services/trails';

import { ContainerButtons, CoursesContainer, StyledButton } from '../style';

interface LinkSeriesModalProps {
  episodeId: string;
  reloadContent: () => Promise<void>;
}

const LinkSeriesModal: React.FC<LinkSeriesModalProps> = ({ episodeId, reloadContent }) => {
  const [series, setSeries] = useState<Trail[]>([]);
  const [search, setSearch] = useState('');

  const getSeries = useCallback(async () => {
    const returnedSeries = await getAllCourses();

    setSeries(returnedSeries);
  }, []);

  const linkSeason = useCallback(async (seriesId: string) => {
    showModal('Selecionar temporada',
      <LinkSeasonModal
        episodeId={episodeId}
        seriesId={seriesId}
        reloadContent={reloadContent}
      />
    )
  }, [episodeId, reloadContent]);

  const seriesToBeShown = useMemo(() => {
    return series && series.length
      ? series.map((series) => ({
        id: series.id,
        trail_id: series.trail_id,
        name: series.name,
        actions: (
          <ContainerButtons>
            <StyledButton
              className="small info"
              onClick={() => linkSeason(series.trail_id!)}
              title='Selecionar série'
            >
              Selecionar
              <BiLinkAlt />
            </StyledButton>
          </ContainerButtons>
        ),
      }))
      : [];
  }, [series, linkSeason]);

  useEffect(() => {
    getSeries();
  }, [getSeries]);

  return (
    <CoursesContainer>
      <CreateButtonDefaultContainer>
        <div className="right">
          <DefaultInput
            placeholder="Buscar uma série"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        searchText={search}
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={seriesToBeShown}
        emptyListMessage={`Nehuma série encontrada!`}
      />
    </CoursesContainer>
  );
};

export default LinkSeriesModal;

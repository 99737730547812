import styled from 'styled-components';
import DefaultButton from '../../components/DefaultButton';

export const CoursesContainer = styled.div``;

export const StyledButton = styled(DefaultButton)`
  margin-top: 0.5rem;
  padding: 0.5rem !important;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
`;
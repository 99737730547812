import httpClient from '../http-client';

const getTerms = async () => {
  return (await httpClient.get('/home/1/home-info')).data;
};

const updateTerms = async (newTerms: string, newPrivacy: string) => {
  return (
    await httpClient.post('/home/home-info', {
      ...(newTerms && { term_of_use: newTerms }),
      ...(newPrivacy && { privacy_policy: newPrivacy }),
    })
  ).data;
};

export { getTerms, updateTerms };

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import Swal from 'sweetalert2';
import DefaultButton from '../DefaultButton';
import { ButtonGroupInline, Container, LanguageContainer, SubtitleUpload } from './style';
import { hideModal, showModal } from '../../helpers/modal';
import { uploadSubtitle, deleteSubtitle as deleteSubtitleService } from '../../services/contents';
import { getMedia } from '../../services/medias';
import MediaFromResponse from '../../models/from-api-response/media';
import LanguageCard from './components/LanguageCard';
import SelectLanguage from './components/SelectLanguage';
import { setTimeout } from 'timers';

interface ContentSubtitle {
  captionLanguage: string;
  subtitle: string | File;
  wasUploaded: boolean;
}
interface SelectSubtitlesProps {
  reference: any;
  modalId?: string;
  hasLoading?: boolean;
}

const SelectSubtitles: React.FC<SelectSubtitlesProps> = ({ reference, modalId, hasLoading }) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [media, setMedia] = useState({} as MediaFromResponse);
  const [subtitles, setSubtitles] = useState([] as ContentSubtitle[]);
  const [hideUpload, setHideUpload] = useState(false);

  const languages = [
    { value: 'pt_BR', label: 'Português' },
    { value: 'es_ES', label: 'Espanhol' },
    { value: 'en_US', label: 'Inglês' },
    { value: 'fr_FR', label: 'Francês' },
    { value: 'de_DE', label: 'Alemão' },
  ];

  const addSubtitle = (file: File, language: string) => {
    setSubtitles([...subtitles, { captionLanguage: language, subtitle: file, wasUploaded: false }]);
  };

  const selectSubtitleLanguage = () => {
    const availableLanguages = languages.filter((language) => {
      return !subtitles?.map((subtitle) => subtitle?.captionLanguage)?.includes(language.value);
    });
    showModal('Idioma da Legenda', <SelectLanguage onSelect={addSubtitle} availableLanguages={availableLanguages} />);
  };

  const submitSubtitles = async () => {
    const subtitlesInMedia = media?.captions?.map((caption) => {
      return caption?.fileInfo?.captionLanguage;
    });

    const subtitlesToDelete = subtitlesInMedia.filter((subtitle) => {
      return !subtitles.map((sub) => sub.captionLanguage).includes(subtitle);
    });

    const errorsOnDelete = [];
    for (let subtitle of subtitlesToDelete) {
      try {
        await deleteSubtitleService(mediaId, subtitle);
      } catch (error) {
        errorsOnDelete.push(error);
        Swal.fire({
          title: 'Erro',
          text: `Erro ao remover as alterações. ${errorsOnDelete}`,
          icon: 'error',
        });
      }
    }

    const subtitlesToUpload = subtitles?.filter((subtitle) => {
      return !subtitle?.wasUploaded;
    });

    const errorsOnUpload = [];
    for (let subtitle of subtitlesToUpload) {
      try {
        const formData = new FormData();
        formData.append('file', subtitle.subtitle);
        formData.append('captionLanguage', subtitle.captionLanguage);
        await uploadSubtitle(media.id, formData);
      } catch (error) {
        errorsOnUpload.push(error);
      }
    }

    const languagesAfterOperation = [
      ...subtitlesInMedia.filter((language) => !subtitlesToDelete.includes(language)),
      ...subtitlesToUpload.map((subtitle) => subtitle.captionLanguage),
    ];

    if (!errorsOnUpload.length && !errorsOnDelete.length) {
      // 3 tentativas de verificar se as legendas do sambavideo foram atualizadas
      await new Promise(async (resolve, reject) => {
        const loading = document.querySelector('#loading') as HTMLDivElement;
        loading.style.display = 'flex';
        const attemptTimeout = 5000;
        for (let i = 0; i < 3; i++) {
          await new Promise((resolve, reject) => setTimeout(resolve, attemptTimeout));
          const media = await getMedia(mediaId);
          const languagesInMedia = media?.captions?.map((caption) => {
            return caption?.fileInfo?.captionLanguage;
          });
          if (JSON.stringify(languagesInMedia) === JSON.stringify(languagesAfterOperation)) {
            break;
          }
        }
        loading.style.display = 'none';
        resolve(undefined);
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Operações realizadas com sucesso!',
        timer: 4000,
      });
      hideModal(modalId);
    } else {
      Swal.fire({
        title: 'Erro!',
        text: 'Houve um problema durante o upload do arquivo!',
        timer: 4000,
      });
    }
  };

  const onUploadClick = () => {
    selectSubtitleLanguage();
  };

  const mediaId = useMemo(() => {
    return reference?.split('/')?.reverse()[0];
  }, [reference]);

  const getOneMedia = useCallback(async () => {
    const media = await getMedia(mediaId);
    setMedia(media);
  }, [mediaId]);

  const deleteSubtitle = async (file: File | string, language: string) => {
    const result = await Swal.fire({
      icon: 'question',
      text: 'Deseja remover esta legenda?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    });
    if (result.isConfirmed) {
      setHideUpload(true);
      setSubtitles([
        ...subtitles.filter((subtitle) => {
          return subtitle.subtitle !== file && subtitle.captionLanguage !== language;
        }),
      ]);
    }
  };

  useEffect(() => {
    getOneMedia();
  }, [getOneMedia]);

  useEffect(() => {
    if (media?.id) {
      const contentSubtitles: ContentSubtitle[] = media?.captions?.map(({ fileInfo, url }) => ({
        captionLanguage: fileInfo.captionLanguage,
        subtitle: url,
        wasUploaded: true,
      }));
      setSubtitles(contentSubtitles);
    }
  }, [media]);

  return (
    <Container>
      {hideUpload ? (
        <p>
          Clique em <span>salvar</span> para confirmar suas alterações!
        </p>
      ) : (
        <SubtitleUpload onClick={onUploadClick}>
          <AiOutlineCloudUpload size={128} />
          <p>Fazer Upload de Legenda</p>
          <input
            ref={fileInput}
            style={{ display: 'none' }}
            accept=".srt"
            type="file"
            onChange={selectSubtitleLanguage}
          />
        </SubtitleUpload>
      )}

      <LanguageContainer>
        {subtitles?.map((subtitle) => {
          const referencedLanguage = languages.find((language) => language.value === subtitle.captionLanguage)!;

          return (
            <LanguageCard
              language={referencedLanguage}
              subtitle={subtitle.subtitle}
              onDelete={deleteSubtitle}
              hideUploadModal={hideUpload}
            />
          );
        })}
      </LanguageContainer>

      <ButtonGroupInline>
        <DefaultButton type="button" className="danger" onClick={() => hideModal(modalId)}>
          Cancelar
        </DefaultButton>

        <DefaultButton onClick={submitSubtitles} className="success">
          Salvar
        </DefaultButton>
      </ButtonGroupInline>
    </Container>
  );
};

export default SelectSubtitles;

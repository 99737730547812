import React, { useState } from 'react';
import styled from 'styled-components';
import DefaultButton from '../../DefaultButton';

const LanguageCardContainer = styled.div`
  position: relative;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 7px 2px var(--default-dark-gray);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .languageName {
    display: flex;
    flex-direction: column;
    margin-right: 6px;
  }
  > button {
    transition: all 0.2s ease-in-out;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;

interface LanguageCardProps {
  language: {
    label: string;
    value: string;
  };
  subtitle: File | string;
  hideUploadModal: boolean;
  onDelete: (file: File | string, language: string) => void;
}

const LanguageCard: React.FC<LanguageCardProps> = ({ language, subtitle, onDelete, hideUploadModal }) => {
  const [hideUpload, setHideUpload] = useState(hideUploadModal);

  const deleteSubtitle = () => {
    onDelete(subtitle, language.value);
    setHideUpload((old) => !old);
  };

  return (
    <LanguageCardContainer>
      <span className="languageName">{language.label}</span>
      <DefaultButton className="small danger" type="button" onClick={deleteSubtitle}>
        Excluir
      </DefaultButton>
    </LanguageCardContainer>
  );
};

export default LanguageCard;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import { uploadFile } from '../../services/files';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import SelectSambaVideosContent from '../../components/SelectSambaVideosContent';
import SelectSubtitles from '../../components/SelectSubtitles';
import { hideModal, showModal } from '../../helpers/modal';
import MediaFromResponse from '../../models/from-api-response/media';
import {
  changeContentBanner as changeContentBannerService,
  changeContentThumbnail as changeContentThumbnailService,
  createContent as createContentService,
  deleteContent,
  getContent as getContentService,
  updateContent as updateContentService,
  changeContentHighlighted as changeHighlightedService,
  getSegmentations,
  changeContentThumbnailMobile as changeContentThumbnailMobileService,
} from '../../services/contents';
import CutImage from '../../components/CutImage';
import checkEmptyString from '../../helpers/check-empty-string';
import Select from 'react-select';
import Author from '../../models/author';
import { getAllAuthors } from '../../services/authors';
import Category from '../../models/category';
import { getAllCategories } from '../../services/categories';
import { getMedia, getMedias } from '../../services/medias';
import {
  ContentThumbnail,
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  ThumbnailUploadContainer,
  Switch,
  ButtonGroupInline,
  CourseThumbnail,
} from './style';
import ContentForCreate, { ContentInfo } from '../../models/for-create/content';
import Content from '../../models/content';
import ContentForUpdate from '../../models/for-update/content';

import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import mdParser from 'marked';

import Producer from '../../models/producer';
import { getAllProducers } from '../../services/producer';

interface CreateAndEditContentProps {
  contentId: string;
}

interface ContentsProps {
  isKids?: boolean;
  isMusics?: boolean;
}

interface LabelAndValueProps {
  label: string;
  value: string;
}

const CreateAndEditContent: React.FC<ContentsProps> = ({ isKids, isMusics }) => {
  const anoAtual = () => new Date().getFullYear();
  const location = useLocation();
  const { contentId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reference, setReference] = useState('');
  const [authors, setAuthors] = useState<Author[]>([]);
  const [selectedAuthors, setSelectedAuthors] = useState([] as LabelAndValueProps[]);

  const [producers, setProducers] = useState<Producer[]>([]);
  const [selectedProducer, setSelectedProducer] = useState({} as LabelAndValueProps);

  const [categories, setCategories] = useState('' as unknown as Category[]);
  const [selectedCategory, setSelectedCategory] = useState([] as LabelAndValueProps[]);

  const [selectedTag, setSelectedTag] = useState({} as LabelAndValueProps);

  const [duration, setDuration] = useState(0);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [type, setType] = useState('');

  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState('');

  const [thumbnailMobileFileToUpload, setThumbnailMobileFileToUpload] = useState<File>();
  const [thumbnailMobile, setThumbnailMobile] = useState('');

  const [bannerFileToUpload, setBannerFileToUpload] = useState<File>();
  const [banner, setBanner] = useState('');

  const [originalTitle, setOriginalTitle] = useState('');
  const [cast, setCast] = useState('');
  const [language, setLanguage] = useState('');
  const [director, setDirector] = useState('');
  const [parentalRating, setParentalRating] = useState('');
  const [year, setYear] = useState({ label: anoAtual(), value: anoAtual() });
  const [hasSubtitle, setHasSubtitle] = useState(false);
  const [premiumContent, setPremiumContent] = useState(true);
  const [charactersNumber, setCharactersNumber] = useState(0);

  const [contentType, setContentType] = useState(`${isMusics ? 'Show/Apresentação/VideoClip' : 'Conteúdo'}`);

  const [extraMaterials, setExtraMaterials] = useState<any>([] as any[]);
  const [contentInfo, setContentInfo] = useState<ContentInfo>({} as ContentInfo);

  const [isLoading, setIsLoading] = useState(false);

  const adjetivo = () => (contentType === 'Palestra' ? 'a' : 'o');

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl);

          setDuration(firstFile.fileInfo.duration / 1000);
          setType(firstFile.qualifier);
        }
      }
      setHasSubtitle(sambaVideosContent.captions.length > 0);
    }

    hideModal();
  };

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error(`Informe um título válido para ${adjetivo()} ${contentType}.`);
      }

      if (!isMusics && !selectedTag.value) {
        throw new Error('Informe o tipo de conteúdo a ser criado.');
      }

      if (isHighlighted && !banner) {
        throw new Error(`Informe uma imagem de banner para ${adjetivo()} ${contentType}.`);
      }

      if (!thumbnail || !thumbnailFileToUpload) {
        throw new Error(`Informe uma imagem de thumbnail para ${adjetivo()} ${contentType}.`);
      }

      if (!thumbnailMobile && !thumbnailMobileFileToUpload) {
        throw new Error(`Informe uma imagem de capa mobile para ${adjetivo()} ${contentType}.`);
      }

      if (!reference) {
        throw new Error(`Selecione ${adjetivo()} ${contentType} antes.`);
      }

      const data: ContentForCreate = {
        title: title,
        description: description.trim().length ? description : undefined,
        duration: duration,
        reference: reference,
        type: 'VIDEO',
        is_library: true,
        categories: selectedCategory && selectedCategory.map((category) => ({ category_id: category.value })),
        authors: selectedAuthors && selectedAuthors.map((author) => ({ author_id: author.value })),
        producer_id: selectedProducer.value,
        info: { premium: premiumContent },
        tag: `${isMusics ? 'musicas' : 'filmes'}`,
        segmentation_items: [
          {
            segmentation_item_id: isKids
              ? String(process.env.REACT_APP_SEGMENTATION_ITEM_KIDS)
              : String(process.env.REACT_APP_SEGMENTATION_ITEM_PADRAO),
          },
        ],
      };

      if (selectedTag.value && selectedTag.value.length) {
        data.tag = selectedTag.value;
      }
      if (isHighlighted) data.info = { ...data.info, is_highlighted: isHighlighted };
      if (originalTitle && originalTitle.length) data.info = { ...data.info, original_title: originalTitle };
      if (cast && cast.length) data.info = { ...data.info, cast };
      if (language && language.length) data.info = { ...data.info, language };
      if (director && director.length) data.info = { ...data.info, director };
      if (parentalRating) data.info = { ...data.info, parental_rating: parentalRating };
      if (year.value && year.value > 0 && Number(year.value)) data.info = { ...data.info, year: year.value };

      const createdContent = await createContentService(data);

      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(thumbnailFileToUpload, createdContent.content_id);
        } catch (error) {
          await deleteContent(createdContent.content_id);

          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      if (thumbnailMobileFileToUpload) {
        try {
          await changeContentThumbnailMobile(thumbnailMobileFileToUpload, createdContent.content_id);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa mobile. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      } else if (!thumbnailMobileFileToUpload)
        if (bannerFileToUpload) {
          try {
            await changeContentBanner(bannerFileToUpload, createdContent.content_id);
          } catch (error) {
            throw new Error(
              'Erro ao fazer upload da imagem do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
            );
          }
        }

      if (bannerFileToUpload) {
        try {
          await changeContentBanner(bannerFileToUpload, createdContent.content_id);
        } catch (error) {
          await deleteContent(createdContent.content_id);

          throw new Error(
            'Erro ao fazer upload da imagem do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      Swal.fire({
        title: 'Sucesso!',
        text: `${contentType} criad${adjetivo()} com sucesso!`,
        icon: 'success',
      });

      goToContents();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar ${adjetivo()} ${contentType}. ${error.message}`,
        icon: 'error',
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error(`Informe um título válido para ${adjetivo()} ${contentType}.`);
      }

      if (isHighlighted && !banner && !bannerFileToUpload) {
        throw new Error(`Informe uma imagem de banner destaque para ${adjetivo()} ${contentType}.`);
      }

      if (!reference) {
        throw new Error(`Selecione ${adjetivo()} ${contentType} antes.`);
      }

      let localBanner = banner;

      if (bannerFileToUpload) {
        const formData = new FormData();
        formData.append('file', bannerFileToUpload);
        const { reference } = await uploadFile(formData);
        localBanner = reference;
      }

      if (!selectedTag.value) {
        throw new Error('Informe o tipo de conteúdo a ser criado.');
      }

      const data: ContentForUpdate = {
        title: title,
        description: description || undefined,
        duration: duration,
        reference: reference,
        type: type,
        is_library: true,
        categories: selectedCategory && selectedCategory.map((category) => ({ category_id: category.value })),
        authors: selectedAuthors && selectedAuthors.map((author) => ({ author_id: author.value })),
        producer_id: selectedProducer.value,
        info: { premium: premiumContent || false, banner_url: localBanner },
        tag: `${isMusics ? 'musicas' : 'filmes'}`,
        segmentation_items: [
          {
            segmentation_item_id: isKids
              ? String(process.env.REACT_APP_SEGMENTATION_ITEM_KIDS)
              : String(process.env.REACT_APP_SEGMENTATION_ITEM_PADRAO),
          },
        ],
      };

      if (isMusics) {
        delete data.authors;
      }
      if (localBanner && localBanner.length) data.info = { ...data.info, banner_url: localBanner };
      if (isHighlighted) data.info = { ...data.info, is_highlighted: isHighlighted };
      if (originalTitle && originalTitle.length) data.info = { ...data.info, original_title: originalTitle };
      if (cast && cast.length) data.info = { ...data.info, cast };
      if (language && language.length) data.info = { ...data.info, language };
      if (director && director.length) data.info = { ...data.info, director };
      if (parentalRating) data.info = { ...data.info, parental_rating: parentalRating };
      if (year.value && year.value > 0 && Number(year.value)) data.info = { ...data.info, year: year.value };
      if (selectedTag.value && selectedTag.value.length) {
        data.tag = selectedTag.value;
      }

      await updateContentService(contentId, data);

      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(thumbnailFileToUpload, contentId);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      if (thumbnailMobileFileToUpload) {
        try {
          await changeContentThumbnailMobile(thumbnailMobileFileToUpload, contentId);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa mobile. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      } else if (!thumbnailMobileFileToUpload)
        if (bannerFileToUpload) {
          try {
            await changeContentBanner(bannerFileToUpload, contentId);
          } catch (error) {
            throw new Error(
              'Erro ao fazer upload da imagem do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
            );
          }
        }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter((extraMaterial: any) => !extraMaterial.alreadySaved)) {
          const formData = new FormData();
          formData.append('file', material);

          await uploadFile(formData).then(({ reference, name }) => {
            uploadedMaterials.push({ reference, filename: name });
          });
        }
      }

      Swal.fire({
        title: 'Sucesso!',
        text: `${contentType} editad${adjetivo()} com sucesso!`,
        icon: 'success',
      });

      goToContents();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar ${adjetivo()} ${contentType}. ${error.message}`,
        icon: 'error',
      });
    }
  };

  const changeContentThumbnail = async (localThumbnailFileToUpload: File, localContentId: string) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append('name', `content_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`);
    formData.append('description', `thumbnail do filme de id ${localContentId}`);
    await changeContentThumbnailService(localContentId, formData);
  };

  const changeContentThumbnailMobile = async (localThumbnailFileToUpload: File, localContentId: string) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append('name', `course_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`);
    formData.append('description', `thumbnail da série de id ${localContentId}`);

    await changeContentThumbnailMobileService(localContentId, formData, true);
  };

  const changeContentBanner = async (localThumbnailFileToUpload: File, localContentId: string) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append('name', `content_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`);
    formData.append('description', `thumbnail do filme de id ${localContentId}`);

    await changeContentBannerService(localContentId, formData);
  };

  const selectSubtitles = () => {
    showModal('Selecionar Legenda', <SelectSubtitles reference={reference} />);
  };

  const selectContent = () => {
    showModal(`Selecionar ${contentType}`, <SelectSambaVideosContent onSelectContent={onSelectContent} />);
  };

  const selectThumbnail = () => {
    showModal('Selecionar Imagem de Capa', <CutImage aspect={1090 / 601} onCutImage={onCutThumbnail} />);
  };

  const selectThumbnailMobile = () => {
    showModal('Selecionar Imagem de Capa mobile', <CutImage aspect={0.7} onCutImage={onCutImageMobile} />);
  };

  const selectBanner = () => {
    if (!isKids) {
      showModal('Selecionar Imagem do Banner', <CutImage aspect={1.812} onCutImage={onCutBanner} />);
    } else {
      showModal('Selecionar Imagem do Banner', <CutImage aspect={0.6} onCutImage={onCutBanner} />);
    }
  };

  const onCutThumbnail = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const onCutImageMobile = (file: File) => {
    if (file) {
      setThumbnailMobileFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnailMobile(`${reader.result}`);

      hideModal();
    }
  };

  const onCutBanner = (file: File) => {
    if (file) {
      setBannerFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setBanner(`${reader.result}`);

      hideModal();
    }
  };

  const goToContents = () => {
    if (!isKids && !isMusics) {
      history.push('/contents');
      return;
    }
    if (isKids) {
      history.push('/kids/contents');
    }
    if (isMusics) {
      history.push('/musics/contents');
    }
  };

  const getContent = useCallback(async () => {
    const foundAuthors = await getAllAuthors();
    const foundProducers = await getAllProducers();
    const foundCategories = await getAllCategories({ is_active: true });
    if (foundAuthors) setAuthors(foundAuthors);
    if (foundProducers) setProducers(foundProducers);
    if (foundCategories) setCategories(foundCategories);
    if (contentId) {
      const content: Content = await getContentService(contentId);
      if (content && Object.keys(content).length) {
        console.log(content);
        const currentMedia = await getMedia(content.reference.split('/')[content.reference.split('/').length - 1]);
        setTitle(content.title);
        setDescription(content.description || '');
        setReference(content.reference);
        setHasSubtitle((currentMedia.captions || []).length > 0);
        setDuration(content.duration);
        setThumbnail(content.thumbnail);
        setThumbnailMobile(content.thumbnail_mobile);
        setType(content.type);
        setBanner(content.info.banner_url || '');
        setIsHighlighted(content.info?.is_highlighted || false);
        setOriginalTitle(content.info?.original_title || '');
        setCast(content.info?.cast || '');
        setLanguage(content.info?.language || '');
        setDirector(content.info?.director || '');
        setParentalRating(content.info?.parental_rating || '');
        setYear({ label: content.info?.year || 0, value: content.info?.year || 0 });
        setPremiumContent(content.info?.premium || false);
        if (content && content.producer) {
          setSelectedProducer({ label: content.producer.name || '', value: content.producer.producer_id! });
        }
        !content.description ? setCharactersNumber(0) : setCharactersNumber(content.description.length);

        if (content.authors && content.authors.length) {
          const selectedAuthors = content.authors.map((author: Author) => ({
            label: author.name,
            value: `${author.id}`,
          }));
          setSelectedAuthors(selectedAuthors);
        }
        if (content.producer_id) {
          const selectedProducer = content.producer_id;

          setSelectedProducer({ value: selectedProducer, label: '' });
        }
        if (content.categories && content.categories.length) {
          const selectedCategories = content.categories.map((category: Category) => ({
            label: category.title,
            value: `${category.id}`,
          }));
          setSelectedCategory(selectedCategories);
        }

        if (content.tag && content.tag.length) {
          const tagEnum: any = {
            filmes: 'Filme',
            cursos: 'Curso',
            musicas: 'Clipe',
            palestras: 'Palestra',
            sermoes: 'Sermão',
            documentarios: 'Documentário',
            lives_anteriores: 'Live anterior',
          };
          setSelectedTag({ label: tagEnum[content.tag], value: content.tag });
          setContentType(tagEnum[content.tag]);
        }
      }
    }
  }, [contentId]);

  const authorsToBeSelected = useMemo(() => {
    if (authors && authors.length) {
      return authors.map((author) => ({
        label: author.name,
        value: `${author.author_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [authors]);

  const producersToBeSelected = useMemo(() => {
    if (producers && producers.length) {
      return producers.map((producer) => ({
        label: producer.name,
        value: `${producer.producer_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [producers]);

  const categoriesToBeSelected = useMemo(() => {
    if (categories && categories.length && !isKids) {
      const categoriesFiltered = categories.filter((category) => category.info?.kids === false || !category.info?.kids);
      return categoriesFiltered.map((category) => ({
        label: category.title,
        value: `${category.category_id}`,
      }));
    } else if (categories && categories.length && isKids) {
      const categoriesFilteredKids = categories.filter((category) => category.info?.kids);
      return categoriesFilteredKids.map((category) => ({
        label: category.title,
        value: `${category.category_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [categories, isKids]);

  const contentTypesToBeSelected = useMemo(() => {
    const types = [
      { label: 'Filme', value: 'filmes' },
      { label: 'Documentário', value: 'documentarios' },
      { label: 'Clipe', value: 'musicas' },
      { label: 'Sermão', value: 'sermoes' },
      { label: 'Curso', value: 'cursos' },
      { label: 'Palestra', value: 'palestras' },
      { label: 'Live anterior', value: 'lives_anteriores' },
    ];

    if (!isKids && !isMusics) delete types[2];

    return types.map((type) => ({
      label: type.label,
      value: `${type.value}`,
    }));
  }, [isKids, isMusics]);

  const contentTypesToBeSelectedKids = useMemo(() => {
    const types = [{ label: 'Filme', value: 'filmes' }];
    return types.map((type) => ({
      label: type.label,
      value: `${type.value}`,
    }));
  }, []);

  const yearOptions = useMemo(() => {
    const types: { label: number; value: number }[] = [];

    for (let i = 1900; i <= 2100; i++) {
      types.push({ label: i, value: i });
    }

    return types;
  }, []);

  const crumbs = useMemo(() => {
    const localCrumbs = [<Link to="/profile">Perfil</Link>];
    const isEdit = contentId ? 'Editar' : 'Criar';
    if (!isKids && !isMusics) {
      localCrumbs.splice(1, 0, <span>Conteúdos</span>, <span>{isEdit} Conteúdos</span>);
      return localCrumbs;
    }
    if (isKids) {
      localCrumbs.splice(1, 0, <span>Kids</span>, <span>Conteúdos</span>, <span>{isEdit} Conteúdo</span>);
    }
    if (isMusics) {
      localCrumbs.splice(1, 0, <span>Músicas</span>, <span>{isEdit} Shows, Apresentações e VideoClips</span>);
    }

    return localCrumbs;
  }, [contentId, isKids, isMusics]);

  useEffect(() => {
    getContent();
  }, [getContent, location.pathname]);

  const isEditting = useMemo(() => {
    if (contentId) {
      return true;
    }

    return false;
  }, [contentId]);

  const updateHighlighted = async () => {
    await changeHighlightedService(contentId, !isHighlighted);
    setIsHighlighted(!isHighlighted);
  };

  const changeSelectedTag = (options: any) => {
    options && setSelectedTag({ label: options.label, value: options.value });
    setContentType(options.label);
  };

  const changeSelectedYear = (options: any) => {
    options && setYear({ label: options.label, value: options.value });
  };

  const updateDescription = (event: any) => {
    const { target } = event;
    setDescription(target.value);

    if (target.value.length <= target.maxLength) setCharactersNumber(target.value.length);
  };

  const pageTitle = () => {
    if (isMusics) return 'Show/Apresentação/VideoClip';
    return 'Conteúdo';
  };

  async function getAllMedias() {
    const data = await getMedias();
    if (data[0]?.captions[0]?.fileName) {
      setHasSubtitle((old) => !old);
    }
  }

  useEffect(() => {
    getAllMedias();
  }, []);

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb crumbs={[...crumbs]} />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} {pageTitle()}
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} id="title" required maxLength={250} />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="original_title">Título Original</label>
          <DefaultTextArea
            value={originalTitle || ''}
            onChange={(e) => setOriginalTitle(e.target.value)}
            id="original_title"
          />
        </DefaultCreationFormGroup>
        {!isMusics && (
          <DefaultCreationFormGroup>
            <label htmlFor="cast">Elenco</label>
            <DefaultTextArea value={cast || ''} onChange={(e) => setCast(e.target.value)} id="cast" />
          </DefaultCreationFormGroup>
        )}
        <DefaultCreationFormGroup>
          <label htmlFor="language">Idioma</label>
          <DefaultTextArea value={language || ''} onChange={(e) => setLanguage(e.target.value)} id="language" />
        </DefaultCreationFormGroup>
        {!isMusics && (
          <DefaultCreationFormGroup>
            <label htmlFor="director">Direção</label>
            <DefaultTextArea value={director || ''} onChange={(e) => setDirector(e.target.value)} id="director" />
          </DefaultCreationFormGroup>
        )}
        <DefaultCreationFormGroup>
          <label htmlFor="parental_rating">Classificação</label>
          <DefaultTextArea
            value={parentalRating || ''}
            onChange={(e) => setParentalRating(e.target.value)}
            id="parental_rating"
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="tags">
            Ano de Lançamento
          </label>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={yearOptions}
            value={year}
            onChange={(options) => changeSelectedYear(options)}
            id="tag"
            isMulti={false}
            placeholder="Selecione o ano do conteúdo a ser criado."
          />
        </DefaultCreationFormGroup>
        {isMusics && !isKids && (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="tags">
              Tipo de conteúdo
            </label>
            <Select
              styles={{
                container: (provided) => ({
                  ...provided,
                  flexGrow: 1,
                  width: '100%',
                }),
              }}
              options={[
                { label: 'Show', value: 'show' },
                { label: 'Apresentação', value: 'apresentacao' },
                { label: 'VideoClip', value: 'videoclip' },
              ]}
              value={selectedTag}
              onChange={(options) => changeSelectedTag(options)}
              id="tag"
              isMulti={false}
              placeholder="Selecione o tipo do conteúdo a ser criado."
            />
          </DefaultCreationFormGroup>
        )}
        {!isKids && !isMusics && (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="tags">
              Tipo de conteúdo
            </label>
            <Select
              styles={{
                container: (provided) => ({
                  ...provided,
                  flexGrow: 1,
                  width: '100%',
                }),
              }}
              options={contentTypesToBeSelected}
              value={selectedTag}
              onChange={(options) => changeSelectedTag(options)}
              id="tag"
              isMulti={false}
              placeholder="Selecione o tipo do conteúdo a ser criado."
            />
          </DefaultCreationFormGroup>
        )}{' '}
        {isKids && (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="tags">
              Tipo de conteúdo
            </label>
            <Select
              styles={{
                container: (provided) => ({
                  ...provided,
                  flexGrow: 1,
                  width: '100%',
                }),
              }}
              options={contentTypesToBeSelectedKids}
              value={selectedTag}
              onChange={(options) => changeSelectedTag(options)}
              id="tag"
              isMulti={false}
              placeholder="Selecione o tipo do conteúdo a ser criado."
            />
          </DefaultCreationFormGroup>
        )}
        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          {/* {isMusics ? (
            <CreateDescriptionMdContainer>
              <MdEditor
                renderHTML={(text) => mdParser(text)}
                value={description}
                onChange={({ text }) => setDescription(text)}
                htmlClass="mdeditor"
              />
            </CreateDescriptionMdContainer>
          ) : ( */}
          <>
            <DefaultTextArea value={description || ''} onChange={(e) => updateDescription(e)} id="description" />
          </>
          {/* )} */}
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="reference" className="required">
            Capa mobile
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnailMobile}>
              Selecionar Imagem de Capa para a mobile
            </DefaultButton>

            {thumbnailMobile && <CourseThumbnail src={thumbnailMobile} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Thumbnail
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Thumbnail
            </DefaultButton>

            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            {contentType}
          </label>

          <ContentUploadOrSelectContainer>
            <ButtonGroupInline>
              <DefaultButton type="button" onClick={selectContent}>
                Selecionar {contentType}
              </DefaultButton>

              {reference && (
                <DefaultButton type="button" onClick={selectSubtitles}>
                  Legendas
                </DefaultButton>
              )}
            </ButtonGroupInline>
            {reference && reference.startsWith(process.env.REACT_APP_PLAYER_INITIAL_URL || '') ? (
              <iframe title="referenced-video" allowFullScreen src={reference} frameBorder={0}></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>
        {!isMusics && (
          <>
            <DefaultCreationFormGroup>
              <label htmlFor="authors">Palestrantes</label>
              <Select
                styles={{
                  container: (provided) => ({
                    ...provided,
                    flexGrow: 1,
                    width: '100%',
                  }),
                }}
                options={authorsToBeSelected}
                value={selectedAuthors}
                onChange={(options) => {
                  options &&
                    !window.isNaN(options.length) &&
                    setSelectedAuthors(options.map((opt) => ({ label: opt.label, value: opt.value })));
                }}
                id="authors"
                isMulti
                placeholder="Selecione um ou mais temas para este curso"
              />
            </DefaultCreationFormGroup>

            <DefaultCreationFormGroup>
              <label htmlFor="producers">Produtores</label>
              <Select
                styles={{
                  container: (provided) => ({
                    ...provided,
                    flexGrow: 1,
                    width: '100%',
                  }),
                }}
                options={producersToBeSelected}
                value={selectedProducer}
                onChange={(option) => {
                  option && setSelectedProducer(option);
                }}
                id="producers"
                isMulti={false}
                placeholder="Selecione um produtor para este curso"
              />
            </DefaultCreationFormGroup>
          </>
        )}
        <DefaultCreationFormGroup>
          <label htmlFor="tags">Categorias</label>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={categoriesToBeSelected}
            value={selectedCategory}
            onChange={(options) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedCategory(options.map((opt) => ({ label: opt.label, value: opt.value })))
            }
            id="authors"
            isMulti
            placeholder={`Selecione uma categoria para este ${contentType}`}
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>Banner Home</label>
          <Switch>
            <input
              type="checkbox"
              checked={isHighlighted}
              onChange={() => (isEditting ? updateHighlighted() : setIsHighlighted(!isHighlighted))}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>
        {isHighlighted && (
          <DefaultCreationFormGroup>
            <label htmlFor="reference" className={isHighlighted ? 'required' : ''}>
              Imagem Destaque
            </label>

            <ThumbnailUploadContainer>
              <div>
                <DefaultButton type="button" onClick={selectBanner}>
                  Selecionar imagem
                </DefaultButton>

                <DefaultButton
                  type="button"
                  onClick={() => {
                    setBanner('');
                    setBannerFileToUpload(undefined);
                  }}
                >
                  Excluir imagem
                </DefaultButton>
              </div>
              {banner && <ContentThumbnail src={banner} />}
            </ThumbnailUploadContainer>
          </DefaultCreationFormGroup>
        )}

        <DefaultCreationFormGroup>
          <label>Conteúdo premium</label>
          <Switch>
            <input type="checkbox" checked={premiumContent} onChange={(e) => setPremiumContent(!premiumContent)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToContents}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditting ? updateContent(e) : createContent(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditContent;

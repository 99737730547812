import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import convertSecondsToTimeText from '../../helpers/seconds-to-time-text';
import Content from '../../models/content';
import {
  deleteContent,
  getMusics,
  getAllContents,
  activateOrInactivateContent as activateOrInactivateContentService,
  getSegmentations,
} from '../../services/contents';

import { ContainerButtons, ContentsContainer, StyledButton } from './style';

interface ContentsProps {
  isKids?: boolean;
  isMusics?: boolean;
}

const Contents: React.FC<ContentsProps> = ({ isKids, isMusics }) => {
  const [contents, setContents] = useState([] as Content[]);
  const [contentType, _setContentType] = useState(isMusics ? 'Show/Apresentação/VideoClip' : 'Conteúdo');
  const [search, setSearch] = useState('');
  const history = useHistory();

  const getContents = async () => {
    let localContents
    isMusics
    ? localContents = await getMusics()
    : localContents = await getAllContents(isKids);

    setContents(localContents);
  };

  const editContent = useCallback(
    (contentId: string) => {
      history.push(`edit-content/${contentId}`);
    },
    [history]
  );

  const removeContent = useCallback(
    async (contentId: string) => {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: `Tem certeza que deseja remover este ${contentType}?`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteContent(contentId);

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: `${contentType} excluído com sucesso!`,
            }).then((result) => {
              if (result.isConfirmed) {
                history.go(0);
              }
            });
            await getContents();
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: `Erro ao excluir ${contentType}. ${error.response && error.response.status === 423
                  ? `Este ${contentType} já está associado a uma aula!`
                  : error.message
                }`,
            });
          }
        }
      });
    },
    [contentType]
  );

  const createContent = async () => {
    history.push('create-content');
  };

  const activateOrInactivateContent = useCallback(async (contentId: string, activate: boolean) => {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} este ${contentType}?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateOrInactivateContentService(contentId, activate);

          getContents();
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: `Erro ao ${activate ? 'ativar' : 'inativar'} ${contentType}. ${error.response && error.response.status === 423 ? `Erro ao inativar ${contentType}!` : error.message
              }`,
          });
        }
      }
    });
  }, []);

  const contentsToBeShown = useMemo(() => {
    return contents && contents.length
      ? contents.map((content) => ({
        title: content.title,
        description: content.description,
        duration: convertSecondsToTimeText(content.duration),
        active: content.active ? <AiOutlineCheck /> : <AiOutlineClose />,
        actions: (
          <ContainerButtons>
            <StyledButton
              onClick={() => editContent(content.id)}
              className="small info"
              title={`Editar ${contentType}`}
            >
              Editar
              <BiEdit />
            </StyledButton>{' '}
            <StyledButton
              onClick={() => activateOrInactivateContent(content.id, !content.active)}
              className="small warning"
              title={`${content.active ? 'Inativar' : 'Ativar'} ${contentType}`}
            >
              {content.active ? (
                <>
                  inativar <AiOutlineClose />
                </>
              ) : (
                <>
                  {' '}
                  ativar <AiOutlineCheck />
                </>
              )}
            </StyledButton>{' '}
            <StyledButton
              onClick={() => {
                removeContent(content.id);
              }}
              className="small danger"
              title={`Excluir ${contentType}`}
            >
              Deletar
              <BiTrash />
            </StyledButton>
          </ContainerButtons>
        ),
      }))
      : [];
  }, [contents, editContent, removeContent, activateOrInactivateContent, contentType]);

  const crumbs = useMemo(() => {
    const localCrumbs = [<Link to="/profile">Perfil</Link>];
    if (isKids) {
      localCrumbs.splice(1, 0, <span>Kids</span>, <span>Conteúdos</span>);
    } else if (isMusics) {
      localCrumbs.splice(1, 0, <span>Músicas</span>, <span>Shows, Apresentações e Videoclips</span>);
    } else {
      localCrumbs.splice(1, 0, <span>Conteúdos</span>);
    }

    return localCrumbs;
  }, [isKids, isMusics]);

  useEffect(() => {
    getContents();
  }, []);

  return (
    <ContentsContainer>
      <BreadCrumb crumbs={crumbs} />

      <DefaultPageTitle>{isMusics ? 'Shows, Apresentações e Videoclips' : 'Conteúdos'}</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createContent}>Criar {contentType}</DefaultButton>
        <div className="right">
          <DefaultInput
            placeholder="Pesquisar"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        searchText={search}
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Duração</span>,
            propName: 'duration',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={`Não foram encontrados ${contentType}s cadastrados!`}
      />
    </ContentsContainer>
  );
};

export default Contents;

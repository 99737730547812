import httpClient from '../http-client';

const cancelPayment = async (userId: string) => {
  await httpClient.post(`/payment/admin/${userId}/payment-cancel`);
};

const cancelPlan = async (userId: string) => {
  await httpClient.patch(`users/${userId}/cancel-subscription`);
};

const activeOrInactiveCourtesy = async (userId: string) => {
  await httpClient.patch(`/users/${userId}/courtesy`);
};

export { cancelPayment, cancelPlan, activeOrInactiveCourtesy };

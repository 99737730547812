import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { CreateAndEditCategoryContainer, Switch, ContainerButtons } from './style';
import checkEmptyString from '../../helpers/check-empty-string';
import {
  createCategory as createCategoryService,
  getCategory,
  updateCategory as updateCategoryService,
  categoryInHomePage as categoryInHomePageService,
  categoryCommemorativeInHomePage,
  categoryKids,
} from '../../services/categories';

interface CreateAndEditCategoryProps {
  categoryId: string;
}

const CreateAndEditCategory: React.FC = () => {
  const { categoryId } = useParams<CreateAndEditCategoryProps>();

  const history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [charactersNumber, setCharactersNumber] = useState(0);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isHighlightedCommemorative, setIsHighlightedCommemorative] = useState(false);
  const [isKids, setIsKids] = useState(false);

  const createCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para a categoria.');
      }

      if (isHighlightedCommemorative) {
        Swal.fire({
          title: 'Atenção',
          text: 'É permitida apenas uma categoria comemorativa por vez. Caso haja uma categoria selecionada como comemorativa anteriormente, a mesma será substituída pela escolha atual.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          focusConfirm: false,
        }).then(async (res) => {
          if (res.isConfirmed) {
            await createCategoryService({
              title: title,
              description: description.trim().length ? description : undefined,
              info: {
                is_home: isHighlighted,
                commemorative: isHighlightedCommemorative,
                kids: isKids,
              },
            });

            Swal.fire({
              title: 'Sucesso!',
              text: 'Categoria criada com sucesso!',
              icon: 'success',
            });

            goToCategories();
          }
        });
      } else {
        await createCategoryService({
          title: title,
          description: description.trim().length ? description : undefined,
          info: {
            is_home: isHighlighted,
            commemorative: isHighlightedCommemorative,
            kids: isKids,
          },
        });

        Swal.fire({
          title: 'Sucesso!',

          text: 'Categoria criada com sucesso!',


          icon: 'success',
        });

        goToCategories();
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o categoria. ' + error.message,
        icon: 'error',
      });
    }
  };

  const updateCategory = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para a categoria.');
      }
      await updateCategoryService(categoryId, {
        title: title,
        description: description || undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo editado com sucesso!',
        icon: 'success',
      });

      goToCategories();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o conteúdo. ' + error.message,
        icon: 'error',
      });
    }
  };

  const goToCategories = () => {
    history.push('/categories');
  };

  const getContent = useCallback(async () => {
    if (categoryId) {
      const content = await getCategory(categoryId);

      if (content && Object.keys(content).length) {
        setTitle(content.title);
        setDescription(content.description);
        setIsHighlighted((content.info && content.info.is_home) || false);
        setIsHighlightedCommemorative((content.info && content.info.commemorative) || false);
        setIsKids((content.info && content.info.kids) || false);
        !content.description ? setCharactersNumber(0) : setCharactersNumber(content.description.length);
      }
    }
  }, [categoryId]);

  const updateDescription = (event: any) => {
    const { target } = event;
    setDescription(target.value);

    if (target.value.length <= target.maxLength) setCharactersNumber(target.value.length);
  };

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (categoryId) {
      return true;
    }

    return false;
  }, [categoryId]);

  const updateHighlighted = async () => {
    await categoryInHomePageService(categoryId, !isHighlighted);
    setIsHighlighted(!isHighlighted);
  };

  const updateHighlightedCommemorative = async () => {
    await categoryCommemorativeInHomePage(categoryId, !isHighlightedCommemorative);
    await categoryInHomePageService(categoryId, false);
    setIsHighlightedCommemorative(!isHighlightedCommemorative);
  };

  const updateKids = async () => {
    await categoryInHomePageService(categoryId, !isHighlighted);
    await categoryCommemorativeInHomePage(categoryId, false);
    await categoryKids(categoryId, !isKids);

    setIsKids(!isKids);
  };

  return (
    <CreateAndEditCategoryContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/categories">Categorias</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Categorias</span>,
        ]}
      />

      <DefaultPageTitle>{isEditting ? 'Editar' : 'Criar'} Categorias</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} id="title" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea value={description || ''} onChange={(e) => updateDescription(e)} id="description" />
        </DefaultCreationFormGroup>
        <ContainerButtons>
          {!isHighlightedCommemorative && (
            <DefaultCreationFormGroup>
              <label>Destaque na home</label>
              <Switch>
                <input
                  type="checkbox"
                  checked={isHighlighted}
                  onChange={(e) => (isEditting ? updateHighlighted() : setIsHighlighted(!isHighlighted))}
                />
                <span className="slider round"></span>
              </Switch>
            </DefaultCreationFormGroup>
          )}

          {!isHighlightedCommemorative && (
            <DefaultCreationFormGroup>
              <label>Categoria Kids</label>
              <Switch>
                <input
                  type="checkbox"
                  checked={isKids}
                  onChange={(e) => {
                    isEditting ? updateKids() : setIsKids(!isKids);
                  }}
                />
                <span className="slider round"></span>
              </Switch>
            </DefaultCreationFormGroup>
          )}

          {!isHighlighted && !isKids && (
            <DefaultCreationFormGroup>
              <label>Categoria comemorativa</label>
              <Switch>
                <input
                  type="checkbox"
                  checked={isHighlightedCommemorative}
                  onChange={(e) => {
                    isEditting
                      ? updateHighlightedCommemorative()
                      : setIsHighlightedCommemorative(!isHighlightedCommemorative);
                  }}
                />
                <span className="slider round"></span>
              </Switch>
            </DefaultCreationFormGroup>
          )}
        </ContainerButtons>
        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToCategories}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditting ? updateCategory(e) : createCategory(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCategoryContainer>
  );
};

export default CreateAndEditCategory;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import {
  activateOrInactivateCategory as activateOrInactivateCategoryService,
  getAllCategories,
  deleteCategory,
} from '../../services/categories';
import { CategoriesContainer, StyledButton } from './style';
import Category from '../../models/category';
import DefaultInput from '../../components/DefaultInput';

const Categories: React.FC = () => {
  const [categories, setCategories] = useState([] as Category[]);
  const [search, setSearch] = useState('');
  const history = useHistory();

  const getCategories = async () => {
    const localCategories = await getAllCategories();
    setCategories(localCategories);
  };

  const editCategory = useCallback(
    (categoryId: any) => {
      history.push(`edit-category/${categoryId}`);
    },
    [history]
  );

  const removeCategory = useCallback(async (categoryId: any) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta categoria?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCategory(categoryId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Categoria excluída com sucesso!',
          });

          await getCategories();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir categoria. ${
              error.response ? 'Esta categoria já está associada a um conteúdo!' : error.message
            }`,
          });
        }
      }
    });
  }, []);

  const createCategory = async () => {
    history.push('create-category');
  };

  const activateOrInactivateCategory = useCallback(async (categoryId: any, activate: boolean) => {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} esta categoria?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateOrInactivateCategoryService(categoryId, activate);
          await getCategories();
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: `Erro ao ${activate ? 'ativar' : 'inativar'} categoria${
              error.response &&
              error.response.status === 400 &&
              error.response.data.message.includes('It is already enrolled in a content.')
                ? 'Esta categoria já está associado a um conteúdo!'
                : error.response.data.message.includes('It is already enrolled in a trail.')
                ? 'Esta categoria já está associado a uma trilha!'
                : `, pois está vinculada a um conteúdo. `
            }`,
          });
        }
      }
    });
  }, []);

  const contentsToBeShown = useMemo(() => {
    return categories && categories.length
      ? categories.map((category) => ({
          id: category.category_id,
          title: category.title,
          description: category.description,
          active: category.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          kids: category.info?.kids ? 'Kids' : 'Padrão',

          actions: (
            <>
              <StyledButton
                onClick={() => editCategory(category.category_id)}
                className="small info"
                title="Editar Categoria"
              >
                Editar
                <BiEdit />
              </StyledButton>{' '}
              <StyledButton
                onClick={() => activateOrInactivateCategory(category?.category_id, !category.is_active)}
                className="small warning"
                title={(category.is_active ? 'Inativar' : 'Ativar') + ' Categoria'}
              >
                {category.is_active ? (
                  <>
                    Inativar <AiOutlineClose />{' '}
                  </>
                ) : (
                  <>
                    Ativar
                    <AiOutlineCheck />
                  </>
                )}
              </StyledButton>{' '}
              <StyledButton
                onClick={() => {
                  removeCategory(category.category_id);
                }}
                className="small danger"
                title="Excluir Categoria"
              >
                Deletar
                <BiTrash />
              </StyledButton>
            </>
          ),
        }))
      : [];
  }, [categories, editCategory, removeCategory, activateOrInactivateCategory]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <CategoriesContainer>
      <BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Categorias</span>]} />

      <DefaultPageTitle>Categorias</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createCategory}>Criar Categoria</DefaultButton>

        <div className="right">
          <DefaultInput
            placeholder="Pesquisar"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        searchText={search}
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Kids</span>,
            propName: 'kids',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={'Não foram encontrados categorias cadastradas!'}
      />
    </CategoriesContainer>
  );
};

export default Categories;

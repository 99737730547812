import httpClient from '../http-client';
import Course from '../models/course';
import { uploadFile } from './files';

const getCourse = async (courseId: string) => {
  const course = (await httpClient.get<any>(`courses/${courseId}`)).data;

  if (course && course.contents) {
    course.contents = course.contents.sort((a: any, b: any) => (a.position > b.position ? 1 : -1));
  }

  return course;
};

const getAllCourses = async () => {
  const courses = (await httpClient.get<any>('courses')).data;

  return courses;
};

const deleteCourse = async (courseId: string) => {
  await httpClient.delete<any>(`courses/${courseId}`);
};

const createCourse = async (newCourse: any): Promise<Course> => {
  const createCourseResponse = (await httpClient.post<any>(`courses`, newCourse)).data;

  return createCourseResponse;
};

const updateCourse = async (courseId: string, newCourse: any) => {
  const updatedCourseResponse = (await httpClient.put<any>(`courses/${courseId}`, newCourse)).data;
  return updatedCourseResponse;
};

const changeCourseThumbnail = async (courseId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`courses/${courseId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const activateOrInactivateCourse = async (courseId: string, activate: boolean) => {
  await httpClient.put<any>(`courses/${courseId}/active`, { is_active: activate });
};

const getCoursesForSelect = async () => {
  const courses = (await httpClient.get<any>('courses/')).data;

  if (courses && courses.length) {
    return courses.map((course: any) => ({
      value: course.trail_id,
      label: course.description,
    }));
  }

  return [] as { value: string; label: string }[];
};

export {
  getAllCourses,
  deleteCourse,
  updateCourse,
  createCourse,
  getCourse,
  changeCourseThumbnail,
  activateOrInactivateCourse,
  getCoursesForSelect,
};

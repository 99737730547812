import httpClient from '../http-client';

import Producer from '../models/producer';

const getProducer = async (producerId: string) => {
  const producer = (await httpClient.get<Producer>(`producers/${producerId}`)).data;

  return producer;
};

const getAllProducers = async (filters?: object): Promise<Producer[]> => {
  const producers = (
    await httpClient.get<Producer[]>('producers', {
      params: filters,
    })
  ).data;

  return producers;
};

const createProducer = async (newProducer: any): Promise<Producer> => {
  const createProducerResponse = (await httpClient.post<any>(`producers/`, newProducer)).data;
  return createProducerResponse;
};

const updateProducer = async (producerId: string, newInfo: any) => {
  await httpClient.put(`producers/${producerId}`, newInfo);
};

const activateOrInactivateProducer = async (producerId: any, activate: boolean) => {
  await httpClient.patch(`producers/${producerId}/active`, { is_active: activate });
};

const deleteProducer = async (contentId: string) => {
  await httpClient.delete(`producers/${contentId}`);
};

export { getProducer, getAllProducers, createProducer, updateProducer, deleteProducer, activateOrInactivateProducer };

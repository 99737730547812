import React from 'react';
import Switch, {ReactSwitchProps} from "react-switch";

import { Container } from './styles'

type Props = ReactSwitchProps & {
  label: string;
};

const Switcher: React.FC<Props> = ({
  label,
  checked,
  onChange,
  uncheckedIcon = false,
  checkedIcon = false,
  ...rest
}) => {

  return (
    <Container>
    <label>{label}</label>

    <Switch 
      onChange={onChange} 
      checked={checked} 
      uncheckedIcon={uncheckedIcon}
      checkedIcon={checkedIcon}
      {...rest}
    />
  </Container>
  )
}

export default  Switcher 

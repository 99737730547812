import httpClient from '../http-client';
import Author from '../models/author';
import AuthorForCreate from '../models/for-create/authors';
import AuthorForUpdate from '../models/for-update/author';
import { uploadFile } from './files';

const getAuthor = async (authorId: string) => {
  const author = (await httpClient.get<Author>(`authors/${authorId}`)).data;

  return author;
};

const getAllAuthors = async (filters?: object) => {
  const authors = (
    await httpClient.get<Author[]>('authors', {
      params: filters,
    })
  ).data;

  return authors;
};

const changeAuthorBanner = async (authorId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.put(`authors/${authorId}/banner`, {
    info: { banner_url: uploadedFile.reference },
  });
};

const createAuthor = async (newAuthor: AuthorForCreate): Promise<Author> => {
  if (!newAuthor.info) newAuthor.info = {};
  const createAuthorResponse = (await httpClient.post<any>(`authors/`, newAuthor)).data;
  return createAuthorResponse;
};

const updateAuthor = async (authorId: string, newInfo: AuthorForUpdate) => {
  await httpClient.put(`authors/${authorId}`, newInfo);
};

const updateReputed = async (authorId: string, is_reputed: boolean) => {
  await httpClient.put(`authors/${authorId}/reputed`, { info: { is_reputed } });
};

const deleteAuthor = async (contentId: string) => {
  await httpClient.delete(`authors/${contentId}`);
};

const changeAuthorThumbnail = async (authorId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`authors/${authorId}/avatar`, {
    avatar_url: uploadedFile.reference,
  });
};

const activateOrInactivateContent = async (contentId: string, activate: boolean) => {
  await httpClient.put(`contents/${contentId}/active`, { is_active: activate });
};

export {
  getAllAuthors,
  deleteAuthor,
  updateAuthor,
  updateReputed,
  createAuthor,
  changeAuthorThumbnail,
  changeAuthorBanner,
  getAuthor,
  activateOrInactivateContent,
};

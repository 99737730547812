import { AiOutlineFileDone } from 'react-icons/ai';
import { IoMdMusicalNote } from 'react-icons/io';
import { BiCameraMovie, BiMovie, BiLike, BiUnlink } from 'react-icons/bi';
import { RiGitRepositoryLine, RiPlayListAddFill } from 'react-icons/ri';
import { BsCameraVideo, BsMusicNoteBeamed, BsPerson } from 'react-icons/bs';
import { FaRegEye } from 'react-icons/fa';
import { FaChild } from 'react-icons/fa';
import { MdDashboard, MdLibraryMusic } from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';
import { BsCardList } from 'react-icons/bs';

interface Menu {
  label: JSX.Element;
  path?: string;
  children?: SubMenu[];
}

interface SubMenu {
  label: JSX.Element;
  path: string;
}

const menus = [
  {
    label: (
      <>
        <span className="icon">
          <MdDashboard />
        </span>
        <span className="text">Dashboard</span>
      </>
    ),
    path: '/profile',
  },
  {
    label: (
      <>
        <span className="icon">
          <BsPerson />
        </span>
        <span className="text">Palestrantes</span>
      </>
    ),
    path: '/authors',
  },
  {
    label: (
      <>
        <span className="icon">
          <BsPerson />
        </span>
        <span className="text">Produtores</span>
      </>
    ),
    path: '/producers',
  },
  {
    label: (
      <>
        <span className="icon">
          <RiPlayListAddFill />
        </span>
        <span className="text">Categorias</span>
      </>
    ),
    path: '/categories',
  },
  {
    label: (
      <>
        <span className="icon">
          <BsCardList />
        </span>
        <span className="text">Planos</span>
      </>
    ),
    path: '/plans',
  },
  {
    label: (
      <>
        <span className="icon">
          <BiCameraMovie />
        </span>
        <span className="text">Conteúdos</span>
      </>
    ),
    path: '/contents',
  },
  {
    label: (
      <>
        <span className="icon">
          <BiMovie />
        </span>
        <span className="text">Séries</span>
      </>
    ),
    path: '/trails/series',
  },
  {
    label: (
      <>
        <span className="icon">
          <BsMusicNoteBeamed />
        </span>
        <span className="text">Músicas</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <IoMdMusicalNote />
            </span>
            <span className="text">Shows, Apresentações e Videoclips</span>
          </>
        ),
        path: '/musics/contents',
      },
      {
        label: (
          <>
            <span className="icon">
              <MdLibraryMusic />
            </span>
            <span className="text">Álbuns Musicais</span>
          </>
        ),
        path: '/musics/trails/series',
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <FaChild />
        </span>
        <span className="text">Kids</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <BiCameraMovie />
            </span>
            <span className="text">Conteúdos</span>
          </>
        ),
        path: '/kids/contents',
      },
      {
        label: (
          <>
            <span className="icon">
              <BiMovie />
            </span>
            <span className="text">Séries</span>
          </>
        ),
        path: '/kids/trails/series',
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <BiUnlink />
        </span>
        <span className="text">Episódios desvinculados</span>
      </>
    ),
    path: '/unlinked-episodes',
  },
  {
    label: (
      <>
        <span className="icon">
          <BsCameraVideo />
        </span>
        <span className="text">Lives</span>
      </>
    ),
    path: '/lives',
  },
  {
    label: (
      <>
        <span className="icon">
          <FiUsers />
        </span>
        <span className="text">Usuários</span>
      </>
    ),
    path: '/payments',
  },
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineFileDone />
        </span>
        <span className="text">Termos de Uso</span>
      </>
    ),
    path: '/terms',
  },
  {
    label: (
      <>
        <span className="icon">
          <RiGitRepositoryLine />
        </span>
        <span className="text">Relatórios</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <BiCameraMovie />
            </span>
            <span className="text">Produtores</span>
          </>
        ),
        path: '/reports/producers',
      },
      {
        label: (
          <>
            <span className="icon">
              <BiLike />
            </span>
            <span className="text">Curtidas</span>
          </>
        ),
        path: '/reports/likes',
      },
      {
        label: (
          <>
            <span className="icon">
              <FaRegEye />
            </span>
            <span className="text">Total de visualizações</span>
          </>
        ),
        path: '/reports/total-vieweds',
      },
    ],
  },
] as Menu[];

export default menus;

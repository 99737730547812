import httpClient from '../http-client/index';

const login = async (email: string, password: string) => {
  const { data: response } = await httpClient.post('/cognito/login', {
    email,
    password,
    in_admin: true
  });

  if (!response.user || !response.user.is_admin) {
    localStorage.removeItem('teomidia-api-token');
    localStorage.removeItem('actual-teomidia-user');
    throw new Error('Você não possui acesso ao painel administrativo!');
  }

  if (response.api_token) {
    localStorage.setItem('teomidia-api-token', response.api_token);
    localStorage.setItem('actual-teomidia-user', JSON.stringify(response.user));
  }
};

export { login };

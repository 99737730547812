import styled from 'styled-components';

export const ContainerDate = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  input[type='date'] {
    height: 40px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px var(--default-dark-gray);
    margin-bottom: 2rem;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  > div {
    ul {
      li {
        list-style: none;
      }
    }
  }
`;

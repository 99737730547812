import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';

import Producer from '../../models/producer';
import { getAllProducers } from '../../services/producer';
import { DefaultCreationFormButtonGroup, DefaultCreationFormGroup } from '../../components/DefaultCreationForm';
import { ContainerDate } from './styles';

import { generateProducerReport } from '../../services/reports';
import getErrorMessage from '../../helpers/get-error-message';
import { getDates } from '../../utils/validateDate';

const ReportsProducers = () => {
  const [producers, setProducers] = useState<Producer[]>();
  const [selectedProducer, setSelectedProducer] = useState({} as unknown as { label: string; value: string });
  const [initialDate, setInitialDate] = useState<any>('');
  const [finalDate, setFinalDate] = useState<any>('');

  const getProducers = useCallback(async () => {
    const allProducers = await getAllProducers();

    if (allProducers && allProducers.length) {
      setProducers(allProducers);
    }
  }, []);

  const getReportProducer = useCallback(
    async (format: string) => {
      const initialDateArray = initialDate.split('-');
      const finalDateArray = finalDate.split('-');
      const date1 = new Date(`${initialDateArray[1]}/${initialDateArray[2]}/${initialDateArray[0]}`);
      const date2 = new Date(`${finalDateArray[1]}/${finalDateArray[2]}/${finalDateArray[0]}`);
      const differenceInTime = date2.getTime() - date1.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      if (!initialDate || !finalDate) {
        return Swal.fire({
          title: 'Erro',
          text: 'É necessário informar as datas',
        });
      }
      if (differenceInDays >= 90) {
        return Swal.fire({
          title: 'Erro',
          text: 'A data deve ter um intervalo de até 90 dias',
        });
      }

      const filters: any = {
        viewed_at_gte: initialDate || undefined,
        viewed_at_lte: finalDate || undefined,
        producer_id_ft: selectedProducer.value ? [selectedProducer.value] : undefined,
      };
      try {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Download do relatório iniciado com sucesso',
        });
        await generateProducerReport(format, filters);
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        Swal.fire({
          title: 'Erro',
          text: errorMessage ? `Erro ao fazer download do relatório` : errorMessage,
        });
      }
    },
    [finalDate, initialDate, selectedProducer.value]
  );

  const producersToBeSelected = useMemo(() => {
    if (producers && producers.length) {
      const sortedProducers = producers.sort((a, b) => 
        a.name.localeCompare(b.name)
      );

      return sortedProducers.map((producer) => ({
        label: producer.name,
        value: `${producer.producer_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [producers]);

  const clearFields = () => {
    setSelectedProducer({ label: '', value: '' });
    setInitialDate('');
    setFinalDate('');
  };

  useEffect(() => {
    getProducers();
  }, [getProducers]);

  return (
    <div>
      <BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Relatórios</span>]} />
      <DefaultPageTitle>Produtores</DefaultPageTitle>
      <CreateButtonDefaultContainer>
        <div className="right">
          <DefaultFilterForm />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultCreationFormGroup>
        <label htmlFor="producers">Produtores</label>
        <Select
          styles={{
            container: (provided) => ({
              ...provided,
              flexGrow: 1,
              width: '100%',
            }),
          }}
          options={producersToBeSelected}
          value={selectedProducer}
          onChange={(option) => {
            option && setSelectedProducer(option);
          }}
          id="producers"
          isMulti={false}
          placeholder="Selecione um produtor"
        />
      </DefaultCreationFormGroup>
      <ContainerDate>
        <DefaultCreationFormGroup>
          <label htmlFor="initialDate">Data Inicial*</label>

          <input type="date" id="initialDate" onChange={(e) => setInitialDate(e.target.value)} value={initialDate} />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="finalDate">Data Final*</label>

          <input type="date" id="finalDate" onChange={(e) => setFinalDate(e.target.value)} value={finalDate} />
        </DefaultCreationFormGroup>
      </ContainerDate>

      <DefaultCreationFormButtonGroup>
        <DefaultButton className="danger" onClick={() => clearFields()}>
          Limpar Campos
        </DefaultButton>

        <DefaultButton
          type="submit"
          className="sucess"
          onClick={() => {
            getReportProducer('csv');
            clearFields();
          }}
        >
          Gerar Relatório (CSV)
        </DefaultButton>

        <DefaultButton
          type="submit"
          className="sucess"
          onClick={() => {
            getReportProducer('pdf');
            clearFields();
          }}
        >
          Gerar Relatório (PDF)
        </DefaultButton>
      </DefaultCreationFormButtonGroup>
    </div>
  );
};

export default ReportsProducers;

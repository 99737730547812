import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Trail from '../../models/trail';
import {
  deleteCourse,
  getAllCourses,
  getAlbums,
  activateOrInactivateCourse as activateOrInactivateCourseService,
} from '../../services/trails';
import { ContainerButtons, CoursesContainer, StyledButton } from './style';

interface SerieProps {
  isKids?: boolean;
  isMusics?: boolean;
}

const Courses: React.FC<SerieProps> = ({ isKids = false, isMusics }) => {
  const [courses, setCourses] = useState([] as Trail[]);
  const [contentType, setContentType] = useState(isMusics ? 'Álbuns' : 'Série');
  const [search, setSearch] = useState('');

  const history = useHistory();
  const location = useLocation();

  const getCourses = async () => {
    let localCourses
    isMusics
      ? localCourses = await getAlbums()
      : localCourses = await getAllCourses({ is_kids: isKids });

    setCourses(localCourses);
  };

  const editCourse = useCallback(
    (courseId: any) => {
      if (!isMusics && !isKids) {
        history.push(`/trails/edit-serie/${courseId}`);
        return;
      }
      if (isKids) {
        history.push(`/kids/trails/edit-serie/${courseId}`);
        return;
      }
      if (isMusics) {
        history.push(`/musics/trails/edit-serie/${courseId}`);
      }
    },
    [history]
  );

  const removeCourse = useCallback(async (courseId: any) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: `Tem certeza que deseja remover este ${contentType}?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCourse(courseId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Série excluída com sucesso!',
          });

          await getCourses();
        } catch (e: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao excluir série',
            text: e.message,
          });
        }
      }
    });
  }, []);

  const createCourse = async () => {
    if (!isKids && !isMusics) {
      history.push('/trails/create-serie');
      return;
    }
    if (isKids) {
      history.push('/kids/trails/create-serie');
      return;
    }
    history.push(`/musics/trails/create-serie`);
  };

  const activateOrInactivateCourse = useCallback(async (courseId: any, activate: boolean) => {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} este ${contentType}?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateOrInactivateCourseService(courseId, activate);

          getCourses();
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: `Erro ao ${activate ? 'ativar' : 'inativar'} ${contentType}!`,
          });
        }
      }
    });
  }, []);

  const coursesToBeShown = useMemo(() => {
    return courses && courses.length
      ? courses.map((course) => ({
        id: course.id,
        course_id: course.course_id,
        name: course.name,
        description: course.description,
        active: course.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
        actions: (
          <ContainerButtons>
            <StyledButton
              className="small info"
              onClick={() => editCourse(course.trail_id)}
              title={`Editar ${contentType}`}
            >
              Editar
              <BiEdit />
            </StyledButton>{' '}
            <StyledButton
              onClick={() => activateOrInactivateCourse(course.trail_id, !course.is_active)}
              className="small warning"
              title={`${course.is_active ? 'Inativar' : 'Ativar'} ${contentType}`}
            >
              {course.is_active ? (
                <>
                  Inativar <AiOutlineClose />{' '}
                </>
              ) : (
                <>
                  Ativar <AiOutlineCheck />
                </>
              )}
            </StyledButton>{' '}
            <StyledButton
              onClick={() => removeCourse(course.trail_id)}
              className="small danger"
              title={`Excluir ${contentType}`}
            >
              Deletar
              <BiTrash />
            </StyledButton>
          </ContainerButtons>
        ),
      }))
      : [];
  }, [courses, editCourse, removeCourse, activateOrInactivateCourse]);

  useEffect(() => {
    getCourses();
  }, [location.pathname]);

  return (
    <CoursesContainer>
      <BreadCrumb
        crumbs={
          isMusics
            ? [<Link to="/profile">Perfil</Link>, <span>Músicas</span>, <span>Álbuns Musicais</span>]
            : isKids
              ? [<Link to="/profile">Perfil</Link>, <span>Kids</span>, <span>Séries</span>]
              : [<Link to="/profile">Perfil</Link>, <span>Séries</span>]
        }
      />

      <DefaultPageTitle>{contentType}</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createCourse}>Criar {contentType}</DefaultButton>
        <div className="right">
          <DefaultInput
            placeholder="Pesquisar"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        searchText={search}
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: 'active',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={coursesToBeShown}
        emptyListMessage={`Não foram encontradas ${contentType} cadastrados!`}
      />
    </CoursesContainer>
  );
};

export default Courses;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { hideModal, showModal } from '../../helpers/modal';
import { ContentThumbnail, CreateAndEditAuthorContainer, ThumbnailUploadContainer, Switch } from './style';

import CutImage from '../../components/CutImage';
import checkEmptyString from '../../helpers/check-empty-string';
import {
  createAuthor,
  getAuthor,
  updateAuthor,
  updateReputed as updateReputedService,
  changeAuthorThumbnail as changeAuthorThumbnailService,
  changeAuthorBanner as changeAuthorBannerService,
  deleteAuthor,
} from '../../services/authors';

interface CreateAndEditContentProps {
  authorId: string;
}

const CreateAndEditContent: React.FC = () => {
  const { authorId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [isReputed, setIsReputed] = useState(false);
  const [bannerFileToUpload, setBannerFileToUpload] = useState<File>();
  const [banner, setBanner] = useState('');

  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState<any>();
  const [charactersNumber, setCharactersNumber] = useState(0);

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o palestrante.');
      }

      if (isReputed && (!banner || !bannerFileToUpload)) {
        throw new Error('Informe um banner para o palestrante.');
      }

      if (!thumbnail || !thumbnailFileToUpload) {
        throw new Error('Informe um avatar para o palestrante.');
      }

      const createdAuthor = await createAuthor({
        name,
        description: description.trim().length ? description : undefined,
        info: isReputed && banner && bannerFileToUpload ? { is_reputed: true } : {},
      });

      if (bannerFileToUpload) {
        try {
          await changeContentBanner(bannerFileToUpload, createdAuthor.author_id);
        } catch (error) {
          await deleteAuthor(createdAuthor.author_id);

          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(thumbnailFileToUpload, createdAuthor.author_id);
        } catch (error) {
          await deleteAuthor(createdAuthor.author_id);

          throw new Error(
            'Erro ao fazer upload da thumbnail. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      Swal.fire({
        title: '',
        text: 'Palestrante criado com sucesso!',
        icon: 'success',
      });

      goToAuthors();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o palestrante. ' + error.message,
        icon: 'error',
      });
    }
  };

  const changeContentBanner = async (localThumbnailFileToUpload: File, localAuthorId: string) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append('name', `content_thumbnail_${localAuthorId}.${localThumbnailFileToUpload.type}`);
    formData.append('description', `thumbnail do filme de id ${localAuthorId}`);

    await changeAuthorBannerService(localAuthorId, formData);
  };

  const changeContentThumbnail = async (localThumbnailFileToUpload: File, localAuthorId: string) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append('name', `content_thumbnail_${localAuthorId}.${localThumbnailFileToUpload.type}`);
    formData.append('description', `thumbnail do filme de id ${localAuthorId}`);

    await changeAuthorThumbnailService(localAuthorId, formData);
  };

  const updateReputed = async () => {
    await updateReputedService(authorId, !isReputed);
    setIsReputed(!isReputed);
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(thumbnailFileToUpload, authorId);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      if (bannerFileToUpload) {
        try {
          await changeContentBanner(bannerFileToUpload, authorId);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      if (checkEmptyString(name)) {
        throw new Error('Informe um nome válido para o palestrante.');
      }

      await updateAuthor(authorId, {
        name,
        description: description || undefined,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Palestrante editado com sucesso!',
        icon: 'success',
      });

      goToAuthors();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o palestrante. ' + error.message,
        icon: 'error',
      });
    }
  };

  const goToAuthors = () => {
    history.push('/authors');
  };

  const getContent = useCallback(async () => {
    if (authorId) {
      const author = await getAuthor(authorId);
      if (author && Object.keys(author).length) {
        setName(author.name);
        setDescription(author.description);
        setIsReputed(author.info.is_reputed);
        setBanner(author.info.banner_url);
        setThumbnail(author.avatar_url);
        !author.description ? setCharactersNumber(0) : setCharactersNumber(author.description.length);
      }
    }
  }, [authorId]);

  const onCutBanner = (file: File) => {
    if (file) {
      setBannerFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setBanner(`${reader.result}`);

      hideModal();
    }
  };

  const onCutThumbnail = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const selectBanner = () => {
    showModal('Selecionar Imagem do Destaque', <CutImage aspect={1.812} onCutImage={onCutBanner} />);
  };

  const selectThumbnail = () => {
    showModal('Selecionar Imagem do Destaque', <CutImage aspect={0.6} onCutImage={onCutThumbnail} />);
  };

  const updateDescription = (event: any) => {
    const { target } = event;
    setDescription(target.value);

    if (target.value.length <= target.maxLength) setCharactersNumber(target.value.length);
  };

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (authorId) {
      return true;
    }

    return false;
  }, [authorId]);

  return (
    <CreateAndEditAuthorContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/authors">Palestrantes</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Palestrante</span>,
        ]}
      />

      <DefaultPageTitle>{isEditting ? 'Editar' : 'Criar'} Palestrante</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Nome
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="title" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea value={description || ''} onChange={(e) => updateDescription(e)} id="description" />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Avatar
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de avatar
            </DefaultButton>

            {thumbnail && <ContentThumbnail width="30%" src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label>Renomado</label>
          <Switch>
            <input
              type="checkbox"
              checked={isReputed}
              onChange={(e) => (isEditting ? updateReputed() : setIsReputed(!isReputed))}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        {isReputed && (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="reference">
              Banner
            </label>

            <ThumbnailUploadContainer>
              <DefaultButton type="button" onClick={selectBanner}>
                Selecionar Imagem do Banner
              </DefaultButton>

              {banner && <ContentThumbnail src={banner} />}
            </ThumbnailUploadContainer>
          </DefaultCreationFormGroup>
        )}
        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToAuthors}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditting ? updateContent(e) : createContent(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditAuthorContainer>
  );
};

export default CreateAndEditContent;

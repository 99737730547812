import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';

import Producer from '../../models/producer';

import { getAllProducers } from '../../services/producer';
import { getAllCourses } from '../../services/trails';
import { ListContents } from '../../services/contents';
import { DefaultCreationFormButtonGroup, DefaultCreationFormGroup } from '../../components/DefaultCreationForm';
import { ContainerDate } from './styles';

import { generateLikeReport, generateTotalViewedReport } from '../../services/reports';
import getErrorMessage from '../../helpers/get-error-message';
import { getDates } from '../../utils/validateDate';

interface IContent {
  label: string;
  value: string;
}

const ReportsTotalVieweds = () => {
  const [producers, setProducers] = useState<Producer[]>();
  const [contents, setContents] = useState<IContent[] | []>([]);
  const [courses, setCourses] = useState<IContent[] | []>([]);
  const [selectedProducer, setSelectedProducer] = useState<IContent[] | []>([]);
  const [selectedContent, setSelectedContent] = useState<IContent[] | []>([]);
  const [selectedCourse, setSelectedCourse] = useState<IContent[] | []>([]);
  const [initialDate, setInitialDate] = useState<any>('');
  const [finalDate, setFinalDate] = useState<any>('');

  const getProducers = useCallback(async () => {
    const allProducers = await getAllProducers();

    if (allProducers && allProducers.length) {
      setProducers(allProducers);
    }
  }, []);
  const getCourses = useCallback(async () => {
    let allCourses = await getAllCourses({ is_kids: false });
    allCourses = allCourses.map((course: { title: string; name: string; trail_id: string }) => ({
      label: course.name,
      value: course.trail_id,
    }));
    if (allCourses && allCourses.length) {
      setCourses(allCourses);
    }
  }, []);
  const getContents = useCallback(async () => {
    let content = await ListContents();
    const allContents = content.map((content: { id: string; title: string }) => ({
      label: content.title,
      value: content.id,
    }));
    if (allContents && allContents.length) {
      setContents([...allContents]);
    }
  }, []);

  const getReportTotalViewed = useCallback(
    async (format: string) => {
      let query: string = '?';

      const initialDateArray = initialDate.split('-');
      const finalDateArray = finalDate.split('-');
      const date1 = new Date(`${initialDateArray[1]}/${initialDateArray[2]}/${initialDateArray[0]}`);
      const date2 = new Date(`${finalDateArray[1]}/${finalDateArray[2]}/${finalDateArray[0]}`);
      const differenceInTime = date2.getTime() - date1.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      if (!initialDate || !finalDate) {
        return Swal.fire({
          title: 'Erro',
          text: 'É necessário informar as datas',
        });
      }
      if (differenceInDays >= 90) {
        return Swal.fire({
          title: 'Erro',
          text: 'A data deve ter um intervalo de até 90 dias',
        });
      }

      if (initialDate) {
        if (query.length > 1) query += `&created_at_gte=${initialDate}`;
        else query += `created_at_gte=${initialDate}`;
      }
      if (finalDate) {
        if (query.length > 1) query += `&created_at_lte=${finalDate}`;
        else query += `created_at_lte=${finalDate}`;
      }

      if (selectedProducer.length > 0) {
        selectedProducer.forEach((producer: IContent) => {
          if (query.length > 1) query += `&producers_id[]=${producer.value}`;
          else query += `producers_id[]=${producer.value}`;
        });
      } else if (selectedContent.length > 0) {
        selectedContent.forEach((content: IContent) => {
          if (query.length > 1) query += `&contents_id[]=${content.value}`;
          else query += `contents_id[]=${content.value}`;
        });
      } else if (selectedCourse.length > 0) {
        selectedCourse.forEach((course: IContent) => {
          if (query.length > 1) query += `&trails_id[]=${course.value}`;
          else query += `trails_id[]=${course.value}`;
        });
      }

      try {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Download do relatório iniciado com sucesso',
        });
        await generateTotalViewedReport(format, query, initialDate, finalDate);
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        Swal.fire({
          title: 'Erro',
          text: errorMessage ? `Erro ao fazer download do relatório` : errorMessage,
        });
      }
    },
    [finalDate, initialDate, selectedContent, selectedProducer, selectedCourse]
  );

  const producersToBeSelected = useMemo(() => {
    if (producers && producers.length) {
      const sortedProducers = producers.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      return sortedProducers.map((producer) => ({
        label: producer.name,
        value: `${producer.producer_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [producers]);

  const contentsToBeSelected = useMemo(() => {
    if (contents && contents.length) {
      const sortedContents = contents.sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      return sortedContents.map((course: IContent) => ({
        label: course.label,
        value: `${course.value}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [contents]);

  const coursesToBeSelected = useMemo(() => {
    if (courses && courses.length) {
      const sortedCourses = courses.sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      return sortedCourses.map((course: IContent) => ({
        label: course.label,
        value: `${course.value}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [courses]);

  const clearFields = () => {
    setSelectedProducer([]);
    setSelectedContent([]);
    setSelectedCourse([]);
    setInitialDate('');
    setFinalDate('');
  };

  useEffect(() => {
    getProducers();
    getContents();
    getCourses();
  }, [getProducers, getContents, getCourses]);

  return (
    <div>
      <BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Relatórios</span>]} />
      <DefaultPageTitle>Tempo de visualização</DefaultPageTitle>
      <CreateButtonDefaultContainer>
        <div className="right">
          <DefaultFilterForm />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultCreationFormGroup>
        <label htmlFor="producers">Produtores</label>
        <Select
          isDisabled={selectedContent.length > 0 || selectedCourse.length > 0 ? true : false}
          styles={{
            container: (provided) => ({
              ...provided,
              flexGrow: 1,
              width: '100%',
            }),
          }}
          isClearable={true}
          value={selectedProducer}
          options={producersToBeSelected}
          onChange={(option: any) => {
            option && setSelectedProducer(option);
          }}
          id="producers"
          isMulti
          placeholder="Selecione um produtor"
        />
      </DefaultCreationFormGroup>

      <DefaultCreationFormGroup>
        <label htmlFor="contents">Conteúdos</label>
        <Select
          isDisabled={selectedProducer.length > 0 || selectedCourse.length > 0 ? true : false}
          styles={{
            container: (provided) => ({
              ...provided,
              flexGrow: 1,
              width: '100%',
            }),
          }}
          value={selectedContent}
          isClearable={true}
          options={contentsToBeSelected}
          onChange={(option: any) => setSelectedContent(option)}
          id="contents"
          isMulti
          placeholder="Selecione um conteúdo"
        />
      </DefaultCreationFormGroup>
      <DefaultCreationFormGroup>
        <label htmlFor="courses">Séries</label>
        <Select
          isDisabled={selectedProducer.length > 0 || selectedContent.length > 0 ? true : false}
          styles={{
            container: (provided) => ({
              ...provided,
              flexGrow: 1,
              width: '100%',
            }),
          }}
          value={selectedCourse}
          isClearable={true}
          onChange={(option: any) => setSelectedCourse(option)}
          options={coursesToBeSelected}
          id="courses"
          isMulti
          placeholder="Selecione uma série"
        />
      </DefaultCreationFormGroup>
      <ContainerDate>
        <DefaultCreationFormGroup>
          <label htmlFor="initialDate">Data Inicial*</label>

          <input type="date" id="initialDate" onChange={(e) => setInitialDate(e.target.value)} value={initialDate} />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor="finalDate">Data Final*</label>

          <input type="date" id="finalDate" onChange={(e) => setFinalDate(e.target.value)} value={finalDate} />
        </DefaultCreationFormGroup>
      </ContainerDate>

      <DefaultCreationFormButtonGroup>
        <DefaultButton className="danger" onClick={() => clearFields()}>
          Limpar Campos
        </DefaultButton>

        <DefaultButton
          type="submit"
          className="sucess"
          onClick={() => {
            getReportTotalViewed('csv');
            clearFields();
          }}
        >
          Gerar Relatório (CSV)
        </DefaultButton>

        <DefaultButton
          type="submit"
          className="sucess"
          onClick={() => {
            getReportTotalViewed('pdf');
            clearFields();
          }}
        >
          Gerar Relatório (PDF)
        </DefaultButton>
      </DefaultCreationFormButtonGroup>
    </div>
  );
};

export default ReportsTotalVieweds;

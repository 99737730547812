import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineDown, AiOutlineEdit, AiOutlineUp } from 'react-icons/ai';
import Swal from 'sweetalert2';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { ReactComponent as DetachButton } from '../../assets/unlinkDetach.svg';
import { hideModal, showModal } from '../../helpers/modal';
import checkEmptyString from '../../helpers/check-empty-string';
import Lesson from '../../models/lesson';
import {
  getCourse as getCourseService,
  updateCourse as updateCourseService,
  createCourse as createCourseService,
} from '../../services/courses';
import { SortLessonsContainer, LessonSelectionContainer, CreateAndEditCourseContainer } from './style';
import Course from '../../models/course';
import CreateAndEditEpisode from '../CreateAndEditEpisode';
import { updateLesson } from '../../services/lessons';

interface CreateAndEditCourseProps {
  courseId?: string;
  onCreateOrUpdate?: (season: Course) => void;
  modalId?: string;
  isMusic?: boolean;
}

const CreateAndEditCourse: React.FC<CreateAndEditCourseProps> = ({ courseId, onCreateOrUpdate, modalId, isMusic }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedLessons, setSelectedLessons] = useState([] as Lesson[]);
  const [episodesToRemove, setEpisodesToRemove] = useState([] as Lesson[]);
  const [charactersNumber, setCharactersNumber] = useState(0);

  const [idOfAddingOrEdittingEpisode, setIdOfAddingOrEdittingEpisode] = useState('');

  const addEpisode = () => {
    if (idOfAddingOrEdittingEpisode) {
      hideModal(idOfAddingOrEdittingEpisode);
    }

    const localIdOfAddingOrEdittingEpisode = showModal(
      (isMusic ? 'Adicionar Música' : 'Adicionar Episódio'),
      <CreateAndEditEpisode onCreateOrUpdate={includeEpisode} isMusic={isMusic} />
    );
    setIdOfAddingOrEdittingEpisode(localIdOfAddingOrEdittingEpisode);
  };

  const editEpisode = (episodeId: string) => {
    const localEditingSeasonModalId = showModal(
      (isMusic ? 'Editar Música' : 'Editar Episódio'),
      <CreateAndEditEpisode contentId={episodeId} onCreateOrUpdate={includeEpisode} />
    );
    setIdOfAddingOrEdittingEpisode(localEditingSeasonModalId);
  };

  const includeEpisode = (episode: Lesson) => {
    const localSelectedLessons = selectedLessons || [];
    if (!localSelectedLessons.some((lesson) => lesson.content_id === episode.content_id)) {
      setSelectedLessons([...localSelectedLessons, episode]);
    } else {
      const foundLesson = localSelectedLessons.find((lesson) => lesson.content_id === episode.content_id);
      if (foundLesson) {
        const indexOfLesson = localSelectedLessons.indexOf(foundLesson);
        if (indexOfLesson > -1) {
          localSelectedLessons.splice(indexOfLesson, 1, episode);
          setSelectedLessons([...localSelectedLessons]);
        }
      }
    }
  };

  const createCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error(`Informe um título válido para a temporada.`);
      }

      if (!selectedLessons || !selectedLessons.length) {
        throw new Error(`Selecione ${isMusic ? 'uma ou mais músicas' : 'um ou mais episódios'} antes.`);
      }

      const createdCourse = await createCourseService({
        title: name,
        description: description.trim().length ? description : undefined,
        contents: selectedLessons.map((lesson, index) => ({
          content_id: lesson.content_id,
          position: index + 1,
        })),
      });

      Swal.fire({
        title: 'Sucesso!',
        text: `Temporada criada com sucesso!`,
        icon: 'success',
      });

      if (onCreateOrUpdate) {
        onCreateOrUpdate(createdCourse);
      }

      if (episodesToRemove && episodesToRemove.length) {
        for (let episode of episodesToRemove) {
          try {
            await updateLesson(episode.content_id!, { tag: 'unlinked' });
          } catch (error) { }
        }
      }

      hideModal(modalId);
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar a temporada. ${error.message}`,
        icon: 'error',
      });
    }
  };

  const updateCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error(`Informe um nome válido para a temporada.`);
      }

      if (!selectedLessons || !selectedLessons.length) {
        throw new Error(`Selecione ${isMusic ? 'uma ou mais músicas' : 'um ou mais episódios'} antes.`);
      }

      const updatedCourse = await updateCourseService(courseId!, {
        title: name,
        description: description || undefined,
        contents: selectedLessons.map((lesson, index) => ({
          content_id: lesson.content_id,
          position: index + 1,
        })),
      });

      Swal.fire({
        title: 'Sucesso!',
        text: `Temporada editada com sucesso!`,
        icon: 'success',
      });

      if (onCreateOrUpdate) {
        onCreateOrUpdate(updatedCourse);
      }

      if (episodesToRemove && episodesToRemove.length) {
        for (let episode of episodesToRemove) {
          try {
            await updateLesson(episode.content_id!, { tag: 'unlinked' });
          } catch (error) { }
        }
      }

      hideModal(modalId);
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar a temporada. ${error.message}`,
        icon: 'error',
      });
    }
  };

  const reorderSelectedLessons = (lesson: Lesson, up: boolean) => {
    if (selectedLessons) {
      const localSelectedLessons = [...selectedLessons];
      const indexOfLesson = localSelectedLessons.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedLessons.length) {
        localSelectedLessons.splice(indexOfLesson, 1);
        if (up) {
          localSelectedLessons.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedLessons.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedLessons(localSelectedLessons);
    }
  };

  const removeSelectedLesson = async (lessonToBeRemoved: Lesson) => {
    Swal.fire({
      icon: 'question',
      text: `Tem certeza que deseja deletar ${isMusic ? 'essa música' : 'esse episódio'} da temporada?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const lessons = [...selectedLessons];
        const filteredList = lessons.filter((lesson) => lesson.content_id !== lessonToBeRemoved.content_id);
        const reorderedList = filteredList.map((lesson, index) => ({
          ...lesson,
          position: index + 1,
        }));
        setSelectedLessons(reorderedList);

        setEpisodesToRemove([...episodesToRemove, lessonToBeRemoved]);
      }
    });
  };

  const getCourse = useCallback(async () => {
    if (courseId) {
      const course = await getCourseService(courseId);
      if (course && Object.keys(course).length) {
        setName(course.title);
        setDescription(course.description);
        setSelectedLessons(course.contents);
        !course.description ? setCharactersNumber(0)
          : setCharactersNumber(course.description.length);
      }
    }
  }, [courseId]);

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  const isEditting = useMemo(() => {
    if (courseId) {
      return true;
    }

    return false;
  }, [courseId]);

  const updateDescription = (event: any) => {
    const { target } = event;
    setDescription(target.value);

    if (target.value.length <= target.maxLength) setCharactersNumber(target.value.length);
  };

  return (
    <CreateAndEditCourseContainer>
      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="title"
            required
            maxLength={250}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description || ''}
            onChange={(e) => updateDescription(e)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            {isMusic ? 'Músicas' : 'Episódios'}
          </label>

          <LessonSelectionContainer>
            {selectedLessons && selectedLessons.length ? (
              <SortLessonsContainer>
                {selectedLessons.map((lesson, index) => (
                  <div key={lesson.content_id} className="selected-lessons">
                    <div className="buttons">
                      {index > 0 ? (
                        <DefaultButton
                          type="button"
                          title={`Subir ${isMusic ? "música" : "episódio"}`}
                          className="small white up"
                          onClick={() => reorderSelectedLessons(lesson, true)}
                        >
                          <AiOutlineUp />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                      {index < selectedLessons.length - 1 ? (
                        <DefaultButton
                          type="button"
                          title={`Descer ${isMusic ? "música" : "episódio"}`}
                          className="small white down"
                          onClick={() => reorderSelectedLessons(lesson, false)}
                        >
                          <AiOutlineDown />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="lesson-title">
                      {index + 1} - {lesson.title}
                    </div>
                    <div className="buttons">
                      <DefaultButton
                        className="small"
                        type="button"
                        onClick={() => editEpisode(lesson.content_id!)}
                        title={`Editar ${isMusic ? "música" : "episódio"}`}
                      >
                        <AiOutlineEdit size={16} />
                      </DefaultButton>
                      <DetachButton onClick={() => removeSelectedLesson(lesson)} />
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : (
              <></>
            )}
            <DefaultButton type="button" onClick={addEpisode}>
              {isMusic ? "Adicionar Música" : "Adicionar Episódio"}
            </DefaultButton>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={() =>
              Swal.fire({
                icon: 'question',
                text: 'Tem certeza que deseja cancelar? Todas as alterações não salvas serão perdidas?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
              }).then((result) => {
                if (result.isConfirmed) {
                  hideModal(modalId);
                }
              })
            }
          >
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditting ? updateCourse(e) : createCourse(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCourseContainer>
  );
};

export default CreateAndEditCourse;

import styled from "styled-components";

const DefaultFilterForm = styled.form`
  width: 100%;

  & > * {
    min-width: 49%;
    margin-bottom: 15px;
  }

  & > *.entire-row {
    min-width: 100%;
  }

  & > *:nth-child(even) {
    margin-right: 10px;
  }

  input[type=date] {
    height: 40px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px var(--default-dark-gray);
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export default DefaultFilterForm;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AiOutlineDown, AiOutlineEdit, AiOutlineUp } from 'react-icons/ai';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import CutImage from '../../components/CutImage';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { ReactComponent as DetachButton } from '../../assets/detach.svg';
import { getAllProducers as getProducersService } from '../../services/producer';

import { hideModal, showModal } from '../../helpers/modal';
import checkEmptyString from '../../helpers/check-empty-string';

import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

import {
  getCourse as getCourseService,
  updateCourse as updateCourseService,
  createCourse as createCourseService,
  changeCourseThumbnail as changeCourseThumbnailService,
  deleteCourse,
  changeTrailBanner,
} from '../../services/trails';
import Category from '../../models/category';
import Author from '../../models/author';
import { getAllAuthors } from '../../services/authors';
import Course from '../../models/course';
import { getAllCategories } from '../../services/categories';
import {
  ThumbnailUploadContainer,
  CourseThumbnail,
  SortLessonsContainer,
  LessonSelectionContainer,
  CreateAndEditSerieContainer,
  Switch,
} from './style';
import { uploadFile } from '../../services/files';
import CreateAndEditCourse from '../CreateAndEditSeason';
import Producer from '../../models/producer';
import { updateContent as updateContentService, getContent as getContentService } from '../../services/contents';

interface CreateAndEditSerieParams {
  serieId: string;
}

interface CreateAndEditSerieProps {
  isKids?: boolean;
  isMusics?: boolean;
}

const CreateAndEditSerie: React.FC<CreateAndEditSerieProps> = ({ isKids, isMusics }) => {
  const history = useHistory();
  const { serieId } = useParams<CreateAndEditSerieParams>();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCourses, setSelectedCourses] = useState([] as Course[]);
  const [seasonsToRemove, setSeasonsToRemove] = useState([] as Course[]);

  const [thumbnailWebFileToUpload, setThumbnailWebFileToUpload] = useState<File>();
  const [thumbnailWeb, setThumbnailWeb] = useState('');

  const [thumbnailMobileFileToUpload, setThumbnailMobileFileToUpload] = useState<File>();
  const [thumbnailMobile, setThumbnailMobile] = useState('');

  const [categories, setCategories] = useState([] as Category[]);
  const [selectedCategory, setSelectedCategory] = useState([] as unknown as { label: string; value: string }[]);

  const [authors, setAuthors] = useState([] as Author[]);
  const [selectedAuthors, setSelectedAuthors] = useState([] as unknown as { label: string; value: string }[]);

  const [selectedTag, setSelectedTag] = useState({} as unknown as { label: string; value: string });

  const [bannerFileToUpload, setBannerFileToUpload] = useState<File>();
  const [banner, setBanner] = useState('');

  const [isHighlighted, setIsHighlighted] = useState(false);
  const [originalTitle, setOriginalTitle] = useState('');
  const [cast, setCast] = useState('');
  const [language, setLanguage] = useState('');
  const [director, setDirector] = useState('');
  const [parentalRating, setParentalRating] = useState('');
  const [year, setYear] = useState(0);
  const [serieType, setSerieType] = useState(isMusics ? 'Álbum musical' : 'Série');
  const [premiumContent, setPremiumContent] = useState(true);
  const [charactersNumber, setCharactersNumber] = useState(0);

  const [addingOrEditingSeasonModalId, setAddingOrEditingSeasonModalId] = useState('');

  const [producers, setProducers] = useState([] as { label: string; value: string }[]);
  const [producer, setProducer] = useState({} as { label: string; value: string });

  const getProducers = useCallback(async () => {
    const producers = (await getProducersService()) || [];
    const configuredProducers = producers.map((p: Producer) => ({ label: p.name, value: p.producer_id }));
    setProducers(configuredProducers);
  }, []);

  const addSeason = () => {
    if (addingOrEditingSeasonModalId) {
      hideModal(addingOrEditingSeasonModalId);
    }

    const localAddingSeasonModalId = showModal(
      'Adicionar Temporada',
      <CreateAndEditCourse onCreateOrUpdate={includeCreatedSeason} isMusic={isMusics} />
    );
    setAddingOrEditingSeasonModalId(localAddingSeasonModalId);
  };

  const includeCreatedSeason = (season: Course) => {
    const localSelectedCourses = selectedCourses || [];
    if (!localSelectedCourses.some((course) => course.course_id === season.course_id)) {
      setSelectedCourses([...localSelectedCourses, season]);
    } else {
      const foundCourse = localSelectedCourses.find((course) => course.course_id === season.course_id);
      if (foundCourse) {
        const indexOfCourse = localSelectedCourses.indexOf(foundCourse);
        if (indexOfCourse > -1) {
          localSelectedCourses.splice(indexOfCourse, 1, season);
          setSelectedCourses([...localSelectedCourses]);
        }
      }
    }
  };

  const editSeason = (seasonId: string) => {
    const localEditingSeasonModalId = showModal(
      'Editar Temporada',
      <CreateAndEditCourse courseId={seasonId} onCreateOrUpdate={includeCreatedSeason} isMusic={isMusics} />
    );
    setAddingOrEditingSeasonModalId(localEditingSeasonModalId);
  };

  const adjetivo = () => (serieType === 'Série' || serieType === 'Palestra' ? 'a' : '');

  const createCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error(`Informe um nome válido para ${adjetivo()} ${serieType}`);
      }

      if (!selectedCourses || !selectedCourses.length) {
        throw new Error('Selecione uma ou mais temporadas antes.');
      }

      if (isHighlighted && !banner) {
        throw new Error(`Informe uma imagem de banner destaque para ${adjetivo()} ${serieType}.`);
      }

      if (!thumbnailMobile && !thumbnailMobileFileToUpload) {
        throw new Error(`Informe uma imagem de capa mobile para ${adjetivo()} ${serieType}.`);
      }

      if (!thumbnailWeb && !thumbnailWebFileToUpload) {
        throw new Error(`Informe uma imagem de capa web para ${adjetivo()} ${serieType}.`);
      }

      if (!isMusics && !selectedTag.value) {
        throw new Error('Informe o tipo de conteúdo a ser criado.');
      }

      const data: any = {
        name: name,
        description: description.trim().length ? description : undefined,
        courses: selectedCourses.map((course, index) => ({
          course_id: course.course_id,
          position: index + 1,
        })),
        authors: selectedAuthors && selectedAuthors.map((author) => ({ author_id: author.value })),
        categories: selectedCategory && selectedCategory.map((category) => ({ category_id: category.value })),
        info: { premium: premiumContent },
        tag: isMusics ? 'musicas' : 'series',
        segmentation_items: [
          {
            segmentation_item_id: `${isKids ? process.env.REACT_APP_SEGMENTATION_ITEM_KIDS : process.env.REACT_APP_SEGMENTATION_ITEM_PADRAO
              }`,
          },
        ],
      };

      if (isMusics) {
        delete data.authors;
      }
      if (isHighlighted) data.info = { ...data.info, is_highlighted: isHighlighted };
      if (originalTitle && originalTitle.length) data.info = { ...data.info, original_title: originalTitle };
      if (cast && cast.length) data.info = { ...data.info, cast };
      if (language && language.length) data.info = { ...data.info, language };
      if (director && director.length) data.info = { ...data.info, director };
      if (parentalRating) data.info = { ...data.info, parental_rating: parentalRating };
      if (year && year > 0 && Number(year)) data.info = { ...data.info, year };
      if (selectedTag.value && selectedTag.value.length) {
        data.tag = selectedTag.value;
      }

      const createdCourse = await createCourseService(data);

      if (thumbnailWebFileToUpload) {
        try {
          await changeCourseThumbnail(thumbnailWebFileToUpload, createdCourse.trail_id);
        } catch (error) {
          await deleteCourse(createdCourse.trail_id);

          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      if (thumbnailMobileFileToUpload) {
        try {
          await changeCourseThumbnailMobile(thumbnailMobileFileToUpload, createdCourse.trail_id);
        } catch (error) {
          await deleteCourse(createdCourse.trail_id);

          throw new Error(
            'Erro ao fazer upload da imagem de capa mobile. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      if (bannerFileToUpload) {
        try {
          await changeSerieBanner(bannerFileToUpload, createdCourse.trail_id);
        } catch (error) {
          await deleteCourse(createdCourse.trail_id);

          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      if (seasonsToRemove && seasonsToRemove.length) {
        for (let season of seasonsToRemove) {
          try {
            await deleteCourse(season.course_id!);
          } catch (error) { }
        }
      }

      if (producer?.value) {
        const actualTrail = await getCourseService(createdCourse.trail_id);

        const actualTrailCourses = actualTrail.courses;

        for (const course of actualTrailCourses) {
          const actualCourseContents = (course as Course).contents || [];
          for (const content of actualCourseContents) {
            const fullContent = await getContentService(content.content_id);
            await updateContentService(fullContent.content_id, {
              producer_id: producer.value,
              info: fullContent.info,
              reference: fullContent.reference,
              title: fullContent.title,
              type: fullContent.type,
              thumbnail: fullContent.thumbnail,
            });
          }
        }
      }

      Swal.fire({
        title: 'Sucesso!',
        text: `criado com sucesso!`,
        icon: 'success',
      });

      goToCourses();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar ${adjetivo()} ${serieType}. ${error.message}`,
        icon: 'error',
      });
    }
  };

  const updateCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error(`Informe um nome válido para ${adjetivo()} ${serieType}`);
      }

      if (!isMusics && (!selectedCourses || !selectedCourses.length)) {
        throw new Error('Selecione uma ou mais temporadas antes.');
      }

      if (!banner && isHighlighted) {
        throw new Error(`Informe uma imagem de banner destaque para ${adjetivo()} ${serieType}.`);
      }

      if (!selectedTag.value) {
        throw new Error('Informe o tipo de conteúdo a ser criado.');
      }

      let localBanner = banner;

      if (bannerFileToUpload) {
        const formData = new FormData();
        formData.append('file', bannerFileToUpload);
        const { reference } = await uploadFile(formData);
        localBanner = reference;
      }

      const data: any = {
        name: name,
        description: description || undefined,
        courses: selectedCourses.map((course, index) => ({
          course_id: course.course_id,
          position: index + 1,
        })),
        authors: selectedAuthors && selectedAuthors.map((author) => ({ author_id: author.value })),
        categories: selectedCategory && selectedCategory.map((category) => ({ category_id: category.value })),
        info: { premium: premiumContent || false, banner_url: localBanner },
        tag: isMusics ? 'musicas' : 'series',
        segmentation_items: [
          {
            segmentation_item_id: `${isKids ? process.env.REACT_APP_SEGMENTATION_ITEM_KIDS : process.env.REACT_APP_SEGMENTATION_ITEM_PADRAO
              }`,
          },
        ],
      };

      if (isMusics) {
        delete data.authors;
      }
      if (localBanner && localBanner.length) data.info = { ...data.info, banner_url: localBanner };
      if (isHighlighted) data.info = { ...data.info, is_highlighted: isHighlighted };
      if (originalTitle && originalTitle.length) data.info = { ...data.info, original_title: originalTitle };
      if (cast && cast.length) data.info = { ...data.info, cast };
      if (language && language.length) data.info = { ...data.info, language };
      if (director && director.length) data.info = { ...data.info, director };
      if (parentalRating) data.info = { ...data.info, parental_rating: parentalRating };
      if (year && year > 0 && Number(year)) data.info = { ...data.info, year };
      if (selectedTag.value && selectedTag.value.length) {
        data.tag = selectedTag.value;
      }

      await updateCourseService(serieId, data);

      if (thumbnailWebFileToUpload) {
        try {
          await changeCourseThumbnail(thumbnailWebFileToUpload, serieId);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      if (thumbnailMobileFileToUpload) {
        try {
          await changeCourseThumbnailMobile(thumbnailMobileFileToUpload, serieId);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa mobile. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      } else if (!thumbnailMobileFileToUpload)
        if (bannerFileToUpload) {
          try {
            await changeSerieBanner(bannerFileToUpload, serieId);
          } catch (error) {
            throw new Error(
              'Erro ao fazer upload da imagem do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
            );
          }
        }

      if (seasonsToRemove && seasonsToRemove.length) {
        for (let season of seasonsToRemove) {
          try {
            await deleteCourse(season.course_id!);
          } catch (error) { }
        }
      }

      if (producer?.value) {
        const actualTrail = await getCourseService(serieId);

        const actualTrailCourses = actualTrail.courses;

        for (const course of actualTrailCourses) {
          const actualCourseContents = (course as Course).contents || [];
          for (const content of actualCourseContents) {
            const fullContent = await getContentService(content.content_id);
            await updateContentService(fullContent.content_id, {
              producer_id: producer.value,
              info: fullContent.info,
              reference: fullContent.reference,
              title: fullContent.title,
              type: fullContent.type,
              thumbnail: fullContent.thumbnail,
            });
          }
        }
      }

      Swal.fire({
        title: 'Sucesso!',
        text: `${serieType} editad${adjetivo()} com sucesso!`,
        icon: 'success',
      });
      goToCourses();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar a série. ' + error.message,
        icon: 'error',
      });
    }
  };

  const changeCourseThumbnail = async (localThumbnailFileToUpload: File, localSerieId: string) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append('name', `course_thumbnail_${localSerieId}.${localThumbnailFileToUpload.type}`);
    formData.append('description', `thumbnail da série de id ${localSerieId}`);

    await changeCourseThumbnailService(localSerieId, formData);
  };

  const changeCourseThumbnailMobile = async (localThumbnailFileToUpload: File, localSerieId: string) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append('name', `course_thumbnail_${localSerieId}.${localThumbnailFileToUpload.type}`);
    formData.append('description', `thumbnail da série de id ${localSerieId}`);

    await changeCourseThumbnailService(localSerieId, formData, true);
  };

  const changeSerieBanner = async (localBannerFileToUpload: File, localSerieId: string) => {
    const formData = new FormData();
    formData.append('file', localBannerFileToUpload);
    formData.append('name', `content_thumbnail_${localSerieId}.${localBannerFileToUpload.type}`);
    formData.append('description', `thumbnail do conteúdo de id ${localSerieId}`);

    await changeTrailBanner(localSerieId, formData);
  };

  const selectThumbnailWeb = () => {
    showModal('Selecionar Imagem de Capa web', <CutImage aspect={1090 / 601} onCutImage={onCutImage} />);
  };

  const selectThumbnailMobile = () => {
    showModal('Selecionar Imagem de Capa mobile', <CutImage aspect={0.7} onCutImage={onCutImageMobile} />);
  };

  const selectBanner = () => {
    if (!isKids) {
      showModal('Selecionar Imagem do Banner', <CutImage aspect={1.812} onCutImage={onCutBanner} />);
    } else {
      showModal('Selecionar Imagem do Banner', <CutImage aspect={0.6} onCutImage={onCutBanner} />);
    }
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailWebFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnailWeb(`${reader.result}`);

      hideModal();
    }
  };

  const onCutBanner = (file: File) => {
    if (file) {
      setBannerFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setBanner(`${reader.result}`);

      hideModal();
    }
  };

  const onCutImageMobile = (file: File) => {
    if (file) {
      setThumbnailMobileFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnailMobile(`${reader.result}`);

      hideModal();
    }
  };

  const reorderSelectedCourses = (lesson: Course, up: boolean) => {
    if (selectedCourses) {
      const localSelectedCourses = [...selectedCourses];
      const indexOfLesson = localSelectedCourses.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedCourses.length) {
        localSelectedCourses.splice(indexOfLesson, 1);
        if (up) {
          localSelectedCourses.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedCourses.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedCourses(localSelectedCourses);
    }
  };

  const removeSelectedCourse = async (courseToBeRemoved: any) => {
    Swal.fire({
      icon: 'question',
      text: `Tem certeza que deseja deletar essa temporada d${adjetivo()} ${serieType}?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const courses = [...selectedCourses];
        const filteredList = courses.filter((course) => course.course_id !== courseToBeRemoved.course_id);
        const reorderedList = filteredList.map((course, index) => ({
          ...course,
          position: index + 1,
        }));
        setSelectedCourses(reorderedList);

        setSeasonsToRemove([...seasonsToRemove, courseToBeRemoved]);
      }
    });
  };

  const getCourse = useCallback(async () => {
    const foundAuthors = await getAllAuthors();

    const foundCategories = await getAllCategories({ is_active: true });
    if (foundAuthors) {
      setAuthors(foundAuthors);
    }

    if (foundCategories) {
      setCategories(foundCategories);
    }
    if (serieId) {
      const course = await getCourseService(serieId);

      if (course && Object.keys(course).length) {
        setName(course.name);
        setDescription(course.description);
        setSelectedCourses(course.courses);
        setThumbnailWeb(course.thumbnail || '');
        setThumbnailMobile(course.thumbnail_mobile);
        setBanner(course.info.banner_url || '');
        setIsHighlighted(course.info?.is_highlighted || false);
        setOriginalTitle(course.info?.original_title || '');
        setCast(course.info?.cast || '');
        setLanguage(course.info?.language || '');
        setDirector(course.info?.director || '');
        setParentalRating(course.info?.parental_rating || '');
        setYear(course.info?.year || 0);
        if (course.categories && course.categories.length) {
          const selectedCategories = course.categories.map((category: any) => ({
            label: category.title,
            value: `${category.id}`,
          }));
          setSelectedCategory(selectedCategories);
        }
        if (course.authors && course.authors.length) {
          const selectedAuthors = course.authors.map((author: any) => ({
            label: author.name,
            value: `${author.id}`,
          }));
          setSelectedAuthors(selectedAuthors);
        }

        if (course.tag && course.tag.length) {
          const tagEnum: any = {
            series: 'Série',
            cursos: 'Curso',
            palestras: 'Palestra',
            sermoes: 'Sermão',
            documentarios: 'Documentário',
            musicas: 'Podcast',
          };
          setSelectedTag({ label: tagEnum[course.tag], value: course.tag });
        }

        setPremiumContent(course.info.premium);

        !course.description ? setCharactersNumber(0) : setCharactersNumber(course.description.length);

        const courseCourses = course.courses || [];
        const firstCourse = (courseCourses[0] as Course) || {};
        const firstCourseContents = firstCourse.contents || [];
        const firstContent = firstCourseContents[0] || {};

        if (firstContent) {
          const fullFirstContent = await getContentService(firstContent.content_id);

          const producers = (await getProducersService()) || [];
          const foundFirstContentProducer = producers.find(
            (p) => p.producer_id === (fullFirstContent.producer || {}).producer_id
          );

          if (foundFirstContentProducer) {
            setProducer({ value: foundFirstContentProducer.producer_id, label: foundFirstContentProducer.name });
          }
        }
      }
    }
  }, [serieId]);

  const authorsToBeSelected = useMemo(() => {
    if (authors && authors.length) {
      return authors.map((author) => ({
        label: author.name,
        value: `${author.author_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [authors]);

  const categoriesToBeSelected = useMemo(() => {
    if (categories && categories.length && !isKids) {
      const categoriesFiltered = categories.filter((category) => category.info?.kids === false || !category.info?.kids);
      return categoriesFiltered.map((category) => ({
        label: category.title,
        value: category.category_id,
      }));
    } else if (categories && categories.length && isKids) {
      const categoriesFilteredKids = categories.filter((category) => category.info?.kids);
      return categoriesFilteredKids.map((category) => ({
        label: category.title,
        value: `${category.category_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [categories, isKids]);

  const contentTypesToBeSelected = useMemo(() => {
    const types = [
      { label: 'Série', value: 'series' },
      { label: 'Documentário', value: 'documentarios' },
      { label: 'Sermão', value: 'sermoes' },
      { label: 'Podcast', value: 'musicas' },
      { label: 'Curso', value: 'cursos' },
      { label: 'Palestra', value: 'palestras' },
    ];

    if (!isKids && !isMusics) delete types[3];

    return types.map((type) => ({
      label: type.label,
      value: `${type.value}`,
    }));
  }, [isKids, isMusics]);

  const contentTypesToBeSelectedKids = useMemo(() => {
    const types = [{ label: 'Série', value: 'series' }];

    if (!isKids && !isMusics) delete types[3];

    return types.map((type) => ({
      label: type.label,
      value: `${type.value}`,
    }));
  }, []);

  const goToCourses = () => {
    if (!isKids && !isMusics) {
      history.push(`/trails/series`);
      return;
    }
    if (isMusics) {
      history.push(`/musics/trails/series`);
      return;
    }
    if (isKids) {
      history.push(`/kids/trails/series`);
    }
  };

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  const isEditting = useMemo(() => {
    if (serieId) {
      return true;
    }

    return false;
  }, [serieId]);

  const changeSelectedTag = (options: any) => {
    options && setSelectedTag({ label: options.label, value: options.value });
    setSerieType(options.label);
  };

  const updateDescription = (event: any) => {
    const { target } = event;
    setDescription(target.value);

    if (target.value.length <= target.maxLength) setCharactersNumber(target.value.length);
  };

  const crumbs = useMemo(() => {
    const localCrumbs = [<Link to="/profile">Perfil</Link>, <span>{isMusics ? 'Álbuns Musicais' : 'Séries'}</span>];
    if (isKids) {
      localCrumbs.splice(1, 0, <span>Kids</span>);
    }

    return localCrumbs;
  }, [isKids]);

  useEffect(() => {
    getProducers();
  }, []);

  return (
    <CreateAndEditSerieContainer>
      <BreadCrumb
        crumbs={[
          ...crumbs,
          <span>
            {isEditting ? 'Editar' : 'Criar'} {serieType}
          </span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} {serieType}
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="title" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="original_title">Título Original</label>
          <DefaultTextArea
            value={originalTitle || ''}
            onChange={(e) => setOriginalTitle(e.target.value)}
            id="original_title"
          />
        </DefaultCreationFormGroup>

        {!isMusics && (
          <DefaultCreationFormGroup>
            <label htmlFor="cast">Elenco</label>
            <DefaultTextArea value={cast || ''} onChange={(e) => setCast(e.target.value)} id="cast" />
          </DefaultCreationFormGroup>
        )}

        <DefaultCreationFormGroup>
          <label htmlFor="language">Idioma</label>
          <DefaultTextArea value={language || ''} onChange={(e) => setLanguage(e.target.value)} id="language" />
        </DefaultCreationFormGroup>

        {!isMusics && (
          <DefaultCreationFormGroup>
            <label htmlFor="director">Direção</label>
            <DefaultTextArea value={director || ''} onChange={(e) => setDirector(e.target.value)} id="director" />
          </DefaultCreationFormGroup>
        )}

        <DefaultCreationFormGroup>
          <label htmlFor="parental_rating">Classificação</label>
          <DefaultTextArea
            value={parentalRating || ''}
            onChange={(e) => setParentalRating(e.target.value)}
            id="parental_rating"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="year">Ano de Lançamento</label>
          <DefaultTextArea value={year || ''} onChange={(e) => setYear(Number(e.target.value))} id="year" />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          {/* {isMusics ? (
            <CreateDescriptionMdContainer>
              <MdEditor
                renderHTML={(text) => mdParser(text)}
                value={description}
                onChange={({ text }) => setDescription(text)}
                htmlClass="mdeditor"
              />
            </CreateDescriptionMdContainer>
          ) : ( */}
          <>
            <DefaultTextArea value={description || ''} onChange={(e) => updateDescription(e)} id="description" />
          </>
          {/* )} */}
        </DefaultCreationFormGroup>

        {isMusics ? (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="tags">
              Tipo de conteúdo
            </label>
            <Select
              styles={{
                container: (provided) => ({
                  ...provided,
                  flexGrow: 1,
                  width: '100%',
                }),
              }}
              options={[{ label: 'Podcast', value: 'musicas' }]}
              value={selectedTag}
              onChange={changeSelectedTag}
              id="tag"
              isMulti={false}
              placeholder="Selecione o tipo do conteúdo a ser criado."
            />
          </DefaultCreationFormGroup>
        ) : null}

        {!isMusics && !isKids && (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="tags">
              Tipo de conteúdo
            </label>
            <Select
              styles={{
                container: (provided) => ({
                  ...provided,
                  flexGrow: 1,
                  width: '100%',
                }),
              }}
              options={contentTypesToBeSelected}
              value={selectedTag}
              onChange={changeSelectedTag}
              id="tag"
              isMulti={false}
              placeholder="Selecione o tipo do conteúdo a ser criado."
            />
          </DefaultCreationFormGroup>
        )}

        {isKids && !isMusics && (
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="tags">
              Tipo de conteúdo
            </label>
            <Select
              styles={{
                container: (provided) => ({
                  ...provided,
                  flexGrow: 1,
                  width: '100%',
                }),
              }}
              options={contentTypesToBeSelectedKids}
              value={selectedTag}
              onChange={(options) => changeSelectedTag(options)}
              id="tag"
              isMulti={false}
              placeholder="Selecione o tipo do conteúdo a ser criado."
            />
          </DefaultCreationFormGroup>
        )}

        <DefaultCreationFormGroup>
          <label htmlFor="producer">Produtor</label>
          <Select
            styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
            options={producers}
            onChange={(opt) => setProducer(opt || { value: '', label: '' })}
            value={producer}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Temporadas
          </label>

          <LessonSelectionContainer>
            {selectedCourses && selectedCourses.length ? (
              <SortLessonsContainer>
                {selectedCourses.map((course, index) => (
                  <div key={course.course_id} className="selected-lessons">
                    <div className="buttons">
                      {index > 0 ? (
                        <DefaultButton
                          type="button"
                          title="Subir Aula"
                          className="small white up"
                          onClick={() => reorderSelectedCourses(course, true)}
                        >
                          <AiOutlineUp />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                      {index < selectedCourses.length - 1 ? (
                        <DefaultButton
                          type="button"
                          title="Descer Aula"
                          className="small white down"
                          onClick={() => reorderSelectedCourses(course, false)}
                        >
                          <AiOutlineDown />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="lesson-title">
                      {index + 1} - {course.title}
                    </div>
                    <div className="buttons">
                      <DefaultButton
                        className="small"
                        type="button"
                        onClick={() => editSeason(course.course_id!)}
                        title="Editar Temporada"
                      >
                        <AiOutlineEdit size={16} />
                      </DefaultButton>
                      <DetachButton title="Deletar" onClick={() => removeSelectedCourse(course)} />
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : (
              <></>
            )}
            <DefaultButton type="button" onClick={addSeason}>
              Adicionar temporadas
            </DefaultButton>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference" className="required">
            Capa web
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnailWeb}>
              Selecionar Imagem de Capa para a web
            </DefaultButton>

            {thumbnailWeb && <CourseThumbnail src={thumbnailWeb} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference" className="required">
            Capa mobile
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnailMobile}>
              Selecionar Imagem de Capa para a mobile
            </DefaultButton>

            {thumbnailMobile && <CourseThumbnail src={thumbnailMobile} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        {!isMusics && serieType !== 'Música' && (
          <>
            <DefaultCreationFormGroup>
              <label htmlFor="tags">Palestrantes</label>
              <Select
                styles={{
                  container: (provided) => ({
                    ...provided,
                    flexGrow: 1,
                    width: '100%',
                  }),
                }}
                options={authorsToBeSelected}
                value={selectedAuthors}
                onChange={(options) => {
                  options &&
                    !window.isNaN(options.length) &&
                    setSelectedAuthors(options.map((opt) => ({ label: opt.label, value: opt.value })));
                }}
                id="authors"
                isMulti
                placeholder={`Selecione um ou mais palestrantes para est${adjetivo()} ${serieType}`}
              />
            </DefaultCreationFormGroup>
          </>
        )}

        <DefaultCreationFormGroup>
          <label htmlFor="tags">Categoria</label>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={categoriesToBeSelected}
            value={selectedCategory}
            onChange={(options) =>
              options &&
              !window.isNaN(options.length) &&
              setSelectedCategory(options.map((opt) => ({ label: opt.label, value: opt.value })))
            }
            id="authors"
            isMulti
            placeholder={`Selecione uma categoria para est${adjetivo()} ${serieType}.`}
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label>Banner Home</label>
          <Switch>
            <input
              type="checkbox"
              checked={isHighlighted}
              onChange={() => {
                setIsHighlighted(!isHighlighted);
                setBannerFileToUpload(undefined);
              }}
            />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        {isHighlighted && (
          <DefaultCreationFormGroup>
            <label htmlFor="reference" className={isHighlighted ? 'required' : ''}>
              Imagem Destaque
            </label>

            <ThumbnailUploadContainer>
              <div>
                <DefaultButton type="button" onClick={selectBanner}>
                  Selecionar imagem
                </DefaultButton>

                <DefaultButton
                  type="button"
                  onClick={() => {
                    setBanner('');
                    setBannerFileToUpload(undefined);
                  }}
                >
                  Excluir imagem
                </DefaultButton>
              </div>
              {banner && <CourseThumbnail src={banner} />}
            </ThumbnailUploadContainer>
          </DefaultCreationFormGroup>
        )}

        <DefaultCreationFormGroup>
          <label>Conteúdo premium</label>
          <Switch>
            <input type="checkbox" checked={premiumContent} onChange={(e) => setPremiumContent(!premiumContent)} />
            <span className="slider round"></span>
          </Switch>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToCourses}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditting ? updateCourse(e) : createCourse(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditSerieContainer>
  );
};

export default CreateAndEditSerie;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import { hideModal, showModal } from '../../helpers/modal';
import CutImage from '../../components/CutImage';
import checkEmptyString from '../../helpers/check-empty-string';
import {
  getLive as getLiveService,
  createLive as createLiveService,
  updateLive as updateLiveService,
  updateImages,
} from '../../services/lives';
import { LiveForCreate } from '../../models/for-create/lives';
import { uploadFile } from '../../services/files';
import Switcher from '../../components/Switcher';

import {
  ContentThumbnail,
  CreateAndEditContentContainer,
  ThumbnailUploadContainer,
  SwitchWrapper,
  SelectPlatform,
  Fade,
} from './style';
import getErrorMessage from '../../helpers/get-error-message';
import { LiveForUpdate } from '../../models/for-update/lives';

interface CreateAndEditLiveProps {
  liveId: string;
}

interface IPlatformOptions {
  label: string;
  value: string;
}

const platformOptions = [
  { label: 'YouTube', value: 'YouTube' },
];

const CreateAndEditLive: React.FC = () => {
  const { liveId } = useParams<CreateAndEditLiveProps>();
  const history = useHistory();

  const [name, setName] = useState('');
  const [description, setDescription] = useState(' ');
  const [startDate, setStartDate] = useState<any>();
  const [finishDate, setFinishDate] = useState<any>();
  const [thumbnail, setThumbnail] = useState<string>();
  const [isSambaVideosLive, setIsSambaVideosLive] = useState(true);
  const [platformSelected, setPlatformSelected] = useState<IPlatformOptions>({ value: '', label: '' });

  const validateLive = () => {
    if (checkEmptyString(name)) {
      throw new Error('Informe um nome válido para a live!');
    }

    if (!thumbnail) {
      throw new Error('Informe uma capa para a live!');
    }

    if(!startDate) {
      throw new Error('Informe uma data de início para a live!');
    }

    if(!finishDate) {
      throw new Error('Informe uma data fim para a live!');
    }

    if (startDate && new Date().getTime() >= startDate.getTime()) {
      throw new Error(
        'Informe uma data de início válida para a live! Certifique-se que a data seja ao menos 5 minutos superior a data atual!'
      );
    }

    if (finishDate && new Date().getTime() >= finishDate.getTime()) {
      throw new Error(
        'Informe uma data final válida para a live! Certifique-se que a data seja ao menos 5 minutos superior a data de início da live!'
      );
    }

    if (finishDate && startDate.getTime() >= finishDate.getTime()) {
      throw new Error('Informe uma data de início inferior a data de fim!');
    }
  };

  function dataURLtoFile(dataurl: string, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)?.[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const createLive = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validateLive();

      const thumbnailUrl = await uploadThumbnail();
      const newLive: LiveForCreate = {
        name,
        description,
        start: startDate,
        stop: finishDate,
      }

      if(thumbnailUrl) {
        newLive.images = {
          thumbnail_Url: thumbnailUrl
        }
      }

      if (!isSambaVideosLive) {
        if (checkEmptyString(platformSelected.value)) {
          throw new Error('Informe uma plataforma válida para a live!');
        }

        newLive.external_live = true;
        newLive.external_live_platform = platformSelected.value;
      }

      await createLiveService(newLive);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo criado com sucesso!',
        icon: 'success',
      });
  
      goToLives();
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao criar o conteúdo. ' + errorMessage,
        icon: 'error',
      });
    }
  };

  const uploadThumbnail = async () => {
    if (thumbnail && thumbnail.length && thumbnail.includes('base64')) {
      try {
        const image = dataURLtoFile(thumbnail, `thumbnail-${name}.png`);

        const formData = new FormData();
        formData.append('file', image);

        const { reference } = await uploadFile(formData);
        return reference;
      } catch (e: any) {
        throw new Error('Erro ao incluir a imagem de capa. ' + e.message);
      }
    }
  };

  const updateLive = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validateLive();

      const newLive: LiveForUpdate = {
        name,
        description,
        start: startDate.toISOString(),
        stop: finishDate.toISOString(),
      };

      if(!isSambaVideosLive) {
        if (checkEmptyString(platformSelected.value)) {
          throw new Error('Informe uma plataforma válida para a live!');
        }

        newLive.external_live_platform = platformSelected.value;
      }

      if (thumbnail && thumbnail.length && thumbnail.includes('base64')) {
        try {
          const image = dataURLtoFile(thumbnail, `thumbnail-${name}.png`);
          changeLiveBanner(liveId, image);
        } catch (e: any) {
          throw new Error('Erro ao alterar a imagem de capa. ' + e.message);
        }
      } else if (!thumbnail) {
        newLive.images = {
          thumbnail_Url: '',
        };
      }

      await updateLiveService(liveId, newLive);

      Swal.fire({
        title: 'Sucesso!',
        text: 'Curso editado com sucesso!',
        icon: 'success',
      });

      goToLives();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o curso. ' + errorMessage,
        icon: 'error',
      });
    }
  };

  const changeLiveBanner = async (liveId: string, localBannerFileToUpload: File) => {
    const formData = new FormData();
    formData.append('file', localBannerFileToUpload);
    await updateImages(liveId, formData);
  };

  const selectThumbnail = () => {
    showModal('Selecionar Imagem de Capa', <CutImage aspect={1.812} onCutImage={onCutImage} />);
  };

  const onCutImage = (file: File) => {
    if (file) {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal(); 
    }
  };

  const goToLives = () => {
    history.push('/lives');
  };

  const getLive = useCallback(async () => {
    try {
      const liveReturned = await getLiveService(liveId);

      setName(liveReturned.name);
      setDescription(liveReturned.description);
      setThumbnail(liveReturned.images.thumbnail_Url);
      setStartDate(new Date(liveReturned.start)) 
      setFinishDate(new Date(liveReturned.stop));

      if(liveReturned.external_live) {
        setIsSambaVideosLive(false);
        setPlatformSelected(platformOptions.find(option => option.value === liveReturned.external_live_platform)!)
      }
    } catch(error: any) {
      const errorMessage = getErrorMessage(error);

      Swal.fire({
        title: 'Erro',
        text: 'Erro ao buscar a live. ' + errorMessage,
        icon: 'error',
      });
    }
    
  }, [liveId]);

  const isEditing = useMemo(() => {
    if (liveId) {
      return true;
    }
    return false;
  }, [liveId]);

  useEffect(() => {
    if (liveId) {
      getLive();
    }
  }, [getLive, liveId]);

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/lives">Lives</Link>,
          <span>{isEditing ? 'Editar' : 'Criar'} Live</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditing ? 'Editar' : 'Criar'} Live {isSambaVideosLive ? 'com Samba Videos' : 'Externa'}
      </DefaultPageTitle>

      <SwitchWrapper>
        <Switcher disabled={isEditing} label="Live com Samba Videos" checked={isSambaVideosLive} onChange={setIsSambaVideosLive} />
      </SwitchWrapper>

      <DefaultCreationForm>
        {!isSambaVideosLive && (
          <Fade>
            <DefaultCreationFormGroup>
              <label className="required" htmlFor="selectPlatform">
                Plataforma
              </label>
              <SelectPlatform
                name="selectPlatform"
                options={platformOptions}
                value={platformSelected}
                onChange={(option: any) => option && setPlatformSelected(option)}
              />
            </DefaultCreationFormGroup>
          </Fade>
        )}

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Nome
          </label>
          <DefaultInput value={name} onChange={(e) => setName(e.target.value)} id="name" required />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description || ' '}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="startDate">Início</label>
          <ReactDatePicker
            id="startDate"
            selected={startDate}
            showTimeSelect
            dateFormat="dd/MM/yyyy hh:mm"
            timeIntervals={5}
            onChange={(date) => setStartDate(date)}
            onChangeRaw={(event) => event.preventDefault()}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="finishDate">Fim</label>
          <ReactDatePicker
            id="finishDate"
            selected={finishDate}
            showTimeSelect
            dateFormat="dd/MM/yyyy hh:mm"
            timeIntervals={5}
            onChange={(date) => setFinishDate(date)}
            onChangeRaw={(event) => event.preventDefault()}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference" className="required">
            Capa
          </label>

          <ThumbnailUploadContainer>
            <div>
              <DefaultButton type="button" onClick={selectThumbnail}>
                Selecionar Imagem de Capa
              </DefaultButton>
              <DefaultButton
                type="button"
                onClick={() => {
                  setThumbnail('');
                }}
              >
                Excluir Imagem de Capa
              </DefaultButton>
            </div>
            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToLives}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditing ? updateLive(e) : createLive(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditLive;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import DefaultButton from '../../components/DefaultButton';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { DefaultTextArea } from '../../components/DefaultTextArea';
import SelectSambaVideosContent from '../../components/SelectSambaVideosContent';
import { hideModal, showModal } from '../../helpers/modal';
import MediaFromResponse from '../../models/from-api-response/media';
import {
  changeLessonThumbnail as changeContentThumbnailService,
  createLesson as createContentService,
  deleteLesson,
  getLesson as getContentService,
  updateLesson as updateContentService,
} from '../../services/lessons';
import { changeContentThumbnailMobile as changeContentThumbnailMobileService } from '../../services/contents';
import { getMedia } from '../../services/medias';
import CutImage from '../../components/CutImage';
import checkEmptyString from '../../helpers/check-empty-string';
import SelectSubtitles from '../../components/SelectSubtitles';
import {
  ContentThumbnail,
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  ThumbnailUploadContainer,
  ButtonGroupInline,
} from './style';
import LessonForCreate from '../../models/for-create/lesson';
import Lesson from '../../models/lesson';
import convertToLesson from '../../helpers/converters/convert-lesson';
import LessonFromReponse from '../../models/from-api-response/lesson';
import { CourseThumbnail } from '../CreateAndEditSerie/style';

interface CreateAndEditContentProps {
  contentId?: string;
  onCreateOrUpdate?: (episode: Lesson) => void;
  modalId?: string;
  isMusic?: boolean
}

const CreateAndEditContent: React.FC<CreateAndEditContentProps> = ({ contentId, onCreateOrUpdate, modalId, isMusic }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reference, setReference] = useState('');
  const [duration, setDuration] = useState(0);
  const [type, setType] = useState('');
  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailMobileFileToUpload, setThumbnailMobileFileToUpload] = useState<File>();
  const [thumbnailMobile, setThumbnailMobile] = useState('');
  const [charactersNumber, setCharactersNumber] = useState(0);
  const [hasSubtitle, setHasSubtitle] = useState(false);

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl);
          setDuration(firstFile.fileInfo.duration / 1000);
          setType(firstFile.qualifier);
        }
      }
      setHasSubtitle((sambaVideosContent.captions || []).length > 0);
    }
  };

  const validate = () => {
    if (checkEmptyString(title)) {
      throw new Error(`Informe um título válido para ${isMusic ? 'a música' : 'o episódio'}.`);
    }

    if (!thumbnail && !thumbnailFileToUpload) {
      throw new Error(`Informe uma imagem de capa para ${isMusic ? 'a música' : 'o episódio'}.`);
    }

    if (!thumbnailMobile && !thumbnailMobileFileToUpload) {
      throw new Error(`Informe uma imagem de capa mobile para ${isMusic ? 'a música' : 'o episódio'}.`);
    }

    if (!reference) {
      throw new Error(`Selecione ${isMusic ? 'uma música' : 'um episódio'} antes.`);
    }
  };

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      const contentCreate: LessonForCreate = {
        title: title,
        description: description.trim().length ? description : undefined,
        duration: duration,
        reference: reference,
        type: type,
        info: {},
      };

      const createdContent = await createContentService(contentCreate);

      if (thumbnailFileToUpload && thumbnailMobileFileToUpload) {
        try {
          await changeContentThumbnail(thumbnailFileToUpload, createdContent.content_id);
        } catch (error) {
          await deleteLesson(createdContent.content_id);

          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      if (thumbnailMobileFileToUpload) {
        try {
          await changeContentThumbnailMobile(thumbnailMobileFileToUpload, createdContent.content_id);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa mobile. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      } else if (!thumbnailMobileFileToUpload)
        if (thumbnailFileToUpload) {
          try {
            await changeContentThumbnail(thumbnailFileToUpload, createdContent.content_id);
          } catch (error) {
            throw new Error(
              'Erro ao fazer upload da imagem do banner. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
            );
          }
        }

      Swal.fire({
        title: 'Sucesso!',
        text: `${isMusic ? 'Música criada' : 'Episódio criado'} com sucesso!`,
        icon: 'success',
      });

      if (onCreateOrUpdate) {
        onCreateOrUpdate(convertToLesson(createdContent as LessonFromReponse));
      }

      hideModal(modalId);
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao criar ${isMusic ? 'a música' : 'o episódio'}. ${error.message}`,
        icon: 'error',
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      const updatedContent = await updateContentService(contentId!, {
        title: title,
        description: description || undefined,
        duration: duration,
        reference: reference,
        type: type,
        info: {},
      });

      if (thumbnailFileToUpload) {
        try {
          await changeContentThumbnail(thumbnailFileToUpload, contentId!);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }
      if (thumbnailMobileFileToUpload) {
        try {
          await changeContentThumbnailMobile(thumbnailMobileFileToUpload, contentId!);
        } catch (error) {
          throw new Error(
            'Erro ao fazer upload da imagem de capa. Certifique-se de que a imagem selecionada não ultrapasse os 5MB.'
          );
        }
      }

      Swal.fire({
        title: 'Sucesso!',
        text: `${isMusic ? 'Música editada' : 'Episódio editado'} com sucesso!`,
        icon: 'success',
      });

      if (onCreateOrUpdate) {
        onCreateOrUpdate(convertToLesson(updatedContent as LessonFromReponse));
      }

      hideModal(modalId);
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao editar ${isMusic ? 'a música' : 'o episódio'}. ${error.message}`,
        icon: 'error',
      });
    }
  };

  const changeContentThumbnail = async (localThumbnailFileToUpload: File, localContentId: string) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append('name', `content_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`);
    formData.append('description', `thumbnail do episódio de id ${localContentId}`);

    await changeContentThumbnailService(localContentId, formData);
  };

  const changeContentThumbnailMobile = async (localThumbnailFileToUpload: File, localContentId: string) => {
    const formData = new FormData();
    formData.append('file', localThumbnailFileToUpload);
    formData.append('name', `course_thumbnail_${localContentId}.${localThumbnailFileToUpload.type}`);
    formData.append('description', `thumbnail da série de id ${localContentId}`);

    await changeContentThumbnailMobileService(localContentId, formData, true);
  };

  const selectSubtitles = () => {
    showModal('Selecionar Legenda', <SelectSubtitles reference={reference} />);
  };

  const selectContent = () => {
    showModal('Selecionar Conteúdo', <SelectSambaVideosContent onSelectContent={onSelectContent} />);
  };

  const selectThumbnail = () => {
    showModal('Selecionar Imagem de Capa', <CutImage aspect={1090 / 601} onCutImage={onCutImage} />);
  };

  const selectThumbnailMobile = () => {
    showModal('Selecionar Imagem de Capa mobile', <CutImage aspect={0.7} onCutImage={onCutImageMobile} />);
  };

  const onCutImage = (file: File) => {
    if (file) {
      setThumbnailFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);
    }
  };

  const onCutImageMobile = (file: File) => {
    if (file) {
      setThumbnailMobileFileToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnailMobile(`${reader.result}`);
    }
  };

  const getContent = useCallback(async () => {
    if (contentId) {
      const content = await getContentService(contentId);
      if (content && Object.keys(content).length) {
        const currentMedia = await getMedia(content.reference.split('/')[content.reference.split('/').length - 1]);
        setTitle(content.title);
        setDescription(content.description);
        setReference(content.reference);
        setHasSubtitle((currentMedia.captions || []).length > 0);
        setDuration(content.duration);
        setThumbnail(content.thumbnail);
        setThumbnailMobile(content.thumbnail_mobile);
        setType(content.type);
        !content.description ? setCharactersNumber(0) : setCharactersNumber(content.description.length);
      }
    }
  }, [contentId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (contentId) {
      return true;
    }

    return false;
  }, [contentId]);

  const updateDescription = (event: any) => {
    const { target } = event;
    setDescription(target.value);

    if (target.value.length <= target.maxLength) setCharactersNumber(target.value.length);
  };

  return (
    <CreateAndEditContentContainer>
      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput value={title} onChange={(e) => setTitle(e.target.value)} id="title" required maxLength={250} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea value={description || ''} onChange={(e) => updateDescription(e)} id="description" />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference" className="required">
            Capa
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnail}>
              Selecionar Imagem de Capa
            </DefaultButton>

            {thumbnail && <ContentThumbnail src={thumbnail} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="reference" className="required">
            Capa mobile
          </label>

          <ThumbnailUploadContainer>
            <DefaultButton type="button" onClick={selectThumbnailMobile}>
              Selecionar Imagem de Capa para a mobile
            </DefaultButton>

            {thumbnailMobile && <CourseThumbnail src={thumbnailMobile} />}
          </ThumbnailUploadContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            {isMusic ? "Música" : "Episódio"}
          </label>

          <ContentUploadOrSelectContainer>
            <ButtonGroupInline>
              <DefaultButton type="button" onClick={selectContent}>
                {isMusic ? "Selecionar Música" : "Selecionar Episódio"}
              </DefaultButton>
              {reference && !hasSubtitle && (
                <DefaultButton type="button" onClick={selectSubtitles}>
                  Selecionar Legenda
                </DefaultButton>
              )}
            </ButtonGroupInline>
            {reference ? (
              <iframe title="referenced-video" allowFullScreen src={reference} frameBorder={0}></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={() =>
              Swal.fire({
                icon: 'question',
                text: 'Tem certeza que deseja cancelar? Todas as alterações não salvas serão perdidas?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
              }).then((result) => {
                if (result.isConfirmed) {
                  hideModal(modalId);
                }
              })
            }
          >
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={(e) => (isEditting ? updateContent(e) : createContent(e))} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditContent;


import { useCallback, useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import Swal from "sweetalert2";

import { 
  DefaultCreationFormButtonGroup, 
  DefaultCreationFormGroup 
} from "../DefaultCreationForm";
import DefaultButton from "../DefaultButton";
import DefaultInput from "../DefaultInput";

import getErrorMessage from "../../helpers/get-error-message";
import checkEmptyString from "../../helpers/check-empty-string";
import { hideModal } from "../../helpers/modal";

import LiveFromResponse from '../../models/from-api-response/lives';
import { LiveForUpdate } from "../../models/for-update/lives";

import { 
  changeLiveStatus, 
  getLive, 
  getLivesSv, 
  updateLive 
} from "../../services/lives";

import * as S from './styles';

interface Option {
  label: string;
  value: string;
}

interface IChannel {
  id: string;
  name: string;
  transmissions: ITransmission[];
}

type ITransmission = Omit<IChannel, 'transmissions'>;

interface StartLiveFormProps {
  liveId: string;
  getLives: () => void
}

export function StartLiveForm({ liveId, getLives }: StartLiveFormProps) {
  const [live, setLive] = useState<LiveFromResponse>();

  const [liveUrl, setLiveUrl]= useState('');
  const [channelOptions, setChannelOptions] = useState<Option[]>([]);
  const [liveChannelOptions, setLiveChannelOptions] = useState<Option[]>([]);
  const [channels, setChannels] = useState<IChannel[]>([]);

  const [channelSelected, setChannelSelected] = useState<Option>();
  const [liveChannelSelected, setLiveChannelSelected] = useState<Option>();

  function validation(type: 'externalLive' | 'sambaVideosLive') {
    if(type === 'externalLive') {
      if(checkEmptyString(liveUrl)) {
        Swal.fire({
          title: 'Erro',
          text: 'Informe uma url para iniciar a live!',
          icon: 'error',
        });
      }
    } 
    
    if(type === 'sambaVideosLive') {
      if(channelSelected?.value && checkEmptyString(channelSelected.value)) {
        Swal.fire({
          title: 'Erro',
          text: 'Informe um canal para iniciar a live!',
          icon: 'error',
        });
      }

      if(liveChannelSelected?.value && checkEmptyString(liveChannelSelected.value)) {
        Swal.fire({
          title: 'Erro',
          text: 'Informe uma live neste canal para iniciar a live!',
          icon: 'error',
        });
      }
    }
  }

  async function handleUpdatedLiveForStart() {
    if(live) {
      let updatedLive: LiveForUpdate = {};
    
      if(live.external_live) {
        validation('externalLive');

        updatedLive = {
          external_live_url: liveUrl
        }
      }

      if(!live.external_live) {
        validation('sambaVideosLive')

        updatedLive = {
          channel_sv_id: channelSelected?.value,
          live_sv_id: liveChannelSelected?.value
        }
      }

      try {
        await updateLive(liveId, updatedLive)
        await changeLiveStatus(liveId, 'ACTIVE')

        Swal.fire({
          title: 'Sucesso!',
          text: 'Live iniciada com sucesso!',
          icon: 'success',
        });

        getLives();
        hideModal();
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);

        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao atualizar a live. ' + errorMessage,
          icon: 'error',
        });
      }
    }
  }

  const handleAlreadySelectedChannel = useCallback(() => {
    if(live && live.channel_sv_id && live.live_sv_id && channels) {
      const channelAlreadySelect = channels.find(option => 
        option.id === live.channel_sv_id
      );
      if(channelAlreadySelect) {
        setChannelSelected({ 
          label: channelAlreadySelect.name,
          value: channelAlreadySelect.id
        });

        
        const liveChannelAlreadySelect = channelAlreadySelect.transmissions.find(
          transmission => transmission.id === live.live_sv_id
        )

        if(liveChannelAlreadySelect) {
          setLiveChannelSelected({ 
            label: liveChannelAlreadySelect.name,
            value: liveChannelAlreadySelect.id
          })
        }
      }
    }
  },[live, channels])

  
  const fetchLivesSv = useCallback(async () => {
    if(live && !live?.external_live) {
      try{
        const channelsSv = await getLivesSv();
  
        setChannelOptions(
          channelsSv.data.map((channel: IChannel) => ({ 
            label: channel.name, 
            value: channel.id 
          }))
        );
        setChannels(channelsSv.data);
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);
  
        Swal.fire({
          title: 'Erro',
          text: 'Houve um erro ao buscar a live no samba vídeos. ' + errorMessage,
          icon: 'error',
        });
      }
    }
  }, [live]);
  
  useEffect(() => {
    fetchLivesSv()
  }, [fetchLivesSv]);

  useEffect(() => {
    const selectedChannelObject = channels.find(
      (channel) => channel.id === channelSelected?.value
    );

    setLiveChannelOptions(
      selectedChannelObject?.transmissions.map((transmission) => ({
        label: transmission.name,
        value: transmission.id,
      })) || []
    );
  }, [channelSelected, channels]);

  const fetchLivesApi = useCallback(async () => {
    try {
      const liveReturned = await getLive(liveId);
      setLive(liveReturned);

      if(liveReturned.external_live) {
        setLiveUrl(liveReturned.external_live_url)
      }
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao buscar a live. ' + errorMessage,
        icon: 'error',
      });
    }
  },[liveId])

  useEffect(() => {
    fetchLivesApi();
  }, [fetchLivesApi]);

  useEffect(() => {
    handleAlreadySelectedChannel()
  },[handleAlreadySelectedChannel])

  const selectStyles: StylesConfig<any, false> = {
    container: (style) => ({
      ...style,
      width: '100%'
    })
  }

  return (
    <S.StartLiveFormContainer>
      <p>Prencha as informações abaixo para iniciar a live</p>
      {live && live.external_live ? (
        <>
          <DefaultCreationFormGroup style={{ marginBottom: '10px' }}>
              <label className="required" htmlFor="liveUrl">
               Embed da live
              </label>
              <DefaultInput 
                id="liveUrl" 
                value={liveUrl} 
                onChange={(e) => setLiveUrl(e.target.value)} 
                required 
              />
            </DefaultCreationFormGroup>
        </>
      ) : (
        <>
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="name">
              Canal do Samba vídeos
            </label>
            <Select
              options={channelOptions}
              placeholder="Selecione um canal do Samba vídeos"
              noOptionsMessage={() => 'Nenhum canal'}
              value={channelSelected}
              onChange={(option) =>
                setChannelSelected(option)
              }
              styles={selectStyles}
            />
          </DefaultCreationFormGroup>

          <DefaultCreationFormGroup>
            <label className="required" htmlFor="name">
              Live neste canal do Samba vídeos
            </label>
            <Select
              options={liveChannelOptions}
              placeholder="Selecione uma live deste canal"
              noOptionsMessage={() => 'Nenhuma live'}
              value={liveChannelSelected}
              onChange={(option) =>
                setLiveChannelSelected(option)
              }
              styles={selectStyles}
            />
          </DefaultCreationFormGroup>
        </>
      )}
      
      <DefaultCreationFormButtonGroup>
        <DefaultButton 
          type="button" 
          className="danger" 
          onClick={() => hideModal()}
        >
          Cancelar
        </DefaultButton>
        <DefaultButton 
          onClick={() => handleUpdatedLiveForStart()} 
          className="success"
        >
          Salvar
        </DefaultButton>
      </DefaultCreationFormButtonGroup>
    </S.StartLiveFormContainer>
  )
}
import styled from 'styled-components';

const ProfileContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin: 35px;
`;

const DashboardCard = styled.div`
  background: var(--primary-color);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin: 20px;
  padding: 16px 24px;
  height: 7rem;
  width: 25rem;

  @media (max-width: 768px) {
    height: 9rem;
    width: 25rem;
  } 
`;

const IconContainer = styled.span`
  background: #FB4F14;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  h5 {
    color: #ffffff;
    text-transform: uppercase;
  }

  span {
    color: white;
    padding-top: 0.4rem;
    padding-left: 0.4rem;
    font-size: x-large;
    font-weight: bold;
  }
`;

export {
  ProfileContainer,
  DashboardContainer,
  DashboardCard,
  IconContainer,
  TextContainer
}
import httpClient from '../http-client';
import ISegmentation, { TrailFromResponse } from '../models/from-api-response/trail';
import { uploadFile } from './files';

const getCourse = async (courseId: string): Promise<TrailFromResponse> => {
  const course = (await httpClient.get<TrailFromResponse>(`trails/${courseId}`)).data;

  if (course && course.courses) {
    course.courses = course.courses.sort((a, b) => (a.position > b.position ? 1 : -1));
  }

  return course;
};

interface getAllCoursesParams {
  is_kids?: boolean;
}

const getAllCourses = async (params?: getAllCoursesParams) => {
  console.log({ params })
  const courses = (
    await httpClient.get<any>('trails', {
      params: {
        ...params,
        orderByName: true,
      },
    })
  ).data;

  return courses;
};
const getAlbums = async (isKids = false) => {
  const courses = (await httpClient.get<any>('trails/albums')).data;

  return courses;
};

const deleteCourse = async (courseId: string) => {
  await httpClient.delete<any>(`trails/${courseId}`);
};

const createCourse = async (newCourse: any) => {
  const createCourseResponse = (await httpClient.post<any>(`trails`, newCourse)).data;

  return createCourseResponse;
};

const updateCourse = async (courseId: string, newCourse: any) => {
  await httpClient.put<any>(`trails/${courseId}`, newCourse);
};

const changeCourseThumbnail = async (courseId: string, formData?: FormData, is_mobile?: boolean) => {
  let reference = undefined;
  if (formData) {
    const response = await uploadFile(formData);
    reference = response.reference;
  }
  const data = is_mobile ? { thumbnail_mobile: reference } : { thumbnail: reference };

  await httpClient.patch(`trails/${courseId}/thumbnail`, data);
};

const changeTrailBanner = async (trailId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`trails/${trailId}/banner`, {
    banner_url: uploadedFile.reference,
  });
};

const activateOrInactivateCourse = async (courseId: string, activate: boolean) => {
  await httpClient.put<any>(`trails/${courseId}/active`, { is_active: activate });
};

const getCoursesForSelect = async () => {
  const courses = (await httpClient.get<any>('trails/')).data;

  if (courses && courses.length) {
    return courses.map((course: any) => ({
      value: course.trail_id,
      label: course.description,
    }));
  }

  return [] as { value: string; label: string }[];
};

const getSegmentations = async (): Promise<ISegmentation[]> => {
  const segmentations = (await httpClient.get(`segmentation-items/type`)).data;

  return segmentations;
};

export {
  getAllCourses,
  getAlbums,
  getSegmentations,
  deleteCourse,
  updateCourse,
  createCourse,
  getCourse,
  changeCourseThumbnail,
  changeTrailBanner,
  activateOrInactivateCourse,
  getCoursesForSelect,
};

import styled from 'styled-components';

const DefaultTableContainer = styled.div``;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Thead = styled.thead`
  background-color: #f6f9fc;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  color: var(--default-gray);
  td:first-of-type {
    color: var(--primary-color);
    font-weight: 700;
  }
`;

const Th = styled.th`
  min-width: 100px;
  padding: 10px;
  font-size: 10px;
  color: var(--default-gray);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.9px;

  .styledIcons {
    display: flex;
    align-items: center;
  }

  &:last-of-type,
  &:nth-last-child(-n + 2) {
    text-align: center;
  }

  svg {
    margin-left: 5px;
  }
`;

const Td = styled.td`
  padding: 16px;
  font-size: var(--font-small);
  border-bottom: 1px solid #e9ecef;
  svg {
    display: block;
    margin: 0 auto;
  }
  > img {
    width: 120px !important;
  }

  &:last-of-type,
  &:nth-last-child(-n + 2) {
    text-align: center;
  }
`;

const DefaultTablePagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
`;

export { DefaultTableContainer, DefaultTablePagination, Thead, Tbody, Tr, Th, Td, Table };

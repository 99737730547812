import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import Swal from 'sweetalert2';
import MediaFromResponse from '../../models/from-api-response/media';
import { getMedias as getMediasService, updateMedia } from '../../services/medias';
import DefaultButton from '../DefaultButton';
import SambaVideosContentCard from '../SambaVideosContentCard';
import UploadProgressBar from './components/UploadProgressBar';
import {
  ContentList,
  ContentListContainer,
  ContentListFilter,
  ContentListFilters,
  ContentUpload,
  SelectSambaVideosContentContainer,
} from './style';
import SambaUploader from 'samba-uploader';
import { hideModal } from '../../helpers/modal';

interface ExtendedWindow extends Window {
  idOfRunningCheckForProcessingMediaJob: number;
  previousProcessingMediaNumber: number;
}

declare let window: ExtendedWindow;

interface SelectSambaVideosContentProps {
  onSelectContent: (content: MediaFromResponse) => void;
  modalId?: string;
  tags?: string[];
}

const SelectSambaVideosContent: React.FC<SelectSambaVideosContentProps> = ({ onSelectContent, modalId, tags }) => {
  const uploadButton = useRef(null);

  const numberOfMediasPerPage = 10;

  const [loadingMoreMedias, setLoadingMoreMedias] = useState(false);
  const [shouldShowLoadMoreMedias, setShouldShowLoadMoreMedias] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [contents, setContents] = useState([] as MediaFromResponse[]);
  const [shouldShowLoading, setShouldShowLoading] = useState(true);
  const [sambaUploader, setSambaUploader] = useState({} as any);
  const [pageNumber, setPageNumber] = useState(1);
  const [processingMedias, setProcessingMedias] = useState([] as { id: string; name: string; actualProcess: string }[]);

  const getMedias = async (filters: any) => {
    for (let key of Object.keys(filters)) {
      if (!filters[key]) {
        delete filters[key];
      }
    }

    return await getMediasService({
      search: searchText,
      ...filters,
    });
  };

  const getSambaVideosContents = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    setContents([]);
    const localContents = await getMedias({
      limit: numberOfMediasPerPage,
      tags: (tags || []).join(','),
    });
    if (localContents && localContents.length) {
      setContents(localContents);
      setShouldShowLoading(true);
    } else {
      setShouldShowLoading(false);
    }
  };

  const loadMoreMedias = async () => {
    setLoadingMoreMedias(true);

    const localContents = await getMedias({
      start: pageNumber,
      limit: numberOfMediasPerPage,
      tags: (tags || []).join(','),
    });

    if (localContents && localContents.length) {
      const localJoinedContents = [...contents, ...localContents];

      if (localJoinedContents.length % numberOfMediasPerPage > 0) {
        setShouldShowLoadMoreMedias(false);
      }

      setContents(localJoinedContents);
      setShouldShowLoading(true);
    } else {
      setShouldShowLoading(false);
      setShouldShowLoadMoreMedias(false);
    }

    setPageNumber(pageNumber + 1);
    setLoadingMoreMedias(false);
  };

  useEffect(
    () => {
      getSambaVideosContents();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (uploadButton.current && (!sambaUploader || !Object.keys(sambaUploader).length)) {
      setSambaUploader(
        new SambaUploader({
          button: uploadButton.current,
          sambaUploadKey: process.env.REACT_APP_SAMBA_UPLOAD_KEY,
          allowedExtensions: ['avi', 'wmv', 'wma', 'mov', 'flv', 'rm', 'rmvb', 'mp4', 'mp3', 'mkv', '3gp'],
        })
      );
    }
  }, [uploadButton, sambaUploader]);

  useEffect(() => {
    if (sambaUploader && Object.keys(sambaUploader).length) {
      sambaUploader.on('start', () => {
        Swal.fire({
          title: 'Iniciando Upload',
          text: 'Upload de conteúdo iniciado!',
          timer: 1000,
        });
      });

      sambaUploader.on('progress', (event: any) => {
        const progress = (event.uploadedBytes / event.totalBytes) * 100;
        if (progress) {
          setUploadProgress(progress);
        }
      });

      sambaUploader.on('complete', async (event: any) => {
        Swal.fire({
          title: 'Sucesso!',
          icon: 'success',
          text:
            'Upload de conteúdo realizado com sucesso!' +
            '\nAgora será iniciado o processo de encoding do vídeo, em breve ele estará disponível para seleção ao lado!',
        });

        setUploadProgress(0);
      });

      sambaUploader.on('mediaCreated', async ({ mediaId, status }: any) => {
        if (mediaId) {
          await updateMedia(mediaId, { tags });
        }
      });
    }
  }, [sambaUploader]);

  useEffect(
    () => {
      const checkIfIsShowingSelectSambaVideosContent = () =>
        !!document.getElementById('select-samba-videos-content-container');

      const checkForNewProcessingMedias = async () => {
        const localProcessingMedias = await getMedias({
          status: 'PROCESSING',
        });

        if (!!localProcessingMedias.length) {
          setProcessingMedias(
            localProcessingMedias.map((md) => ({
              id: md.id,
              name: md.title,
              actualProcess: 'Processando',
            }))
          );
        } else {
          setProcessingMedias([]);
          if (window.previousProcessingMediaNumber > 0) {
            await getSambaVideosContents();
          }
        }

        window.previousProcessingMediaNumber = localProcessingMedias.length;

        if (checkIfIsShowingSelectSambaVideosContent()) {
          window.idOfRunningCheckForProcessingMediaJob = window.setTimeout(
            async () => await checkForNewProcessingMedias(),
            10000
          );
        } else {
          window.clearTimeout(window.idOfRunningCheckForProcessingMediaJob);
          window.idOfRunningCheckForProcessingMediaJob = 0;
        }
      };

      if (checkIfIsShowingSelectSambaVideosContent()) {
        if (!window.idOfRunningCheckForProcessingMediaJob) {
          window.setTimeout(async () => await checkForNewProcessingMedias(), 150);
        } else {
          window.clearTimeout(window.idOfRunningCheckForProcessingMediaJob);
          window.idOfRunningCheckForProcessingMediaJob = window.setTimeout(
            async () => await checkForNewProcessingMedias(),
            150
          );
        }
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <SelectSambaVideosContentContainer id="select-samba-videos-content-container">
      <ContentUpload ref={uploadButton}>
        <AiOutlineCloudUpload size={128} />
        <p>Fazer Upload de Conteúdo</p>
        {uploadProgress > 0 ? <UploadProgressBar progress={uploadProgress} /> : <></>}
      </ContentUpload>

      <ContentListContainer>
        <ContentListFilters onSubmit={getSambaVideosContents}>
          <ContentListFilter value={searchText} onChange={(e) => setSearchText(e.target.value)} type="search" />
          <DefaultButton type="submit">
            <BsSearch />
          </DefaultButton>
        </ContentListFilters>

        <ContentList>
          {contents && contents.length ? (
            <>
              {processingMedias && processingMedias.length ? (
                processingMedias.map((media) => (
                  <div
                    key={media.id}
                    style={{
                      border: 'solid 1px var(--default-dark-gray)',
                      borderRadius: '15px',
                      cursor: 'not-allowed',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginRight: '10px',
                        padding: '20px 0',
                      }}
                    >
                      <div className="spinner medium">
                        <div></div>
                      </div>
                      <p>{media.actualProcess}...</p>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <b>{media.name}</b>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              {contents.map((content) => (
                <SambaVideosContentCard
                  onClick={(content) => {
                    onSelectContent(content);
                    hideModal(modalId);
                  }}
                  key={content.id}
                  content={content}
                />
              ))}
              {shouldShowLoadMoreMedias ? (
                <div className="center">
                  {loadingMoreMedias ? (
                    <div className="spinner small"></div>
                  ) : (
                    <DefaultButton onClick={loadMoreMedias}>Carregar Mais</DefaultButton>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          ) : shouldShowLoading ? (
            <div className="spinner"></div>
          ) : (
            <>Nenhum conteúdo encontrado.</>
          )}
        </ContentList>
      </ContentListContainer>
    </SelectSambaVideosContentContainer>
  );
};

export default SelectSambaVideosContent;

import Content from '../../models/content';
import ContentFromReponse from '../../models/from-api-response/content';

const convertToContent = (course: ContentFromReponse): Content => {
  return {
    tag: course.tag,
    id: course.content_id,
    title: course.title,
    description: course.description,
    duration: +course.duration,
    reference: course.reference,
    active: course.is_active,
    thumbnail: course.thumbnail,
    thumbnail_mobile: course.thumbnail_mobile,
    type: course.type,
    is_library: course.is_library,
    info: {
      banner_url: course.info?.banner_url,
      is_highlighted: course.info?.is_highlighted,
      original_title: course.info.original_title,
      cast: course.info.cast,
      language: course.info.language,
      director: course.info.director,
      parental_rating: course.info.parental_rating,
      year: course.info.year,
      premium: course.info.premium,
    },
  };
};

export default convertToContent;

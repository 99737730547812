import httpClient from '../http-client';
import download from 'downloadjs';
import addDays from '../helpers/add-days';

const generateEngagementReport = async (format: string, queryString: string) => {
  const reportResponse = await httpClient.get(`reports/engagement/${format}`, {
    params: queryString,
    responseType: 'blob',
  });

  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_engajamento.${format}`, contentType);
};

const generateFinishedCoursesReport = async (format: string, queryString: string) => {
  const reportResponse = await httpClient.get(`reports/courses-finished/${format}`, {
    params: queryString,
    responseType: 'blob',
  });

  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_cursos_finalizados.${format}`, contentType);
};

const generateProducerReport = async (format: string, queryString: any) => {
  const reportResponse = await httpClient.get(`reports/producer-content/${format}`, {
    params: queryString,
    responseType: 'blob',
  });
  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_produtor_${addDays(new Date(queryString.viewed_at_gte), 1).toLocaleDateString('pt-br').replaceAll('/', '-')}_até_${addDays(new Date(queryString.viewed_at_lte), 1).toLocaleDateString('pt-br').replaceAll('/', '-')}.${format}`, contentType);
};

const generateUsersReport = async (format: string) => {
  const reportResponse = await httpClient.get(`reports/users-list/${format}`, {
    responseType: 'blob',
  });

  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_usuarios.${format}`, contentType);
};

const generateLikeReport = async (format: string, queryString: string, initialDate: Date, finalDate: Date) => {

  const reportResponse = await httpClient.get(`reports/likes/${format}${queryString}`, {
    responseType: 'blob',
  });
  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_curtida_${addDays(new Date(initialDate), 1).toLocaleDateString('pt-br').replaceAll('/', '-')}_até_${addDays(new Date(finalDate), 1).toLocaleDateString('pt-br').replaceAll('/', '-')}.${format}`, contentType);
};

const generateTotalViewedReport = async (format: string, queryString: string, initialDate: Date, finalDate: Date) => {

  const reportResponse = await httpClient.get(`reports/total-viewed/${format}${queryString} `, {
    responseType: 'blob',
  });

  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_tempo_de_visualizações_${addDays(new Date(initialDate), 1).toLocaleDateString('pt-br').replaceAll('/', '-')}_até_${addDays(new Date(finalDate), 1).toLocaleDateString('pt-br').replaceAll('/', '-')}.${format}`, contentType);
};


export { generateEngagementReport, generateFinishedCoursesReport, generateProducerReport, generateLikeReport, generateTotalViewedReport, generateUsersReport };

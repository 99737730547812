import styled from 'styled-components';

const TermsContainer = styled.div`
  .rc-md-editor {
    height: 200px;
    width: 100%;
  }
`;

export { TermsContainer };

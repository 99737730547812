import httpClient from '../http-client';
import Plan from '../models/plan';

export const getPlan = async (planId: string): Promise<Plan> => {
  const plan = (await httpClient.get(`plans/${planId}`)).data;
  return plan;
};

export const getAllPlans = async (): Promise<Plan[]> => {
  const plans = (await httpClient.get('plans')).data;
  return plans;
};

export const updatePlan = async (
  planId: string,
  { name, description, price, benefits }: { name: string; description: string; price: number; benefits: string[] }
): Promise<void> => {
  await httpClient.put(`plans/${planId}`, {
    name,
    description,
    price,
    benefits,
  });
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiLinkAlt } from 'react-icons/bi';
import Swal from 'sweetalert2';

import { CreateButtonDefaultContainer } from '../../../components/CreateButtonDefaultContainer';
import DefaultInput from '../../../components/DefaultInput';
import DefaultTable from '../../../components/DefaultTable';

import Course from '../../../models/course';
import Lesson from '../../../models/lesson';
import { getCourse } from '../../../services/trails';
import { updateCourse } from '../../../services/courses';
import { updateLesson } from '../../../services/lessons';

import { ContainerButtons, CoursesContainer, StyledButton } from '../style';
import { hideModal } from '../../../helpers/modal';

interface LinkSeasonModalProps {
  episodeId: string;
  seriesId: string;
  reloadContent: () => Promise<void>;
}

const LinkSeasonModal: React.FC<LinkSeasonModalProps> = ({
  episodeId,
  seriesId,
  reloadContent,
}) => {
  const [seasons, setSeasons] = useState<Course[]>([]);
  const [search, setSearch] = useState('');

  const getSeries = useCallback(async () => {
    const returnedSeries = await getCourse(seriesId);

    setSeasons(returnedSeries.courses);
  }, [seriesId]);

  const linkEpisodeToSeason = useCallback(async (season: Course) => {
    hideModal();
    hideModal();

    try {
      const episodeAlreadyExistsOnSeason = season.contents.find(
        (content: Lesson) => content.content_id === episodeId
      );
      if (episodeAlreadyExistsOnSeason) {
        await updateLesson(episodeId, { tag: 'episode' });
        await reloadContent();
        return Swal.fire({
          title: 'Erro',
          text: 'Episódio já está vinculado a temporada.',
          icon: 'error',
        });
      }

      const newSeasonContent = season.contents.map((lesson: Lesson) => ({
        content_id: lesson.content_id,
        position: lesson.position
      })) as { content_id: string, position: number }[];

      newSeasonContent.push({
        content_id: episodeId,
        position: newSeasonContent.length + 1,
      });

      const updatedSeason = {
        title: season.title,
        description: season.description || undefined,
        contents: newSeasonContent,
      }

      await updateCourse(season.course_id!, updatedSeason);
      await updateLesson(episodeId, { tag: 'episode' });
      await reloadContent();

      Swal.fire({
        title: 'Sucesso!',
        text: 'Episódio vinculado a Temporada  com sucesso!',
        icon: 'success',
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: `Houve um erro ao vincular o episódio a temporada. ${error.message}`,
        icon: 'error',
      });
    }
  }, [episodeId, reloadContent]);

  const seasonsToBeShown = useMemo(() => {
    return seasons && seasons.length
      ? seasons.map((season) => ({
        id: season.id,
        course_id: season.course_id,
        name: season.title,
        actions: (
          <ContainerButtons>
            <StyledButton
              className="small info"
              onClick={() => linkEpisodeToSeason(season)}
              title='Selecionar temporada'
            >
              Selecionar
              <BiLinkAlt />
            </StyledButton>
          </ContainerButtons>
        ),
      }))
      : [];
  }, [seasons, linkEpisodeToSeason]);

  useEffect(() => {
    getSeries();
  }, [getSeries]);

  return (
    <CoursesContainer>
      <CreateButtonDefaultContainer>
        <div className="right">
          <DefaultInput
            placeholder="Buscar pela temporada"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        searchText={search}
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={seasonsToBeShown}
        emptyListMessage={`Nehuma temporada encontrada!`}
      />
    </CoursesContainer>
  );
};

export default LinkSeasonModal;

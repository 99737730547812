import styled, { css } from 'styled-components';

interface IFormGroupProps {
  type?: 'checkbox';
}

const FormGroup = styled.div<IFormGroupProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 20px;
  label {
    min-width: 145px;
    margin-bottom: 10px;
    font-size: var(--font-small);
    color: var(--white);
    font-weight: 700;
  }
  ${({ type }) =>
    type === 'checkbox' &&
    css`
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      label {
        margin-left: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    `}
`;

export default FormGroup;

import LiveFromResponse from '../../models/from-api-response/lives';
import Live from '../../models/lives';

const convertToLive = (live: LiveFromResponse): Live => {
  return {
    id: live.live_id || '',
    name: live.name,
    description: live.description,
    status: live.status,
    transmission_id: live.transmission_id,
    thumbnail_url: live.images.thumbnail_Url,
    start: live.start
      ? new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(live.start))
      : '',
    stop: live.stop
      ? new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(live.stop))
      : '',
    rtmp: live.rtmp || '',
    error_sv: live.error_sv,
  };
};

const convertExternalLiveToLive = (live: LiveFromResponse): Live => {
  return {
    id: '',
    name: live.name,
    description: live.description,
    thumbnail_url: live.images.thumbnail_Url,
    start: live.start
      ? new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(live.start))
      : '',
    stop: live.stop
      ? new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(live.stop))
      : '',
    error_sv: live.error_sv,
  };
};

export { convertToLive, convertExternalLiveToLive };

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiLinkAlt, BiTrash } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';

import { showModal } from '../../helpers/modal';
import LinkSeriesModal from './LinkSeriesModal';

import Lesson from '../../models/lesson';
import { getAllLessons, deleteLesson } from '../../services/lessons';

import { ContainerButtons, CoursesContainer, StyledButton } from './style';

const UnlinkedEpisodes: React.FC = () => {
  const [unlinkedEpisodes, setUnlinkedEpisodes] = useState<Lesson[]>([]);
  const [search, setSearch] = useState('');

  const getUnlinkedEpisodes = useCallback(async () => {
    const unlinkedEpisodes = await getAllLessons({ tag: 'unlinked' });

    setUnlinkedEpisodes(unlinkedEpisodes);
  }, []);

  const linkEpisode = useCallback(async (episodeId: string) => {
    showModal('Selecionar série',
      <LinkSeriesModal episodeId={episodeId} reloadContent={getUnlinkedEpisodes} />
    )
  }, [getUnlinkedEpisodes]);

  const removeEpisode = useCallback(async (episodeId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: `Tem certeza que deseja deletar este episódio?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteLesson(episodeId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Episódio excluído com sucesso!',
          });

          await getUnlinkedEpisodes();
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao excluir o episódio',
            text: error.message,
          });
        }
      }
    });
  }, [getUnlinkedEpisodes]);


  const unlinkedEpisodesToBeShown = useMemo(() => {
    return unlinkedEpisodes && unlinkedEpisodes.length
      ? unlinkedEpisodes.map((episode) => ({
        id: episode.content_id,
        course_id: episode.content_id,
        name: episode.title,
        actions: (
          <ContainerButtons>
            <StyledButton
              className="small info"
              onClick={() => linkEpisode(episode.content_id)}
              title='Vincular episódio'
            >
              Vincular
              <BiLinkAlt />
            </StyledButton>{' '}
            <StyledButton
              onClick={() => removeEpisode(episode.content_id)}
              className="small danger"
              title='Excluir episódio'
            >
              Deletar
              <BiTrash />
            </StyledButton>
          </ContainerButtons>
        ),
      }))
      : [];
  }, [unlinkedEpisodes, linkEpisode, removeEpisode]);

  useEffect(() => {
    getUnlinkedEpisodes();
  }, [getUnlinkedEpisodes]);

  return (
    <CoursesContainer>
      <BreadCrumb
        crumbs={
          [<Link to="/profile">Perfil</Link>, <span>Episódios desvinculados</span>]
        }
      />

      <DefaultPageTitle>Episódios desvinculados</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <div className="right">
          <DefaultInput
            placeholder="Buscar um episódio"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        searchText={search}
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={unlinkedEpisodesToBeShown}
        emptyListMessage={`Não foram encontradas episódio desvinculados!`}
      />
    </CoursesContainer>
  );
};

export default UnlinkedEpisodes;

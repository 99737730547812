import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/BreadCrumb';
import { CreateButtonDefaultContainer } from '../../components/CreateButtonDefaultContainer';
import DefaultButton from '../../components/DefaultButton';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import DefaultTable from '../../components/DefaultTable';
import Author from '../../models/author';
import { getAllAuthors, deleteAuthor } from '../../services/authors';
import { activateOrInactivateCourse as activateOrInactivateCourseService } from '../../services/courses';
import { AuthorsContainer, StyledButton } from './style';

const Authors: React.FC = () => {
  const history = useHistory();
  const [authors, setAuthors] = useState([] as Author[]);
  const [search, setSearch] = useState('');

  const getAuthors = async () => {
    const allAuthors = await getAllAuthors();
    setAuthors(allAuthors);
  };

  const editAuthor = useCallback(
    (authorId: any) => {
      history.push(`edit-author/${authorId}`);
    },
    [history]
  );

  const removeAuthor = useCallback(async (authorId: any) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este palestrante?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteAuthor(authorId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Palestrante excluído com sucesso!',
          });

          await getAuthors();
        } catch (e) {
          const isEnrolledInCourse = e.response.data.message.includes('It is already enrolled in a trail.');
          Swal.fire({
            icon: 'error',
            title: 'Erro ao excluir palestrante',
            text: isEnrolledInCourse ? 'Este palestrante está associado à um conteúdo' : e.message,
          });
        }
      }
    });
  }, []);

  const createAuthor = async () => {
    history.push('create-author');
  };

  const activateOrInactivateCourse = useCallback(async (courseId: string, activate: boolean) => {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja ${activate ? 'ativar' : 'inativar'} este tema?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await activateOrInactivateCourseService(courseId, activate);
          await getAuthors();
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: `Erro ao ${activate ? 'ativar' : 'inativar'} tema!`,
          });
        }
      }
    });
  }, []);

  const authorsToBeShown = useMemo(() => {
    return authors && authors.length
      ? authors.map((author) => ({
          id: author.author_id,
          name: author.name,
          description: author.description,
          actions: (
            <>
              <StyledButton className="small info" onClick={() => editAuthor(author.author_id)}>
                Editar <BiEdit />
              </StyledButton>
              {'  '}
              <StyledButton
                onClick={() => removeAuthor(author.author_id)}
                className="small danger"
                title="Excluir palestrante"
              >
                Deletar
                <BiTrash />
              </StyledButton>
            </>
          ),
        }))
      : [];
  }, [authors, editAuthor, removeAuthor, activateOrInactivateCourse]);

  useEffect(() => {
    getAuthors();
  }, []);

  return (
    <AuthorsContainer>
      <BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Palestrantes</span>]} />
      <DefaultPageTitle>Palestrantes</DefaultPageTitle>
      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createAuthor}>Criar palestrante</DefaultButton>

        <div className="right">
          <DefaultInput
            placeholder="Pesquisar"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>
      </CreateButtonDefaultContainer>

      <DefaultTable
        searchText={search}
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: 'name',
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: 'description',
          },
          {
            headerLabel: <span>Ações</span>,
            propName: 'actions',
          },
        ]}
        items={authorsToBeShown}
        emptyListMessage={'Não foram encontrados palestrantes cadastrados!'}
      />
    </AuthorsContainer>
  );
};

export default Authors;

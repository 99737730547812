import axios from 'axios';
import MediaFromResponse from '../models/from-api-response/media';

const sambaVideosApiBaseUrl = 'https://sambavideos.sambatech.com/v1';

const getMedias = async (filters?: object) => {
  const medias = (
    await axios.get<MediaFromResponse[]>(
      `${sambaVideosApiBaseUrl}/medias?access_token=${process.env.REACT_APP_SAMBA_ACCESS_TOKEN}&pid=${process.env.REACT_APP_SAMBA_PID}`,
      {
        params: filters,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
  ).data;

  return medias;
};

const getMedia = async (mediaId: string) => {
  const media = (
    await axios.get<MediaFromResponse>(
      `${sambaVideosApiBaseUrl}/medias/${mediaId}?access_token=${process.env.REACT_APP_SAMBA_ACCESS_TOKEN}&pid=${process.env.REACT_APP_SAMBA_PID}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
  ).data;

  return media;
};

const updateMedia = async (mediaId: string, data: any) => {
  const createdMedia = await axios.put(
    `${sambaVideosApiBaseUrl}/medias/${mediaId}?access_token=${process.env.REACT_APP_SAMBA_ACCESS_TOKEN}&pid=${process.env.REACT_APP_SAMBA_PID}`,
    data,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
  return createdMedia;
};

export { getMedias, getMedia, updateMedia };

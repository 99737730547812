import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { TermsContainer } from './styles';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import mdParser from 'marked';
import { useState } from 'react';
import { useEffect } from 'react';
import DefaultButton from '../../components/DefaultButton';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';
import { getTerms as getTermsService, updateTerms as updateTermsService } from '../../services/terms';

//Novo editor
import { EditorState } from 'draft-js';
import { DescriptionTextarea, getCleanHtml } from './Editor';
import checkEmptyString from '../../helpers/check-empty-string';

const Terms: React.FC = () => {
  //Novo editor
  const [editorTermsState, setEditorTermsState] = useState(EditorState.createEmpty());
  const [editorPrivacyState, setEditorPolicyState] = useState(EditorState.createEmpty());

  const [termsText, setTermsText] = useState<string>('');
  const [privacyText, setPrivacyText] = useState<string>('');

  const cleanTermsHTML = getCleanHtml(editorTermsState);
  const cleanPrivacyHTML = getCleanHtml(editorPrivacyState);

  const getTerms = async () => {
    try {
      const localTerms = await getTermsService();
      setTermsText(localTerms.term_of_use);
      setPrivacyText(localTerms.privacy_policy);
    } catch (error) {}
  };

  const updateTerms = async (event: React.FormEvent) => {
    if (event.preventDefault) {
      event.preventDefault();
    }

    if (checkEmptyString(cleanTermsHTML) || checkEmptyString(cleanPrivacyHTML)) {
      throw new Error('Preencha todos os campos.');
    }

    try {
      await updateTermsService(cleanTermsHTML, cleanPrivacyHTML);

      Swal.fire({
        title: 'Sucesso!',
        text: `Atualizado com sucesso!`,
        icon: 'success',
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({ icon: 'error', text: 'Erro ao alterar termos de uso e politica de privacidade. ' + errorMessage });
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <TermsContainer>
      <BreadCrumb crumbs={[<Link to="/profile">Perfil</Link>, <span>Termos de uso</span>]} />

      <DefaultPageTitle>Termos de uso</DefaultPageTitle>

      <DefaultCreationForm onSubmit={updateTerms}>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Termos de Uso
          </label>
          <DescriptionTextarea
            description={termsText}
            editorState={editorTermsState}
            setEditorState={setEditorTermsState}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup style={{ marginTop: '24px' }}>
          <label className="required" htmlFor="reference">
            Política de Privacidade
          </label>

          <DescriptionTextarea
            description={privacyText}
            editorState={editorPrivacyState}
            setEditorState={setEditorPolicyState}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton className="success">Salvar</DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </TermsContainer>
  );
};

export default Terms;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Contents from '../../pages/Contents';
import CreateAndEditContent from '../../pages/CreateAndEditContent';
import CreateAndEditCourse from '../../pages/CreateAndEditSeason';
import Profile from '../../pages/Profile';
import Authors from '../../pages/Authors';
import CreateAndEditAuthors from '../../pages/CreateAndEditAuthors';
import Categories from '../../pages/Categories';
import CreateAndEditCategory from '../../pages/CreateAndEditCategory';
import CreateAndEditLesson from '../../pages/CreateAndEditEpisode';
import Series from '../../pages/Series';
import CreateAndEditSerie from '../../pages/CreateAndEditSerie';
import Payments from '../../pages/Payments';
import Terms from '../../pages/Terms';
import Lives from '../../pages/Lives';
import CreateAndEditLive from '../../pages/CreateAndEditLive';
import Plans from '../../pages/Plans';
import EditPlan from '../../pages/EditPlan';
import Producers from '../../pages/Producer';
import CreateAndEditProducer from '../../pages/CreateAndEditProducer';
import ReportsProducers from '../../pages/ReportsProducers';
import ReportsLikes from '../../pages/ReportsLikes';
import ReportsTotalVieweds from '../../pages/ReportsTotalVieweds';
import UnlinkedEpisodes from '../../pages/UnlinkedEpisodes';

const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path="/profile" component={Profile} />

        <Route exact path="/authors" component={Authors} />
        <Route exact path="/create-author" component={CreateAndEditAuthors} />
        <Route exact path="/edit-author/:authorId" component={CreateAndEditAuthors} />

        <Route exact path="/producers" component={Producers} />
        <Route exact path="/create-producer" component={CreateAndEditProducer} />
        <Route exact path="/edit-producer/:producerId" component={CreateAndEditProducer} />
        <Route exact path="/reports/producers" component={ReportsProducers} />
        <Route exact path="/reports/likes" component={ReportsLikes} />
        <Route exact path="/reports/total-vieweds" component={ReportsTotalVieweds} />

        <Route exact path="/categories" component={Categories} />
        <Route exact path="/create-category" component={CreateAndEditCategory} />
        <Route exact path="/edit-category/:categoryId" component={CreateAndEditCategory} />

        <Route exact path="/plans" component={Plans} />
        <Route exact path="/edit-plan/:planId" component={EditPlan} />

        <Route exact path="/contents">
          <Contents key="contents" />
        </Route>
        <Route exact path="/create-content" component={CreateAndEditContent} />

        <Route exact path="/kids/contents">
          <Contents key="kids" isKids={true} />
        </Route>
        <Route exact path="/kids/create-content">
          <CreateAndEditContent isKids={true} />
        </Route>
        <Route exact path="/kids/edit-content/:contentId">
          <CreateAndEditContent isKids={true} />
        </Route>

        <Route exact path="/edit-content/:contentId" component={CreateAndEditContent} />

        <Route exact path="/trails/create-content" component={CreateAndEditLesson} />
        <Route exact path="/trails/edit-content/:contentId" component={CreateAndEditLesson} />

        <Route exact path="/trails/create-episode" component={CreateAndEditCourse} />
        <Route exact path="/trails/edit-episode/:courseId" component={CreateAndEditCourse} />
        <Route exact path="/trails/series" key="series" component={Series} />
        <Route exact path="/trails/create-serie" component={CreateAndEditSerie} />
        <Route exact path="/trails/edit-serie/:serieId" component={CreateAndEditSerie} />

        <Route exact path="/kids/trails/create-serie">
          <CreateAndEditSerie isKids={true} />
        </Route>
        <Route exact path="/kids/trails/edit-serie/:serieId" component={CreateAndEditSerie}>
          <CreateAndEditSerie isKids={true} />
        </Route>
        <Route exact path="/kids/trails/series">
          <Series key="kids" isKids={true} />
        </Route>

        <Route exact path="/musics/contents">
          <Contents key="musics" isMusics={true} />
        </Route>
        <Route exact path="/musics/create-content">
          <CreateAndEditContent key="musics" isMusics={true} />
        </Route>
        <Route exact path="/musics/edit-content/:contentId">
          <CreateAndEditContent key="musics" isMusics={true} />
        </Route>

        <Route exact path="/musics/trails/create-serie">
          <CreateAndEditSerie key="musics" isMusics={true} />
        </Route>
        <Route exact path="/musics/trails/edit-serie/:serieId">
          <CreateAndEditSerie key="musics" isMusics={true} />
        </Route>
        <Route exact path="/musics/trails/series">
          <Series key="musics" isMusics={true} />
        </Route>

        <Route exact path="/lives" component={Lives} />
        <Route exact path="/create-live" component={CreateAndEditLive} />
        <Route exact path="/edit-live/:liveId" component={CreateAndEditLive} />

        <Route exact path="/payments" component={Payments} />

        <Route exact path="/terms" component={Terms} />
        <Route exact path="/unlinked-episodes" component={UnlinkedEpisodes} />
      </Switch>
    </>
  );
};

export default Routes;

import convertToContent from '../helpers/converters/convert-content';
import httpClient from '../http-client';
import Content from '../models/content';
import ContentForCreate from '../models/for-create/content';
import ContentForUpdate from '../models/for-update/content';
import ContentFromReponse from '../models/from-api-response/content';
import { uploadFile } from './files';

const getContent = async (contentId: string) => {
  const content = (await httpClient.get(`contents/${contentId}`)).data;

  return content;
};

const getSegmentations = async () => {
  const segmentations = (await httpClient.get(`segmentation-items/type`)).data;

  return segmentations;
};

const getAllContents = async (isKids?: boolean) => {
  const contents = (
    await httpClient.get<ContentFromReponse[]>('contents', {
      params: {
        is_library: true,
        is_kids: isKids || false,
      },
    })
  ).data;

  let convertedContents = [] as Content[];
  if (contents && contents.length) {
    convertedContents = contents.map(convertToContent);
  }

  return convertedContents;
};

const getMusics = async (isKids?: boolean) => {
  const contents = (await httpClient.get<ContentFromReponse[]>('contents/show-clips-presentations')).data;

  let convertedContents = [] as Content[];
  if (contents && contents.length) {
    convertedContents = contents.map(convertToContent);
  }

  return convertedContents;
};

const ListContents = async () => {
  const contents = (
    await httpClient.get<ContentFromReponse[]>('contents', {
      params: {
        is_library: true,
        orderByName: true,
      },
    })
  ).data;

  let convertedContents = [] as Content[];
  if (contents && contents.length) {
    convertedContents = contents.map(convertToContent);
  }

  return convertedContents;
};

const createContent = async (newContent: ContentForCreate) => {
  const createContentResponse = (await httpClient.post<ContentFromReponse>(`contents/`, newContent)).data;

  return createContentResponse;
};

const updateContent = async (contentId: string, newContent: ContentForUpdate) => {
  await httpClient.put(`contents/${contentId}`, newContent);
};

const deleteContent = async (contentId: string) => {
  await httpClient.delete(`contents/${contentId}`);
};

const changeContentThumbnail = async (contentId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`contents/${contentId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const changeContentThumbnailMobile = async (contentId: string, formData?: FormData, is_mobile?: boolean) => {
  let reference = undefined;
  if (formData) {
    const response = await uploadFile(formData);
    reference = response.reference;
  }
  const data = is_mobile ? { thumbnail_mobile: reference } : {};

  await httpClient.patch(`contents/${contentId}/thumbnail`, data);
};

const changeContentBanner = async (contentId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`contents/${contentId}/banner`, {
    banner_url: uploadedFile.reference,
  });
};

const uploadSubtitle = async (mediaId: string, formData: FormData) => {
  await httpClient.post(`webhooks/caption/${mediaId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteSubtitle = async (mediaId: string, captionLanguage: string) => {
  await httpClient.delete(`webhooks/caption/${mediaId}`, {
    data: {
      captionLanguage,
    },
  });
};

const activateOrInactivateContent = async (contentId: string, activate: boolean) => {
  await httpClient.put(`contents/${contentId}/active`, { is_active: activate });
};

const changeContentHighlighted = async (contentId: string, isHighlighted: boolean) => {
  await httpClient.patch(`contents/${contentId}/highlighted`, {
    info: { is_highlighted: isHighlighted },
  });
};

export {
  getAllContents,
  getMusics,
  ListContents,
  deleteContent,
  updateContent,
  createContent,
  changeContentThumbnail,
  changeContentBanner,
  getContent,
  uploadSubtitle,
  deleteSubtitle,
  activateOrInactivateContent,
  changeContentHighlighted,
  getSegmentations,
  changeContentThumbnailMobile,
};

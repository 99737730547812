import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import LogoDesktop from '../../assets/logo-teomidia.png';
import LogoMobile from '../../assets/logo-teomidia.png';
import * as Style from './style';
import { login as loginService } from './../../services/login';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();

  const login = (event: React.FormEvent) => {
    event.preventDefault();

    loginService(email, password)
      .then(() => {
        history.push('/profile');
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          text: 'Erro ao realizar login. Verifique se as credenciais informadas estão corretas e tente novamente.',
        });
      });
  };

  return (
    <Style.Container>
      <Style.LogoContainer>
        <picture>
          <source media="(max-width:768px)" srcSet={LogoMobile} />
          <img src={LogoDesktop} alt="Logo" />
        </picture>
      </Style.LogoContainer>
      <Style.LoginContainer>
        <Style.LoginBlock>
          <Style.LoginTitle>Que bom te ver aqui!</Style.LoginTitle>
          <Style.LoginDescription>
            Seja bem vindo a <strong>plataforma administrativa</strong> da Teomídia.
          </Style.LoginDescription>
          <Style.LoginForm onSubmit={login}>
            <Style.LoginLabel>
              Login
              <Style.LoginInput
                type="email"
                placeholder="Digite seu e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Style.LoginLabel>
            <Style.LoginLabel>
              Senha
              <Style.LoginInput
                type="password"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Style.LoginLabel>
            <Style.LoginInput type="submit" value="Entrar" />
          </Style.LoginForm>
        </Style.LoginBlock>
      </Style.LoginContainer>
    </Style.Container>
  );
};

export default Login;

import styled from 'styled-components';

export const PerksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  & > div:last-child {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    > div {
      font-size: 16px;
      color: var(--primary-color);
      background: var(--default-dark-gray);
      border-radius: 4px;
      display: flex;
      overflow: hidden;
      button {
        width: 30px;
        font-size: 24px;
        color: var(--default-dark-gray);
        background: var(--primary-color);
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      span {
        padding: 8px;
      }
    }
  }
  & > div:first-child {
    > div {
      display: flex;
      width: 100%;
      input {
        border-radius: 4px 0 0 4px;
      }
      button {
        width: 50px;
        color: var(--default-dark-gray);
        border-radius: 0 4px 4px 0;
        border: 1px solid var(--primary-color);
        background: var(--primary-color);
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
`;

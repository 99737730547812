import httpClient from '../http-client';
import Category from '../models/for-create/category';
import CategoryForUpdate from '../models/for-update/category';

const getCategory = async (categoryId: string) => {
  const category = (await httpClient.get<any>(`categories/${categoryId}`)).data;

  return category;
};

const getAllCategories = async (filters?: object) => {
  const categories = (await httpClient.get<any[]>('categories', { params: filters })).data;

  return categories;
};

const createCategory = async (newCategory: Category) => {
  const createCategoryResponse = (await httpClient.post<any>(`categories`, newCategory)).data;

  return createCategoryResponse;
};

const updateCategory = async (categoryId: string, newContentForCategory: CategoryForUpdate) => {
  await httpClient.put(`categories/${categoryId}`, newContentForCategory);
};

const deleteCategory = async (categoryId: string) => {
  await httpClient.delete(`categories/${categoryId}`);
};

const activateOrInactivateCategory = async (categoryId: any, activate: boolean) => {
  await httpClient.put(`categories/${categoryId}/active`, { is_active: activate });
};

const categoryInHomePage = async (categoryId: string, isHome: boolean) => {
  await httpClient.patch(`categories/${categoryId}/home`, { is_home: isHome });
};

const categoryCommemorativeInHomePage = async (categoryId: string, isCommemorative: boolean) => {
  await httpClient.patch(`categories/${categoryId}/commemorative`, { commemorative: isCommemorative });
};
const categoryKids = async (categoryId: string, isKids: boolean) => {
  await httpClient.patch(`categories/${categoryId}/kids`, { kids: isKids });
};

export {
  getAllCategories,
  deleteCategory,
  updateCategory,
  createCategory,
  getCategory,
  activateOrInactivateCategory,
  categoryInHomePage,
  categoryCommemorativeInHomePage,
  categoryKids,
};

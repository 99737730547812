import styled from 'styled-components';
import DefaultButton from '../../components/DefaultButton';

const CoursesContainer = styled.div``;

const StyledButton = styled(DefaultButton)`
  margin-top: 0.5rem;
  padding: 0.5rem !important;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const ContainerButtons = styled.div`
  display: flex;
`;

export { CoursesContainer, StyledButton, ContainerButtons };

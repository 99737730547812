import styled from 'styled-components';

const CreateButtonDefaultContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;

  .right {
    margin: 0 0 0 auto;
    align-self: center;
  }
`;

export { CreateButtonDefaultContainer };

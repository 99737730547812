import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FiPlus, FiX } from 'react-icons/fi';
import Swal from 'sweetalert2';

import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import FormGroup from '../../components/FormGroup';

import checkEmptyString from '../../helpers/check-empty-string';

import { getPlan, updatePlan as updatePlanService } from '../../services/plans';

import { PerksContainer } from './style';

interface EditPlanProps {
  planId: string;
}

const EditPlan = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [benefits, setBenefits] = useState([] as string[]);
  const [price, setPrice] = useState(0);
  const [benefit, setBenefit] = useState('');

  const { planId } = useParams<EditPlanProps>();
  const history = useHistory();

  const getSinglePlan = useCallback(async () => {
    if (planId) {
      const plan = await getPlan(planId);
      setName(plan.name);
      setDescription(plan.description);
      setPrice(plan.price);
      setBenefits(plan.benefits);
    }
  }, [planId]);

  const addBenefit = () => {
    if (benefits === null) {
      setBenefits([benefit]);
    } else {
      setBenefits([...benefits, benefit]);
      setBenefit('');
    }
  };

  const removeBenefit = (indexRemove: number) => {
    setBenefits(benefits.filter((item, index) => indexRemove !== index));
  };

  const goToPlans = () => {
    history.push('/plans');
  };

  const updatePlan = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error('Informe um título valido para o plano.');
      }

      if (price < 0 || isNaN(price)) {
        throw new Error('Informe um preço válido para o plano.');
      }

      await updatePlanService(planId, {
        name,
        description,
        benefits,
        price,
      });

      Swal.fire({
        title: 'Sucesso!',
        text: 'Plano editado com sucesso!',
        icon: 'success',
      });

      goToPlans();
    } catch (error: any) {
      Swal.fire({
        title: 'Erro',
        text: 'Houve um erro ao editar o plano. ' + error.message,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    getSinglePlan();
  }, [getSinglePlan]);

  return (
    <div>
      <BreadCrumb crumbs={[<Link to="/plans">Planos</Link>, <span>Editar Plano</span>]} />

      <DefaultPageTitle>Editar Plano</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label htmlFor="title">
            Título
          </label>
          <DefaultInput disabled id="title" value={name} onChange={(e) => setName(e.target.value)} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Descrição
          </label>
          <DefaultInput id="title" value={description} onChange={(e) => setDescription(e.target.value)} required />
        </DefaultCreationFormGroup>

        <PerksContainer>
          <FormGroup>
            <label className="required" htmlFor="benefit">
              Vantagens
            </label>
            <div>
              <DefaultInput id="benefit" value={benefit} onChange={(e) => setBenefit(e.target.value)} />
              <button type="button" onClick={addBenefit}>
                <FiPlus />
              </button>
            </div>
          </FormGroup>

          <div>
            {(benefits || []).map((ben, index) => (
              <div key={index}>
                <span>{ben}</span>
                <button onClick={() => removeBenefit(index)} type="button">
                  <FiX />
                </button>
              </div>
            ))}
          </div>
        </PerksContainer>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="price">
            Preço
          </label>
          <DefaultInput id="price" value={price} onChange={(e) => setPrice(+e.target.value)} required />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToPlans}>
            Cancelar
          </DefaultButton>
          <DefaultButton onClick={updatePlan} className="success">
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </div>
  );
};

export default EditPlan;
